.yearly-consumption-input {
  font-weight: normal;
}

.yearly-consumption-input__divider {
  margin-bottom: $spacing-less;
  margin-top: $spacing-less;
}

.yearly-consumption-input__calculation-method-label {
  text-align: left;
}

.yearly-consumption-input__nub {
  border: solid 10px;
  border-color: transparent transparent $enerkey-primary;
  margin-top: -33px;
  position: absolute;
  margin-left: 85%;
  width: 0;
}

.yearly-consumption-input__input-row-container {
  display: flex;
  @include flex-center-center();

  .yearly-consumption-input__input-field {
    margin-left: auto;
    max-width: 40%;
    margin-bottom: 0;
    margin-right: $spacing-less;
    text-align: right;
  }
}
