// Select
//
// You can easily use custom <strong>&lt;select&gt;</strong> styles by wrapping the <strong>&lt;select&gt;</strong> element in <strong>.select-wrapper.</strong>
//
// markup:
// <div class="select-wrapper" style="max-width: 200px;">
//   <select class="select">
//     <optgroup label="Group 1">
//     <option value="1">Option 1</option>
//     <option value="2">Option 2</option>
//     </optgroup>
//     <option value="3" disabled>Option 2</option>
//   </select>
// </div>
//
// Styleguide 4.6.1
@mixin selectbox-marker() {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAApklEQVQ4T62T0RWCMAxFbyeQDVyBEWQDnFQ2wBEcATbQDTzPQzw9IS0fpX9N+m5emzTRuFKjnlMAPfAAOmAAXgeudH4G3sBdDhbguokUrEFMrGJaiwcoWIJ4sc6uAijxBC6ZdQ+JxB/gZo9Yg4irO5tt7X9ivVfehRJEglCshG9jBMmb8q9swWgOSpCdOHJgYA8JxTWAcoJMG3EsDdgpo9z0n5odfAFSXycw/SRVcAAAAABJRU5ErkJggg==');
  background-position: right rem-calc(20) top rem-calc(18);
  background-repeat: no-repeat;
  background-size: 10px;
}

.select-wrapper {
  position: relative;

  .select, .multiSelect > button {
    width: 100%;
    max-width: 400px;
    background: $enerkey-input-background;
    color: $enerkey-black;
    height: $input-height;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid $kendo-input-border;

    @include selectbox-marker();

    &:focus {
      outline: none;
      background-color: $enerkey-input-focus-background-color;
      border-color: $enerkey-input-focus-border-color;
    }

    &:disabled {
      @include disabled( $disabled-styling );
      color: $enerkey-footnote;
    }

    &::-ms-value {
      background: none; /* remove blue background on ie10/ie11 when selected */
      color: $enerkey-black;
    }
  }

  .select--full-width {
    .multiSelect {
      button {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  /* isteven-multi-select */
  .select--multiple {
    .multiSelect > button {
      text-align: left;
      border-radius: 0;
      overflow: hidden;

      .caret {
        display: inline-block;
        width: 1rem;
        height: 0;
        margin: 0 0 1px 12px !important; /* stylelint-disable-line */
        border: none;
      }
    }

    .multiSelect .helperButton {
      padding: 0 8px;
    }

    .multiSelect .clearButton {
      right: 0;
      top: 2px;
      padding: 2px;
      height: 20px;
      width: 20px;
    }

    .multiSelect .buttonLabel {
      padding-bottom: 0;
      padding-top: 2px;
    }

    .multiSelect .inputFilter {
      padding: 0 0.5rem;
    }

    &.disabled {
      .multiSelect > button {
        color: $enerkey-footnote;
      }
    }

    &.in-modal {
      .checkboxLayer {
        position: fixed;
        overflow-y: auto;
      }
    }
  }
}

select {
  margin: 0; // Override foundation margin
}

// Override iSteven default font-size
.multiSelect  {
  button {
    font-size: $body-font-size;
  }
}
