.actions-edit-form .upload-button {
  margin-left: rem-calc(15);
}

.actions-edit-form__file-list {
  margin-left: rem-calc(15); // Foundation $column-gutter/2
  margin-right: rem-calc(15);
}

.actions-edit-form__file-name {
  margin-right: 1rem;
}

.actions-edit-form__file--removed {
  text-decoration: line-through;

  a {
    color: $enerkey-light-grey;
  }
}

.actions-edit-form__MeterName {
  .multiSelectGroup .acol label {
    font-weight: bold;
  }
}

/* For metertree as meter picker, see actions-edit.html
.actions-edit-form__MeterName {
  padding: 2px 6px 3px;
  color: #132878;
  background-color: #f1f2f6;
  width: 100%;
  min-height: 2.7em;
}

.actions-edit-form__MeterPicker {
  padding: rem-calc(15);
  &.f-dropdown {
    max-width: none;
  }
}
*/

.action-update-info__content {
  margin: 0 0 1rem;
}

.actions-edit-field__validation-condition {
  font-weight: normal;
}

.actions-edit-form__investment-and-savings {
  margin: rem-calc(15);
  display: flex;
  flex-wrap: wrap;
}

.actions-edit-form__investment, .actions-edit-form__savings {
  outline: 1px solid $enerkey-border;
  outline-width: 1px 1px 0 0; /* stylelint-disable-line */
}

.actions-edit-form__savings {
  background-color: $enerkey-panel-background;
}

// No energy type coloring or icons for now
.actions-edit-form__label--electricity {
  // color: $enerkey-electricity;
}

.actions-edit-form__label--districtheating {
  // color: $enerkey-districtheating;
}

.actions-edit-form__label--oil {
  // color: $enerkey-oil;
}

.actions-edit-form__label--water {
  // color: $enerkey-water;
}

.ek-input--autofilled.ek-input--autofilled.ek-input--autofilled.ek-input--autofilled {
  background-color: $enerkey-autofill;
}

.actions-import-form {
  .k-grid-toolbar {
    background-color: $enerkey-white;
    padding: 0 0 1rem;
  }

  .k-button.k-grid-excel {
    // .button "extend"
    background-color: $enerkey-primary;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    transition: none;
    font-size: 1rem;
    // .button "extend" ends
    font-weight: bold;
  }

  .helptext-section {
    white-space: pre-line;
  }

  .actions-paste-area {
    padding-top: 3em;
    height: 10em;
    border: 3px dashed $enerkey-input-focus-border-color;
    text-align: center;
    font-size: 1.2rem;
    width: 100%;
  }

  .validation-error-indicator {
    font-size: 1rem;
    color: $enerkey-red;
  }
}

%container {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.actions-params {
  &__container {
    @extend %container;

    .searchInput {
      min-width: 20em;
    }

    &--mobile {
      @extend %container;
      flex-wrap: wrap;

      .searchInput {
        min-width: initial;
      }
    }

    > *:not(:last-child) {
      margin-right: $spacing-default;
    }

    // TODO: temporary fix
    .toggle-grid-columns {
      height: auto;
      position: absolute;
      right: 0;
      top: auto;
    }
  }

  &__date-select--mobile {
    order: 2;
    flex-grow: 1;
  }

  &__facility-select--mobile {
    order: 1;
    flex-basis: 100%;
    margin-bottom: $spacing-default;
  }

  &__search-button--mobile {
    order: 3;
    margin-left: $spacing-default;
  }

  &__datefiltertype {
    min-width: 160px;
  }

  &__right-content {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    > * + * {
      margin-left: $spacing-default;
    }
  }

  &__link {
    &:before {
      content: '\f078';
      display: inline-block;
      margin-right: 5px;
      font-family: $font-awesome;
      font-weight: 600;
    }
  }
}
