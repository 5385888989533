$section-width-default: 400px;
$section-width-more: 470px;
$section-width-small: 215px;
$smaller-font-size: $body-font-size * 0.9;

.closeable__content--expanded {
  .mqa-inspect-search {
    min-height: 230px;
  }
}

.mqa-inspect-search {
  @include flex();
  font-size: $smaller-font-size;
  padding-top: $spacing-default;

  button {
    font-size: $smaller-font-size;
    padding-bottom: 0;
  }

  & > div {
    @include flex();
  }

  &__section {
    padding-left: $spacing-default;
    padding-right: $spacing-default;

    border-right: $mqa-default-border;

    &__meters {
      width: $section-width-more;
      max-width: $section-width-more;
    }

    &__defects {
      width: $section-width-small;
      max-width: $section-width-small;
    }

    &__time-frame {
      max-width: $section-width-default;
    }

    &--no-border {
      border: none;
    }

    &:last-of-type {
      border-right: 0;
    }
  }

  &__wrap {
    display: flex;
    flex: 1 1 auto;
  }

  @media only screen and (max-width: 1720px) and (min-width: $mqa-medium-width) {
    &__wrap {
      display: flex;
      flex-direction: column;

      .mqa-inspect-search__section:first-of-type {
        padding-bottom: $spacing-default * 2;
      }
    }
  }

  @media only screen and (max-width: $mqa-medium-width) {
    flex-wrap: wrap;

    & > div {
      flex-wrap: wrap;
    }

    &__section {
      padding: 0 $spacing-less $spacing-default $spacing-less; 
      flex: 0 0 50%;

      width: 100%;
      max-width: 100%;

      &--no-border {
        border-right: $mqa-default-border;
      }

      &:first-of-type {
        width: 100%;
      }

      &:last-of-type {
        width: 100%;
        border-right: $mqa-default-border;
      }
    }
  }

  @media only screen and (max-width: $mqa-small-width) {
    &__section {
      flex: 0 0 100%;
      border-right: 0;
    }

    &__wrap {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
