$icomoon-font-family: "Enerkey2" !default;
$icomoon-font-path: "fonts" !default;

$icon-ekey-totalownenergy: "\e920";
$icon-ekey-totalcooling: "\e927";
$icon-ekey-totalelectricity: "\e958";
$icon-ekey-totalownelectricity: "\e959";
$icon-icon-selecthierarchy: "\e95a";
$icon-ekey-personsin: "\e955";
$icon-ekey-personsoccupancy: "\e956";
$icon-ekey-personsout: "\e957";
$icon-ekey-biodieseltransport: "\e954";
$icon-ekey-kmtransport: "\e94d";
$icon-ekey-biogastransport: "\e939";
$icon-ekey-dieseltransport: "\e93a";
$icon-ekey-electricitytransport: "\e93b";
$icon-ekey-ethanole85transport: "\e93c";
$icon-ekey-fueloiltransport: "\e93d";
$icon-ekey-lngtransport: "\e93e";
$icon-ekey-naturalgastransport: "\e93f";
$icon-ekey-petroltransport: "\e940";
$icon-ekey-transport: "\e941";
$icon-ekey-co2permanence: "\e942";
$icon-ekey-humiditypermanence: "\e943";
$icon-ekey-indoorairquality: "\e944";
$icon-ekey-indoorco2: "\e945";
$icon-ekey-indoorhumidity: "\e946";
$icon-ekey-indoorpermanence: "\e947";
$icon-ekey-indoortemperature: "\e948";
$icon-ekey-indoorvoc: "\e94e";
$icon-ekey-indoorpressure: "\e94f";
$icon-ekey-indoorparticulates: "\e950";
$icon-ekey-particulatespermanence: "\e949";
$icon-ekey-pressurepermanence: "\e94a";
$icon-ekey-temperaturepermanence: "\e94b";
$icon-ekey-vocpermanence: "\e94c";
$icon-ekey-biowaste: "\e913";
$icon-ekey-woodwaste: "\e91f";
$icon-ekey-electricalandelectronicwaste: "\e914";
$icon-ekey-glasswaste: "\e915";
$icon-ekey-hazardouswaste: "\e916";
$icon-ekey-metalwaste: "\e917";
$icon-ekey-mixednorecyclingwaste: "\e918";
$icon-ekey-moldedfiberwaste: "\e919";
$icon-ekey-paperwaste: "\e91a";
$icon-ekey-plasticwaste: "\e91b";
$icon-ekey-totalwaste: "\e91c";
$icon-ekey-otherwaste: "\e91d";
$icon-ekey-wasterecycle: "\e91e";
$icon-ekey-scop: "\e92a";
$icon-ekey-cop: "\e925";
$icon-ekey-eer: "\e926";
$icon-ekey-seer: "\e92b";
$icon-ekey-custom: "\e951";
$icon-ekey-ratio: "\e952";
$icon-ekey-percentage: "\e953";
$icon-ekey-chargingsystem: "\e92f";
$icon-ekey-condensationheat: "\e930";
$icon-ekey-coolingsystem: "\e931";
$icon-ekey-facilityelectricity: "\e932";
$icon-ekey-heatpumps: "\e933";
$icon-ekey-heatingelectricity: "\e934";
$icon-ekey-lighting: "\e935";
$icon-ekey-pue: "\e936";
$icon-ekey-refrigeration: "\e937";
$icon-ekey-ventilation: "\e938";
$icon-ekey-totalenergy: "\e92d";
$icon-ekey-heatingenergy: "\e92e";
$icon-ekey-totalenergypurchased: "\e92c";
$icon-ekey-heatingpurchased: "\e928";
$icon-ekey-carbondioxide: "\e909";
$icon-ekey-pellet: "\e90a";
$icon-ekey-heatingownproduction: "\e911";
$icon-ekey-ownelectricity: "\e929";
$icon-ekey-coolingownproduction: "\e912";
$icon-ekey-heavyoil: "\e906";
$icon-ekey-oxygen: "\e907";
$icon-ekey-nitrogen: "\e908";
$icon-ekey-liquefiedpetroleumgas: "\e905";
$icon-ekey-woodchips: "\e90b";
$icon-ekey-biogas: "\e90c";
$icon-ekey-reactivepower: "\e90d";
$icon-ekey-reactivepowerplus: "\e90e";
$icon-ekey-reactivepowerminus: "\e90f";
$icon-ekey-dryice: "\e910";
$icon-ekey-coolingwater: "\e60a";
$icon-ekey-wastewater: "\e60e";
$icon-ekey-compressedair: "\e60f";
$icon-ekey-districtheating: "\e610";
$icon-ekey-naturalgasother: "\e611";
$icon-ekey-naturalgas: "\e921";
$icon-ekey-oil: "\e612";
$icon-ekey-electricity: "\e613";
$icon-ekey-steam: "\e904";
$icon-ekey-iceagewater: "\e614";
$icon-ekey-districtcooling: "\e615";
$icon-ekey-water: "\e616";
$icon-uniE600: "\e600";
$icon-ekey-arrow-down: "\e601";
$icon-ekey-arrow-right: "\e602";
$icon-ekey-accept: "\e603";
$icon-ekey-cancel: "\e604";
$icon-ekey-logo: "\e605";
$icon-ekey-options: "\e606";
$icon-ekey-star: "\e607";
$icon-ekey-arrow-left: "\e608";
$icon-ekey-arrow-up: "\e609";
$icon-ekey-groups: "\e60b";
$icon-ekey-office: "\e60c";
$icon-ekey-dottedchart: "\e60d";
$icon-ekey-times: "\f00d";
$icon-ekey-thermometer: "\f055";
$icon-ekey-close: "\f057";
$icon-ekey-leaf: "\f06c";
$icon-ekey-calendar: "\f073";
$icon-ekey-bookmark: "\f097";
$icon-ekey-bulb: "\f0eb";
$icon-ekey-chart: "\f201";
$icon-icon-notes-blank: "\e902";
$icon-icon-notes-content: "\e903";
$icon-icon-notes-add: "\e900";
$icon-ekey-add-file: "\e901";

