meter-create {
  .setup-step-field {
    max-width: 20rem;
  }

  .k-spreadsheet-action-bar {
    /* stylelint-disable-next-line */
    display: none !important;
  }

  .k-state-disabled {
    opacity: 1;
    color: $enerkey-black;
  }

  .hide-spreadsheet-bars {
    .k-spreadsheet-row-header,
    .k-spreadsheet-column-header,
    .k-spreadsheet-top-corner {
      display: none;
    }
  }
}
