admin-spreadsheet {
  .spreadsheet-row {
    width: 100%;
  }

  .k-spreadsheet-action-bar {
    /* stylelint-disable-next-line */
    display: none !important;
  }

  .k-state-disabled {
    opacity: 1;
    color: $enerkey-black;
  }
}

.type-select-wrapper {
  width: 400px;
  display: block;
  margin: $spacing-default;
  background: $enerkey-background;
  padding: $spacing-default;
  border: $enerkey-light-grey solid 2px;
}
