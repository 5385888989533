.meter-groups-grid-container {

  @mixin remove-border-left {
    > td:not(:first-of-type):not(:nth-of-type(2)) {
      border-left: none;
    }
  }

  @mixin add-border-top($color: $enerkey-light-grey) {
    td:not(.k-checkbox-cell) {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-top: 2px solid $color;
      }
    }
  }

  @mixin add-hierarchy-font-weight($weight: 500) {
    .meterTreelistHierarchyCell {
      font-weight: $weight;
    }
  }

  .meter-group-row {
    @include add-hierarchy-font-weight(700);
    @include remove-border-left;
  }

  .facility-row {
    @include add-hierarchy-font-weight(600);
    @include remove-border-left;
    @include add-border-top;
  }

  .meter-row {
    @include add-hierarchy-font-weight;
  }

  .meter-groups-grid {
    height: calc(var(--content-full-height) - 50px)
  }

  .k-treelist {
    .text-right {
      text-align: right;
    }

    .text-center {
      text-align: center;
    }
  }
}
