.facilities {
  svg {
    border: 1px solid $enerkey-border;
    padding: rem-calc(5);
    background: $enerkey-super-light-grey;
    margin: 1rem 0 2rem;
  }

  &__grid {
    [kendo-grid] thead tr:first-of-type th:first-of-type {
      text-overflow: clip;
    }

    .grid-content {
      margin-top: 0;

      h3 {
        border-bottom: 2px solid $enerkey-border;
        margin-bottom: 2rem;
        text-transform: uppercase;
      }

    }

    .graph-content {
      h5 {
        text-transform: uppercase;
        border-bottom: 2px solid $enerkey-border;
        margin: 0 rem-calc(15) 2rem;
        padding: 0 0 0.5rem;
      }

      .row {
        margin-bottom: 2rem;
      }

    }

    .error {
      color: $enerkey-border;
      display: block;
      font-size: rem-calc(16);
      margin: rem-calc(8);
      text-align: center;
      @include vertical-align();
    }

    .filters select {
      width: 6rem;
    }

    .k-grid-forceNotComparable {
      line-height: normal;
      font-size: larger;
    }

    #forceNotAllComparable {
      &.fa-toggle-off {
        opacity: 0.5;
      }

      &.fa-toggle-on {
        opacity: 1.0;
      }
    }

    .cell-actions-impact-number {
      padding: 0;
      background: $enerkey-white;
      text-align: right;
    }

    .k-grid-cell-actions-impact {
      display: inline-block;
      width: 100%;
      height: 31px;
      padding: rem-calc(5);
      box-sizing: border-box;
    }
  }

  .k-grid {
    table {
      // Remove borders from grid command buttons
      tbody {
        .k-button {
          padding: 0;
        }
      }
    }
  }
}

.k-grid {
  .button {
    // Slim fit for Buttons in grid cells
    margin: 0;

    &--link {
      padding: 0;
    }
  }
}

// flags coloring
.k-grid-cell-Modelled {
  color: $modelled-value;
}

.k-grid-cell-Incomplete {
  color: $enerkey-red;
}

.k-grid-column-Modelled {
  border-bottom: medium solid $modelled-value !important; // stylelint-disable-line declaration-no-important
}

.actions, .facilities, .facilities-report, .meters-report {
   @include k-grid--colored-group-headers;
}

// Make tooltips work in grid
.energy-reporting {
  .k-column-title {
    overflow: hidden;
    white-space: normal;
  }
}

.grid-mobile {
  .k-grid table > thead {
    tr, th {
      background: white;
    }
  }

  &.k-grid table td {
    padding: 0;

    a {
      padding: 1rem;
      display: inline-block;
    }
  }

  &.k-grid .k-grid-header {
    border: none;
    background: transparent;

    .k-header:first-child {
      padding: 1rem 0;
      font-size: 1.25rem;
      background: white;
    }
  }

  &.k-grid .k-grid-footer {
    display: none;
  }
}

meters-report-treelist td {
  height: 20px;
}
