.time-frame-select {
  @include flex-center();

  .k-datetimepicker {
    width: 100%;
  }

  input {
    height: 2.1em !important; /* stylelint-disable-line declaration-no-important */
  }
}
