.alarm-settings-number-input {
  display: flex;
  width: calc(100% - 60px);

  &-container {
    position: relative;
    margin-bottom: var(--spacing-default);

    div:last-child {
      position: absolute;
      bottom: calc(1rem / 2);
      left: calc(100% - 60px + 1rem);
    }
  }
}