.er-report-modal{
  &__content {
    padding-right: $spacing-default;
    padding-left: $spacing-default;
    height: calc(100vh - #{$modal-header-height});
    overflow-y: scroll;
  }

  &__sidebar {
    height: calc(100vh - #{$modal-header-height} - #{$spacing-default}) !important; // stylelint-disable-line
    top: unset !important; // stylelint-disable-line
    margin-left: 0;
  }
}
