// Modal view header
//
// Defines the header for modal views. Brings together the title and the close button, but allows also
// any custom content between the title and the close button.
//
// markup:
// <modal-view-header
//  heading-text="'ADMIN.CREATECOMPANY' | translate"
//  on-close="cancel()">
// <!-- or with dynamic content: -->
// </modal-view-header>
// <modal-view-header
//  heading-text="'ADMIN.CREATEUSER' | translate"
//  on-close="cancel()">
//    <span ng-if="!user.IsHuman"><span translate="ADMIN.ISMACHINE"></span></span>
// </modal-view-header>
//
// Styleguide 4.4
modal-view-header {
  a.menu__link {
    &--padded {
      padding-right: $spacing-default;
    }
  }
}
