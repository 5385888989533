html,
body {
  font-size: $body-font-size;
}

body {
  background: $enerkey-background;
  overflow-y: auto;

  &.is-reveal-open {
    // Override foundation definition
    overflow: hidden;
  }
}

label > * {
  cursor: pointer;
  pointer-events: none;
}

.row {
  max-width: 100%;
}

.pointer-hover:hover {
  cursor: pointer;
}

.fa {
  transform: none !important; /* stylelint-disable-line */

  &.disabled {
    color: $enerkey-footnote;
    cursor: auto;
  }
}

.access-denied,
.error {
  margin-top: 50px;

  h4 {
    color: $enerkey-black;
  }

  .fa {
    color: $enerkey-red;
  }

  button {
    margin-top: rem-calc(24);
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .valign-middle {
    @include breakpoint(large up) {
      transform: translateY(50%);
    }
  }

  .error-reason {
    font-size: smaller;
    color: $enerkey-primary;
  }
}

.noheight {
  height: 0 !important; /* stylelint-disable-line */
  visibility: hidden;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* non-prefixed version, currently not supported by any browser */
}

.columns {
  position: relative;
}

.tooltip {
  $tooltip-border-color: $enerkey-light-grey;
  z-index: 10000;
  border: 1px solid $tooltip-border-color;

  &::before {
    border-color: transparent transparent $tooltip-border-color;
  }

  &.top::before {
    border-color: $tooltip-border-color transparent transparent;
  }

  &.bottom::before {
    border-color: transparent transparent $tooltip-border-color;
  }

  &.left::before {
    border-color: transparent transparent transparent $tooltip-border-color;
  }

  &.right::before {
    border-color: transparent $tooltip-border-color transparent transparent;
  }
}

.dropdown-pane {
  display: none;

  &.is-open {
    display: block;
  }
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.bold {
  font-weight: bold;
}

.semibold {
  font-weight: 600;
}

.content-background {
  background-color: $enerkey-white;
  box-shadow: 0 1px 2px 0 rgba(202, 202, 202, 0.5);

  &.is-mobile {
    margin: 0 -0.5rem;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-margin {
  margin: 0;
}

// Creates no-border-{size} class for every breakpoints.
@each $size in $breakpoint-classes {
  @include breakpoint($size only) {
    .no-border-#{$size} {
      border: 0 !important; /* stylelint-disable-line */
    }
  }
}

span.secondary {
  font-style: italic;
  color: $enerkey-footnote;
}

/* Font awesome colorization */
.fas, .far {
  &.green-icon {
    color: var(--enerkey-green);
  }

  &.yellow-icon {
    color: var(--enerkey-warning);
  }

  &.red-icon {
    color: var(--enerkey-red);
  }

  &.grey-icon {
    color: var(--enerkey-grey);
  }
}
