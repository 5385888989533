table {
  tr {
    height: 29px;
  }

  thead,
  tbody,
  tfoot {
    border: none;
  }
}

.table {
  tbody {
    tr:nth-of-type(odd) {
      background-color: $base-bg;
    }
  }

  &--margin {
    margin: 1rem 0;
  }

  &--no-margin {
    margin: 0;
  }

  &--no-background {
    background: none;

    tbody {
      background: none;

      tr {
        background: none;
      }
    }
  }

  &--no-stripes {
    tbody {
      tr {
        background: none;
      }
    }
  }

  &__color-cell {
    width: rem-calc(5px);
    padding: 0;
  }
}
