// Actions and Documents popups
//
// Display action and document information in facility or meter context
//
.latest-actions-container, .latest-documents-container, .graph-actions-container, .links-container {
  .actions-icon-container, .documents-icon-container {
    vertical-align: middle;

    .content-loading {
      color: $enerkey-footnote;
    }

    &--mobile {
      .dropdown-pane {
        position: fixed;
        left: 1rem!important; // stylelint-disable-line declaration-no-important
        width: calc(100% - 2rem);
      }
    }
  }

  .dropdown-pane, .ek-dropdown-menu {
    font-size: rem-calc(14);
    padding: 0;
    cursor: auto;
    clear: both;
    background: $enerkey-white;
    border: 1px solid $enerkey-secondary;
    white-space: nowrap;
    width: rem-calc(700);
    box-shadow: 0px 2px 4px rgba(1,1,1,0.5);
    border-radius: 5px;
  }

  // TODO: refactor to be more BEM like with reusable stuff to get rid of deep nesting
  /* stylelint-disable max-nesting-depth */
  .latest-actions-dropdown, .latest-documents-dropdown, .graph-actions-dropdown {
    &:before {
      border-bottom-color: $enerkey-secondary;
    }

    .title-row {
      background-color: $enerkey-secondary;
      color: var(--enerkey-header-text);
      padding: 0.25rem 1rem;
      font-size: 1.25rem;
      text-align: left;

      @include breakpoint(small only) {
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 1rem;
      }
    }

    .table-header {
      color: $enerkey-input-text;
      text-transform: uppercase;
      font-weight: bold;
      padding: 0.5rem 0 0 1rem;
      text-align: left;
    }

    .table-empty-placeholder {
      margin: 0 0.75rem 0.3rem;
    }

    .actions-table, .documents-table, .alarms-table {
      border: none;
      margin: 0 0.75rem 0.3rem;
      border-spacing: 0;
      width: calc(100% - 2 * 0.75rem);
      table-layout: fixed;

      tr:hover {
        background-color: transparent;
      }

      td, th {
        padding: rem-calc(5);
        text-align: left;
        border: 0;

        &.no-mobile {
          @include breakpoint(small only) {
            display: none;
          }
        }
      }

      th {
        font-weight: bold;
        color: $enerkey-black;

        &.action-icon {
          width: 1.5rem;
        }

        &.effect-time-frame {
          width: 9.45rem;
        }

        &.updated {
          width: 5.125rem;
        }

        &.reported-description {
          width: 18rem;
        }

        &.action-investigation-lights {
          width: 5.125rem
        }

        &.action-level {
          width: 5.125rem
        }

        &.alarm-executed-at {
          width: 6rem;
        }

        &.alarm-type {
          width: 24rem;
        }

        &.alarm-status {
          width: 5.125rem;
        }
      }

      .reported-description, .document-filename, .alarm-type {
        padding-left: rem-calc(5);
      }

      .ellipsis {
        position: relative;
        width: 80%;

        &:before {
          content: '&nbsp;';
          visibility: hidden;
        }

        span {
          position: absolute;
          left: 0;
          right: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__table {
      padding-left: $spacing-default;
      padding-right: 0;

      tr > th:nth-child(1) {
        width: 25%;
      }

      tr > th:nth-child(2) {
        width: 60%;
      }
      
      tr > th:nth-child(3) {
        width: 20%;
      }
    }

    .actions-to-perform {
      margin-right: 0.75rem;
      text-align: right;

      .action-to-perform {
        display: inline-block;

        .button {
          margin-bottom: 0.25rem;
        }

        .fa {
          color: $enerkey-white;
        }
      }
    }
  }

  .graph-actions-dropdown {
    width: rem-calc(530);
    max-width: rem-calc(530);

    .effect-stops-at {
      white-space: pre;
    }
  }
}

.graph-actions-container {
  position: absolute;
  bottom: 0.8em;
  left: 0.5rem;

  .dropdown-pane {
    @include breakpoint(small only) {
      width: rem-calc(390);
      left: -2rem !important; // stylelint-disable-line declaration-no-important
    }
  }
}

.graph-action-tooltip {
  text-align: left;
  max-width: 400px;

  .tooltip-field {
    display: block;
    white-space: nowrap;
  }

  .ellipsis {
    width: 100%;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
