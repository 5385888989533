$fonts-path: '../fonts/';
$font-bold: 'Campton/350D26_0_0' !default;
$font-book-italic: 'Campton/350D26_1_0' !default;
$font-book: 'Campton/350D26_2_0' !default;
$font-medium: 'Campton/350D26_3_0' !default;
$font-semi-bold: 'Campton/350D26_4_0' !default;
$icomoon-font-path: "../fonts/Enerkey2";

@import "fonts-Enerkey2";

@mixin font-face($name, $path, $weight: null, $style: null, $local: null, $exts: woff2 woff ttf) {
  $src: null;

  $formats: (
    ttf: "truetype"
  );

  @each $ext in $exts {
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $ext)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    @if $local {
      src: append($local, $src)
    } @else {
      src: $src
    }
  }
}

@include font-face('Campton-Bold', $fonts-path + $font-bold);
@include font-face('Campton-BookItalic', $fonts-path + $font-book-italic, null, null);
@include font-face('Campton-Book', $fonts-path + $font-book);
@include font-face('Campton-Medium', $fonts-path + $font-medium);
@include font-face('Campton-SemiBold', $fonts-path + $font-semi-bold, null, null);

/* open-sans-300 - latin */
@include font-face(
  'Open Sans',
  '../fonts/OpenSans/open-sans-v15-latin-300',
  300,
  normal,
  (local('Open Sans Light'), local('OpenSans-Light'))
);

/* open-sans-300italic - latin */
@include font-face(
  'Open Sans',
  '../fonts/OpenSans/open-sans-v15-latin-300italic',
  300,
  italic,
  (local('Open Sans Light Italic'), local('OpenSans-LightItalic'))
);

/* open-sans-regular - latin */
@include font-face(
  'Open Sans',
  '../fonts/OpenSans/open-sans-v15-latin-regular',
  400,
  normal,
  (local('Open Sans Regular'), local('OpenSans-Regular'))
);

/* open-sans-italic - latin */
@include font-face(
  'Open Sans',
  '../fonts/OpenSans/open-sans-v15-latin-italic',
  400,
  italic,
  (local('Open Sans Italic'), local('OpenSans-Italic'))
);

/* open-sans-600 - latin */
@include font-face(
  'Open Sans',
  '../fonts/OpenSans/open-sans-v15-latin-600',
  600,
  normal,
  (local('Open Sans SemiBold'), local('OpenSans-SemiBold'))
);

/* open-sans-600italic - latin */
@include font-face(
  'Open Sans',
  '../fonts/OpenSans/open-sans-v15-latin-600italic',
  600,
  italic,
  (local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'))
);

/* open-sans-700 - latin */
@include font-face(
  'Open Sans',
  '../fonts/OpenSans/open-sans-v15-latin-700',
  700,
  normal,
  (local('Open Sans Bold'), local('OpenSans-Bold'))
);

/* open-sans-700italic - latin */
@include font-face(
  'Open Sans',
  '../fonts/OpenSans/open-sans-v15-latin-700italic',
  700,
  italic,
  (local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'))
);

/* open-sans-800 - latin */
@include font-face(
  'Open Sans',
  '../fonts/OpenSans/open-sans-v15-latin-800',
  800,
  normal,
  (local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'))
);

/* open-sans-800italic - latin */
@include font-face(
  'Open Sans',
  '../fonts/OpenSans/open-sans-v15-latin-800italic',
  800,
  italic,
  (local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'))
);
