/* stylelint-disable declaration-no-important */
// Form elements
//
// Styles & markup examples for form elements
//
// Styleguide 4.6

.ng-invalid:not(form):not(.k-textbox):not(.k-input):not(.k-widget) {
  &[type=text]:focus {
    border: 1px solid $invalid-border;
  }

  border: 1px solid $invalid-border;
}

.no-border-on-invalid {
  .ng-invalid {
    border-color: $kendo-input-border !important;

    > .k-dropdown-wrap, .k-picker-wrap {
      border-color: $kendo-input-border !important;
    }
  }
}

.no-border-on-pristine-invalid.ng-invalid.ng-pristine {
  border-color: $kendo-input-border !important;
}

label.disabled {
  color: $enerkey-footnote;
}

.ek-form {
  textarea {
    height: 8rem;
  }

  input[type="text"] {
    max-width: 100%;
  }

  input {
    color: initial;
    background-color: $enerkey-panel-background;
    border: 1px solid #CCC;
    box-shadow: none;
  }

  input:not([type="checkbox"]):focus, textarea:focus {
    background-color: $enerkey-input-focus-background-color;
    border-color: $enerkey-input-focus-border-color;
  }

  input[type="checkbox"]:focus {
    box-shadow: 0 0 4px $enerkey-black;
  }

  .select-wrapper select {
    color: initial;
  }

  .select-wrapper select:focus {
    color: initial;
  }

  .k-picker-wrap .k-input {
    margin: 0;
    height: 2.2rem;
    line-height: normal;
  }

  label {
    cursor: default;
    white-space: nowrap;
    font-weight: bold;
    padding: 0.5rem 0 0.2rem;
  }

  .separator {
    margin-left: rem-calc(15); // Foundation $column-gutter/2
    margin-right: rem-calc(15);
    max-width: none;
  }
}

.label {
  display: flex;
  align-items: center;
  background-color: transparent;
  height: 100%;
  font-size: $body-font-size;
  color: $enerkey-black;

  &--secondary {
    color: $enerkey-white;
  }
}

.checkbox {
  margin-bottom: 0;
}
