// Different styles for the reporting module
// facility info, meter info, comments & actions, sum report facilities & meters cards
.reporting-info-card {
  border-radius: var(--container-radius);

  .header-group {
    background: var(--enerkey-secondary);
    border-radius: var(--container-radius) var(--container-radius) 0 0;

    h3 {
      color: var(--enerkey-header-text);
    }
  }
}

// Different colors to distinguish new reporting from old (ENE-4382)
// This should be temporary before adjusting whole color theme (ENE-4345)
.reporting-colors {
  th.table-report-group-header {
    color: var(--enerkey-header-text);
    background: var(--enerkey-secondary);

    &:nth-child(even) {
      background: var(--enerkey-secondary-light);
    }
  }

  .k-grid {
    line-height: 1.5;
  }

  &--enerkey {
    .k-grid table td a, a.reporting-link, .button--link {
      color: #106a91;
      
      &:active,
      &:hover,
      &:focus {
        color: lighten(#106a91, 10%);
      }
    }
  
    .k-grid-header, .k-grid-footer {
      border-bottom: 2px solid var(--enerkey-primary);
    }
  
    .k-grid-footer {
      border-top: 2px solid var(--enerkey-primary);
    }
  }
}