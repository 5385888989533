th.table-report-group-header {
  color: var(--enerkey-white);
  background-color: var(--enerkey-header-general);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:nth-child(even) {
    background-color: var(--enerkey-grey);
  }
}

.simple-table-report {
  .simple-report-header-cell {
    padding: 10px;
    border: none;

    .k-link {
      display: flex;
    }

    .k-icon {
      position: absolute;
      right: 20%;
    }
  }

  .simple-report-header-name-cell {
    vertical-align: middle;
  }

  .simple-report-cell {
    border: none;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .simple-report-value-cell {
    text-align: center;
  }

  .real-estate-id-container {
    padding-top: 5px;
  }

  .simple-report-allow-overflow {
    overflow: visible;

    .real-estate-id-container {
      max-width: 100%;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
    }
  }

  .simple-report-change-value {
    padding-top: 5px;
  }
}
