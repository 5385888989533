@import "../../../../scss/mixins";

/** Time period **/
.facilities-sidebar-time-period {
  .start-container {
    height: $input-height;
    border: 1px solid $kendo-input-border;
    background: $enerkey-input-background;

    .start {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 rem_calc(6);
      white-space: nowrap;
      border: none;

      .start-name {
        display: inline-block;
        margin-left: rem-calc(-4);
        transform: scaleX(0.9);
      }

      .start-colors {
        display: inline-block;
        vertical-align: middle;
        width: 25px;

        .start-color {
          float: left;
          line-height: 0.65;
        }
      }

      .remove-start-button {
        position: absolute;
        right: 0;
        padding: 0.55rem 0;
        width: 1.75rem;
      }
    }
  }

  .k-datepicker {
    float: right;
    border: 0 !important;
    box-shadow: none !important;
    height: 100%;
    width: 12.4rem;

    &:hover {
      background: transparent;
    }

    .k-input-inner {
      background: transparent;
      border-radius: 0;
      padding: 0;

      // Hide Kendo's own input field because we use our own here
      // Use opacity: 0. That way it's still possible to focus the input
      opacity: 0;

      &.k-state-hover, &.k-state-active, &.k-state-focused {
        box-shadow: 0 0 0;
      }
    }

    .k-input-button {
      width: 3.2rem;
      height: 100%;
    }

    .k-icon {
      background: transparent;
    }
  }

  .history-menu-container {
    .dropdown-pane {
      padding: 0;
    }

    .history-menu {
      font-size: smaller;

      .history-menu-item {
        @include clearfix();
        border-bottom: 1px solid $enerkey-light-grey;
        padding: 0.5rem;

        &:last-child {
          border-bottom: none;
        }

        &.active, &:hover {
          background-color: $enerkey-user-menu-bg;
        }

        div {
          float: left;
          margin-right: 1rem;
        }

        .line-break {
          clear: both;
        }
      }
    }

  }
}

/** Additional settings **/
.facilities-sidebar-additional-settings {
  .select--multiple {
    &.disabled {
      opacity: 1;
    }
  }

  .multiSelect {
    button[disabled] {
      opacity: 1;
      cursor: pointer;
    }

    .checkboxLayer {
      min-width: 247px;
      width: 247px;
    }

    .multiSelectItem {
      min-width: 227px;
      width: 227px;
    }

    .checkBoxContainer {
      max-height: 380px;
      overflow-y: auto;
    }

    .acol {
      max-width: 212px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

/** Global er-report-option **/
.er-report-option {
  @include flex();
  flex-wrap: wrap;

  label {
    position: relative;
    cursor: pointer;

    &.has-parent {
      margin-left: rem-calc(8);
      padding: 0;
    }

    &.no-margin-bottom {
      margin-bottom: 0;
    }
  }

  &::after {
    display: block;
    clear: both;
    content: " ";
  }
}

.sidebar-settings-disabled-fill {
  background: #f1f2f6;
}

.sidebar-settings-disabled {
  font-size: 1rem;
  text-align: left;
  color: #797979;
  padding: 3rem;
  z-index: 5;
  border-bottom: 1px solid #e6e6e6;
  font-weight: bold;

  &__body {
    margin-bottom: 2rem;
  }

  &__list {
    font-weight: normal;
    text-align: left;
    font-size: 1rem;
    list-style: none;

    li:before {
      content: "-";
    }
  }
}

.collapsed .sidebar-settings-disabled {
  display: none;
}

.row__label {
  word-break: break-word;
}
