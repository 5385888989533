/** 
  :::: temp fix for ENE-1485 ::::
  TODO: Remove this SCSS fix and come up with an permanent solution
  ---- would also be skipping Linting `!important` warning ----
*/
.griRowEditModal {
  /* stylelint-disable declaration-no-important */
  width: 100% !important;
  max-width: 600px !important;
  /* stylelint-enable */
}