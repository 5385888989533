.em-input-multi-select {
  &__placeholder {
    position: absolute;
    bottom: 12.25px;
    right: 42px;
    color: #808080;
    pointer-events: none;
  
  }

  &__container {
    max-width: 400px;
  }
}
