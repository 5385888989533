// Tabs
//
// Default - Default state for tabs item
// :focus - Focus state for tabs item
// :hover - Hover state for tabs item
// :active - Active state
//
// markup:
// <ul class="tabs">
//   <li class="tabs__item tabs__item--active">
//     <a class="tabs__item-link {$modifiers}" href="#">List item #1 (active)</a>
//   </li>
//   <li class="tabs__item">
//     <a class="tabs__item-link {$modifiers}" href="#">List item #2 (normal)</a>
//   </li>
//   <li class="tabs__item tabs__item--disabled">
//     <a class="tabs__item-link {$modifiers}" href="#">List item #3 (disabled)</a>
//   </li>
// </ul>
//
// Styleguide 4.1
.tabs {
  display: flex;
  border: none;
  font-size: 110%;

  &--vertical {
    flex-flow: column;
  }

  &__item {
    display: flex;
    align-items: center;
    min-height: 35px;
    white-space: nowrap;
  }

  &__item--margin-left {
    margin-left: 1rem;
  }

  &__item, .tabs-title {
    a {
      &, &:hover, &:link, &:visited {
        font-family: $menu-item-font;
        color: $enerkey-black;
      }

      &:hover {
        box-shadow: $tab-item-hover-shadow;
      }
    }

    &--active, &.is-active {
      box-shadow: $tab-item-active-shadow;
      padding-top: 0;
      font-weight: 700;
    }
  }

  &__item-link {
    padding-left: $menu-padding-left;
    padding-right: $menu-padding-right;
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1;
    height: 100%;
    transition: box-shadow .15s;
  }

  &__item--disabled {
    &, .tabs__item-link, a {
      &, &:hover, &:link, &:visited {
        cursor: not-allowed;
        color: $enerkey-grey;
        box-shadow: none;
      }
    }
  }
}

.tabs.vertical {
  flex-flow: column;
}
