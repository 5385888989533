.link-unlink {
  margin-left: $spacing-less;
  margin-right: $spacing-less;

  cursor: pointer;

  &__icon-container {
    transform: rotate(-45deg);
  }
}
