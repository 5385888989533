//button,    // DO NOT assume this! It is just to override foundation defaults.
.button { // This is what you are supposed to use.
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;

  &.disabled,
  &[disabled],
  &:disabled {
    background: desaturate($enerkey-primary, 30%);
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px $enerkey-white;
    background-color: $enerkey-primary;
    outline: none;
  }

  &.loading {
    position: relative;
    overflow: hidden;
    color: #ffffff50;
    background-image: linear-gradient(#ffffff70, #ffffff70);

    &[disabled], &:disabled {
      opacity: 1;
    }

    &::before, &::after {
      --size: 30px;
      box-sizing: border-box;
      content: '';
      background: transparent;
      position: absolute;
      top: calc(50% - var(--size) / 2);
      left: calc(50% - var(--size) / 2);
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      border: solid 2px transparent;
    }

    &::before {
      border-color: var(--enerkey-white) transparent;
      animation: fa-spin 1.5s linear reverse infinite;
    }

    &::after {
      --size: 20px;
      border-color: transparent var(--enerkey-white);
      animation: fa-spin 2s linear infinite;
    }
  }

  &--negative {
    background-color: $enerkey-remove-action;
    color: $enerkey-white;

    &:active,
    &:focus,
    &:hover {
      background-color: $enerkey-remove-action-hover;
      color: $enerkey-white;
    }

    &.disabled,
    &[disabled],
    &:disabled {
      background: desaturate($enerkey-remove-action, 30%);
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &--toggle-button {
    width: 25px;
    border-radius: 0;
    height: 100%;
    padding: 0;
  }

  &--no-padding {
    padding: 0 !important; // stylelint-disable-line
  }

  &--no-side-padding {
    padding-left: 0;
    padding-right: 0;
  }

  &--secondary {
    background-color: $enerkey-secondary-action;
    color: $enerkey-black;

    &:active,
    &:focus,
    &:hover {
      background-color: $enerkey-secondary-action-hover;
      color: $enerkey-black;
    }

    &.disabled,
    &[disabled],
    &:disabled {
      background: desaturate($enerkey-secondary-action, 30%);
      opacity: 0.3;
      cursor: not-allowed;
    }

    &.loading {
      color: #2c2c2c50;

      &::before {
        border-color: var(--enerkey-black) transparent;
      }

      &::after {
        border-color: transparent var(--enerkey-black);
      }
    }
  }

  &--external-link {
    &:after {
      font-family: $font-awesome;
      content: '\f054';
      position: relative;
      top: 1px;
      margin-left: 5px;
      font-weight: 600;
    }
  }

  &--scroll {
    &:before {
      content: '\f078';
      display: inline-block;
      margin-right: 5px;
      font-family: $font-awesome;
      font-weight: 600;
    }
  }

  &--link {
    font-weight: normal;
    color: $enerkey-link;
    background: transparent;
    border: 1px solid transparent;
    // stylelint-disable-next-line
    box-shadow: none !important; // remove focus styles used in normal buttons

    &.button--negative {
      color: $enerkey-remove-action;

      &:active,
      &:focus,
      &:hover,
      &:disabled {
        color: $enerkey-remove-action-hover;
      }

      &:focus {
        border: 1px dashed $enerkey-remove-action-hover;
      }

      &.button--no-focus {
        &:focus {
          color: $enerkey-remove-action !important; // stylelint-disable-line

          &:hover {
            color: $enerkey-remove-action-hover !important; // stylelint-disable-line
          }
        }
      }
    }

    &.disabled,
    &[disabled],
    &:disabled {
      color: $enerkey-link !important; // stylelint-disable-line
      background-color: transparent !important; // stylelint-disable-line
    }

    &:active,
    &:hover,
    &:focus {
      color: $enerkey-link-hover;
      background-color: transparent;
    }

    &:focus {
      border: 1px dashed $enerkey-link;
    }

    &.button--no-focus {
      &:focus {
        color: $enerkey-link-hover !important; // stylelint-disable-line

        &:hover {
          color: $enerkey-link-hover !important; // stylelint-disable-line
        }
      }
    }
  }

  &--circle {
    border-radius: $enerkey-button-icon-border-radius;
    width: 30px;
    height: 30px;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    line-height: 30px;
    padding: 0;
    box-sizing: border-box;
  }

  &.icon {
    border-radius: $enerkey-button-icon-border-radius;
    padding: 0;
    height: 2em;
    width: 2em;
    text-align: center;
    vertical-align: middle;

    i, span {
      font-size: 1em;
      line-height: 1.2em;
    }

    &.link {
      color: $enerkey-link;
      background-color: transparent;
      border: none;

      &:focus, &:active {
        border: none;
        box-shadow: none;
      }
    }

    &.icon--small {
      font-size: 9px;

      i, span {
        line-height: 1em;
      }
    }

    &.loading {
      &::before {
        --size: 24px;
      }

      &::after {
        --size: 16px;
      }
    }
  }

  &--compact {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }

  &--no-focus {
    &:focus {
      border: 1px solid transparent !important; /* stylelint-disable-line */
      box-shadow: none !important; /* stylelint-disable-line */
    }
  }

  &--slim {
    font-size: 1em;
    line-height: 1em;
    padding: 0.65em 1.25em 0.45em;
  }
}

.ek-remove-button {
  color: var(--enerkey-remove-action);
}

.ek-link-button {
  color: var(--enerkey-link);
}
