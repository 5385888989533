.ek-dropdown-menu__item {
  padding: 0.5rem;
  color: $enerkey-black;
  cursor: pointer;

  &:hover {
    background : $enerkey-extra-light-grey;
  }

  &--disabled {
    color: $enerkey-footnote;
    cursor: not-allowed;
  }

  &--separator {
    border-bottom: 1px solid $enerkey-light-grey;
    padding: 0;
    margin: 0.5rem;
  }
}
