.time-frame-radio {
  &__custom-content {
    @include flex-center();

    padding-left: 1.5rem;
    margin: 0.5rem;

    input {
      margin: 0;
      width: 5rem;
    }
  }

  .before-text {
    padding-right: $spacing-default;
  }

  .after-text {
    padding-left: $spacing-default;
  }
}
