// Wizard
//
// Wizard can be used for actions that include multiple subsequent steps
//
// markup:
// <wizard>
//   <div class="wizard-content">
//     <div class="step">
//       <button
//         class="button button--circle wizard-button wizard-button--visited fa fa-check"
//       ></button>
//       <label>Step 1 text</label>
//       <hr class="separator" />
//     </div>
//     <div class="step">
//       <button
//         class="button button--circle wizard-button"
//       >2</button>
//       <label>Step 2 text</label>
//       <hr class="separator" />
//     </div>
//     <div class="step">
//       <button
//         class="button button--circle wizard-button"
//         disabled="true"
//       >3</button>
//       <label class="disabled">Step 3 text</label>
//       <hr class="separator" />
//     </div>
//     <div class="step">
//       <button
//         class="button button--circle wizard-button"
//         disabled="true"
//       >4</button>
//       <label class="disabled">Step 4 text</label>
//     </div>
//     <button class="button">Next</button>
//   </div>
// <wizard>
//
// Styleguide 5.13

.wizard-wrapper {
  display: block;
  margin: -2px;
  background: $enerkey-background;
  padding: 13px 15px 20px;
}

wizard {
  .wizard-content {
    display: flex;
    flex-wrap: wrap;
  }

  .wizard-button {
    font-weight: bold;

    &.disabled,
    &[disabled],
    &:disabled {
      color: $enerkey-white;
      background: $enerkey-footnote;
      opacity: 0.8;
      cursor: not-allowed;
    }

    &:hover:disabled {
      background-color: $enerkey-light-grey;
    }

    &--visited {
      opacity: 0.7;
      padding: 0;
    }
  }

  label {
    font-size: 14px;
    margin: auto 8px;
    white-space: nowrap;
    font-family: $menu-item-font;

    &.disabled,
    &[disabled],
    &:disabled {
      color: $enerkey-footnote;
      opacity: 0.8;
    }
  }

  hr.separator {
    margin: auto 0;
    width: 40px;
  }

  .step {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 8px;
  }

  #wizardNext.hidden {
    visibility: hidden;
  }
}
