﻿// Headers
//
// Styleguide 25.9

// Normal header
//
// header--modal - Modal
//
// markup:
// <div class="header {$modifiers}">Header</div>
//
// Styleguide 25.9.1
header,
.header {
  width: $modal-width;
  @include calc('left', '0.5 * (100% - #{$modal-width})');
  @include calc('top', '0.5 * (100% - #{$modal-height})');
  z-index: 2000; // Otherwise raised modal content will show on top of header when scrolling

  &--modal {
    // copy paste from old
    display: flex;
    font-size: 1rem;
    margin: 0;
    // ensure title stays in one line, clip if necessary
    padding: 0;
    padding-left: $spacing-default;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: var(--enerkey-modal-header);
    height: $modal-header-height;
    align-items: center;
    justify-content: space-between;

    &__header-text-content {
      display: flex;
    }

    &__buttons-container {
      display: flex;
      height: 100%;
      align-items: center;
    }

    .fa, .far, .fas {
      font-size: rem-calc(20);
      color: $enerkey-modal-header-icon;
    }

    .modal-title, h2, h3 {
      color: var(--enerkey-modal-header-text);
    }

    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    .close-button-container {
      height: 100%;
      width: 3 * $spacing-default;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--enerkey-modal-close-background);

      button {
        width: 100%;
        height: 100%;
      }
    }
  }

  .header__dynamic-content {
    display: flex;
  }
}
