.map-images {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  .map-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    border: 2px solid $enerkey-grid-row-dark; 
  }

  .back-button {
    margin: 1rem;
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
  }
}