manual-qa-search {
  .multiSelect {
    .checkBoxContainer {
      max-height: 380px;
      overflow-y: auto;
    }

    button {
      max-width: 100%;
    }
  }

  .k-dropdown {
    width: 100%;
  }

  .k-widget {
    margin-bottom: $spacing-default;
  }

  input[type="text"] {
    max-width: 500px;
  }

  .select--multiple {
    margin-bottom: $spacing-default;
  }

  profile-search-select {
    margin-bottom: 1rem;
  }

  .k-autocomplete {
    width: 100%;
  }
}

.mqa__search__column {
  flex-basis: 25%;
  padding: $spacing-more;
}
