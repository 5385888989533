@page {
  size: A4;
}

@media print {

  // Edge hack: force the hiding of elements hidden by Angular
  .ng-hide:not(.ng-hide-animate)
  {
    display: none !important;
  }

  body {
    width: 24cm;
    height: auto !important;
    /* Specificity Arms Race against body.is-reveal-open from
     * front/src/scss/_base.scss
     */
    &.is-reveal-open {
      position: relative !important;
      width: 24cm !important;
      overflow: auto !important;
    }
  }

  body.is-reveal-open {
    overflow: visible;

    .ng-app {
      display: none;
    }
    
    .reveal-overlay {
      position: static;
      overflow: visible;
      height: auto;
      bottom: unset;

      modal-view-header {
        display: none;
      }

      .reveal {
        height: auto;

        .modal-state-body {
          overflow: visible;
          height: auto;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }

  .no-print,
  /* Specificity Arms Race against .loadingIndicator.ng-hide from
   * front/src/scss/_containers.scss
   */
  .loadingIndicator.ng-hide.no-print {
    display: none !important;
  }

  .app-container, .modal-state-body {
    padding: 0 !important;
  }

  .content-container {
    margin: 0;
  }

  .modal-overlay {
    position: relative !important;
  }

  .reveal {
    max-height: none !important;
    overflow-y: inherit !important;
    position: relative !important;
    transform: none !important;

    &.fullheight {
      width: 100%;
    }
  }

  .modal-body {
    padding: 0px !important;
  }

  .fixed-placeholder {
    display: none;
  }

  .trend-report-settings {
    display: none;
  }

  .facilities-report {
    position: relative;

    .card {
      border: none;
      margin-bottom: 0;
    }
  }

  .graph, .k-grid, .facility {
    max-width: 24cm;
    display: inline-block;
    position: relative
  }

  .facility {
    padding-top: 2rem;
    border-bottom: 0;
    page-break-before: always;

    &:first-child {
      page-break-before: avoid;
    }

    .row:first-child .columns:first-child {
      padding: 0;
    }

    .facility-info-group, .meter-info-group {
      border-top: 1px solid black;
      .title {
        padding-left: 0.5rem;
        padding-right: 0;
        font-size: 120%;
      }
      ul {
        border: 0;
        width: 20cm;
        li {
          width: 4.5cm;
          border: 0;

          div.no-wrap {
            white-space: initial;
            overflow: initial;
            text-overflow: initial;
          }
        }
      }
    }

    > div:last-child {
      padding-bottom: 0;
      height: 1px;
    }
  }

  .k-grid-container {
    overflow: hidden;
  }

  .k-grid {
    /* Coupled with respective container height adjustment in
     * `beforePrint()` and `afterPrint()` found in `src/app/app.js`.
     */
    transform: scale(.7);
    transform-origin: 0 0;
    page-break-inside: avoid;
  }

  .k-grid-header {
    overflow: hidden;
    width: 127%;
  }

  .k-grid-footer {
    overflow: hidden;
    width: 125%;
  }

  .k-grid-content {
    width: 114% !important;
    overflow: hidden;
  }

  .k-grid-footer-wrap {
    width: auto!important;
  }

  .energy-reporting > .large-10 {
    float: none!important;
    width: 100%;
  }

  .k-grid-toolbar {
    display: none;
  }

  .graph {
    width: 11.5cm !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    //height: 250pt !important;
    overflow: hidden!important;

    h5 {
      padding: 0.2rem;
      font-size: rem-calc(14);
    }
  }

  .sumreport-objects {
    div {
      width: 50%;
    }
  }

  //.k-chart {
  //  height: 0;
  //  width: 100%;
  //  padding: 0;
  //  padding-bottom: 100%;
  //}

  //* {
  //  position: relative;
  //}

  a[href]:after {
    content: "";
  }

  #toast-container, .top-bar, .filtertoolbar, .sidebar-container, .bookmark, .downloadbuttons, .mordor,
    .mobile-search, .menu-icon, .k-tooltip, .er-report-types, .actions-icon-container, .documents-icon-container {
    display: none !important;
  }

  .toolbar:last-child {
    display: none;
  }

  .facility > div.row > div:nth-child(2) {
    display: none;
  }
}

//@media print and (max-width: 800pt) {
//  .k-webkit .facility {
//    padding-bottom: 200pt;
//  }
//}
//
//@media print and (max-width: 595pt) {
//  .k-webkit .facility {
//    padding-bottom: 600pt;
//  }
//}
