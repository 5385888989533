// Accordions
accordion {
  &, &.accordion {
    display: flex;
    flex-direction: column;

    .accordion-title {
      display: flex;
      align-items: center;
      font-weight: bold;
      transition: color .2s, background-color .2s;

      &:hover {
        cursor: pointer;
        color: inherit;
      }
    }
  }
}

.sidebar-accordion {
  & > * > * {
    &, > * {
      .accordion-content {
        border-left: none;
        border-right: none;
        border-bottom: none !important;
      }
    }
  }
}

.panel-accordion-primary {
  background-color: var(--enerkey-primary);

  .accordion-title {
    color: var(--enerkey-white);

    &:hover {
      background-color: var(--enerkey-primary-action-hover);
      color: var(--enerkey-white) !important;
    }
  }
}

// Different color for title panels in reporting sidebar ENE-4382
.reporting-sidebar-accordion {
  background-color: var(--enerkey-secondary-light);
  
  .accordion-title:hover {
    background-color: var(--enerkey-secondary);
  }
}
