.mqa-inspect {
  display: flex;
  flex: 1 0;

  .card {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 100%;
  }

  .header-group {
    min-height: 40px;
  }

  .k-spreadsheet-sheets-bar {
    display: none;
  }

  &__left, &__right {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    padding: $spacing-less $spacing-default;

    > * {
      height: 100%;
    }
  }

  &__half {
    max-width: 100%;
    padding-bottom: $spacing-less;
    padding-top: $spacing-less;

    &:first-of-type {
      flex: 1 1 50%;
      min-height: 450px;
      padding-top: 0;
    }

    &:last-of-type {
      height: auto;
      padding-bottom: 0;
    }
  }

  &__left {
    flex: 0 1 1100px;
    padding-right: $spacing-less;

    &--medium {
      flex: 0 1 750px;
    }

    &--small {
      flex: 0 1 350px;
    }
  }

  &__right {
    flex: 0 1 100%;
    padding-left: $spacing-less;
  }
}

@media only screen and (max-width: $mqa-medium-width) {
  .mqa-inspect {
    flex-direction: column;
    height: inherit;

    &__left {
      padding: $spacing-less;
      flex: 0 1 100%;
    }

    &__right {
      padding: $spacing-less;
    }

    &__half {
      padding-bottom: $spacing-less;
      padding-top: $spacing-less;

      #chart {
        min-height: 50vh;
      }
    }
  }
}
