.manual-qa-actions-select {
  &__checkbox {
    @include flex-center();
    margin-right: $spacing-default;

    &--with-dropdown {
      display: flex;
      flex-direction: row;

      select {
        display: inline;
        margin-right: $spacing-default;
      }

      input {
        display: inline;
        max-width: 75px;
      }
    }
  }
    
  .disabled {
    color: $enerkey-footnote;
  }
  
  .fa {
    margin-right: $spacing-less;
  }
  
  label {
    @include flex-center();
    margin: $spacing-less $spacing-default $spacing-less 0;
  }

  input {
    margin-bottom: 0;
  }

  .button {
    margin-bottom: $spacing-less;
  }
}
