gridster.mobile {
  gridster-item {
    /* stylelint-disable-next-line declaration-no-important */
    height: auto !important;
  }
}

.widget-settings-container {
  label {
    font-weight: 700;
    margin-top: var(--spacing-default);
  }
}

.rm-validation-styles {

  /* stylelint-disable-next-line*/
  &.ng-invalid,
  .ng-invalid {
    /* stylelint-disable-next-line declaration-no-important */
    border: none !important;
  }
}

.dashboard-edit-modal {
  /* stylelint-disable declaration-no-important */
  width: 100% !important;
  max-width: 400px !important;

  .modal-body {
    min-height: 170px !important;
  }

  /* stylelint-enable */
}

.rm-validation-styles {

  &.ng-invalid,
  .ng-invalid {
    border: none !important;
    /* stylelint-disable-line */
  }
}