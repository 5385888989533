meter-mass-edit {
  .setup-step-field {
    padding: 10px;
  }

  .k-spreadsheet-action-bar {
    /* stylelint-disable-next-line */
    display: none !important;
  }

  .k-state-disabled {
    opacity: 1;
    color: $enerkey-black;
  }
  
  .date-picker {
    max-width: 300px;
  }
}
