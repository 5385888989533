.ines-report {
  h3 {
    font-size: 1.7rem;
    margin-top: 4rem;
  }

  h4 {
    margin-top: var(--spacing-default);
  }

  .chart-container {
    display: block;
    margin: 2rem auto;
    aspect-ratio: 1.5;
  }
}