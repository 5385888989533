.actions-params__container {
  .actions-params__facilities-dropdown {
    width: 200px;

    .checkboxLayer {
      max-height: 70vh;
      overflow-y: scroll;
    }
  }
}
