/* THIS FILE HAS GLOBAL KENDO SPECIFIC VARIABLES */

$primary: $enerkey-primary;
$font-size: $body-font-size;
$base-bg: $enerkey-white;
$body-text: #656565;
$kendo-button-bg: #fdfdfd;
$kendo-button-text: #656565;
$forms-invalid-color: $body-text;

// Grid and Treelist
$grid-alt-bg: $enerkey-grid-row-light;
$table-cell-padding-x: 5px;
$table-cell-padding-y: 5px;
$grid-header-padding-y: 8px;
$grid-selected-bg: transparent;
$grid-sticky-selected-bg: transparent;
$grid-sticky-selected-alt-bg: transparent;
$grid-hierarchy-col-width: 1rem;
$grid-sticky-border: rgba(0, 0, 0, 0.08);
$pager-dropdown-width: 6em;

// Tooltip
$tooltip-bg: $enerkey-light-grey;
$tooltip-text: $enerkey-black;

$link-text: $enerkey-link;
$link-hover-text: $enerkey-link-hover;

$kendo-input-border: rgba(0, 0, 0, 0.15);
$kendo-picker-border: $kendo-input-border;
$kendo-input-focused-border: rgba(0, 0, 0, 0.3);
$dropdownlist-border: $kendo-input-border;
$kendo-border-radius: 2px;
$kendo-border-radius-sm: 1px;
$kendo-border-radius-lg: 4px;
$kendo-checkbox-border: $kendo-input-border;
$invalid-border: $enerkey-error;

$kendo-input-placeholder-text: #cacaca;

$disabled-filter: grayscale(0.3) brightness(0.9);
$disabled-opacity: 0.5;

$chart-selection-marque-background: $enerkey-primary;
