@import "../em-facility-select/em-facility-select";
@import "../em-input-date/em-input-date";
@import "../em-input-dropdown/em-input-dropdown";
@import "../em-mass-edit/em-mass-edit";
@import "../em-visuals/em-visuals";
@import "../em-yearly-visuals/em-yearly-visuals";
@import "./em-input-multi-select";

.legacy-upload-button {
  position: relative;
  overflow: hidden;
  margin: rem-calc(15) rem-calc(15) 0.3rem 0;

  input.upload {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
