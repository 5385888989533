manual-qa-inspect-search-section-header {
  & > div {
    @include flex-center();

    margin-bottom: $spacing-less;

    input[type="checkbox"] {
      margin: 0 0 0 $spacing-default;
    }

    label {
      line-height: 0;
    }

    h2 {
      margin: 0
    }
  }
}
