@import "font_variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?3xvfg8') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?3xvfg8') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?3xvfg8##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ekey-totalownenergy {
  &:before {
    content: $icon-ekey-totalownenergy; 
  }
}
.icon-ekey-totalcooling {
  &:before {
    content: $icon-ekey-totalcooling; 
  }
}
.icon-ekey-totalelectricity {
  &:before {
    content: $icon-ekey-totalelectricity; 
  }
}
.icon-ekey-totalownelectricity {
  &:before {
    content: $icon-ekey-totalownelectricity; 
  }
}
.icon-icon-selecthierarchy {
  &:before {
    content: $icon-icon-selecthierarchy; 
  }
}
.icon-ekey-personsin {
  &:before {
    content: $icon-ekey-personsin; 
  }
}
.icon-ekey-personsoccupancy {
  &:before {
    content: $icon-ekey-personsoccupancy; 
  }
}
.icon-ekey-personsout {
  &:before {
    content: $icon-ekey-personsout; 
  }
}
.icon-ekey-biodieseltransport {
  &:before {
    content: $icon-ekey-biodieseltransport; 
  }
}
.icon-ekey-kmtransport {
  &:before {
    content: $icon-ekey-kmtransport; 
  }
}
.icon-ekey-biogastransport {
  &:before {
    content: $icon-ekey-biogastransport; 
  }
}
.icon-ekey-dieseltransport {
  &:before {
    content: $icon-ekey-dieseltransport; 
  }
}
.icon-ekey-electricitytransport {
  &:before {
    content: $icon-ekey-electricitytransport; 
  }
}
.icon-ekey-ethanole85transport {
  &:before {
    content: $icon-ekey-ethanole85transport; 
  }
}
.icon-ekey-fueloiltransport {
  &:before {
    content: $icon-ekey-fueloiltransport; 
  }
}
.icon-ekey-lngtransport {
  &:before {
    content: $icon-ekey-lngtransport; 
  }
}
.icon-ekey-naturalgastransport {
  &:before {
    content: $icon-ekey-naturalgastransport; 
  }
}
.icon-ekey-petroltransport {
  &:before {
    content: $icon-ekey-petroltransport; 
  }
}
.icon-ekey-transport {
  &:before {
    content: $icon-ekey-transport; 
  }
}
.icon-ekey-co2permanence {
  &:before {
    content: $icon-ekey-co2permanence; 
  }
}
.icon-ekey-humiditypermanence {
  &:before {
    content: $icon-ekey-humiditypermanence; 
  }
}
.icon-ekey-indoorairquality {
  &:before {
    content: $icon-ekey-indoorairquality; 
  }
}
.icon-ekey-indoorco2 {
  &:before {
    content: $icon-ekey-indoorco2; 
  }
}
.icon-ekey-indoorhumidity {
  &:before {
    content: $icon-ekey-indoorhumidity; 
  }
}
.icon-ekey-indoorpermanence {
  &:before {
    content: $icon-ekey-indoorpermanence; 
  }
}
.icon-ekey-indoortemperature {
  &:before {
    content: $icon-ekey-indoortemperature; 
  }
}
.icon-ekey-indoorvoc {
  &:before {
    content: $icon-ekey-indoorvoc; 
  }
}
.icon-ekey-indoorpressure {
  &:before {
    content: $icon-ekey-indoorpressure; 
  }
}
.icon-ekey-indoorparticulates {
  &:before {
    content: $icon-ekey-indoorparticulates; 
  }
}
.icon-ekey-particulatespermanence {
  &:before {
    content: $icon-ekey-particulatespermanence; 
  }
}
.icon-ekey-pressurepermanence {
  &:before {
    content: $icon-ekey-pressurepermanence; 
  }
}
.icon-ekey-temperaturepermanence {
  &:before {
    content: $icon-ekey-temperaturepermanence; 
  }
}
.icon-ekey-vocpermanence {
  &:before {
    content: $icon-ekey-vocpermanence; 
  }
}
.icon-ekey-biowaste {
  &:before {
    content: $icon-ekey-biowaste; 
  }
}
.icon-ekey-woodwaste {
  &:before {
    content: $icon-ekey-woodwaste; 
  }
}
.icon-ekey-electricalandelectronicwaste {
  &:before {
    content: $icon-ekey-electricalandelectronicwaste; 
  }
}
.icon-ekey-glasswaste {
  &:before {
    content: $icon-ekey-glasswaste; 
  }
}
.icon-ekey-hazardouswaste {
  &:before {
    content: $icon-ekey-hazardouswaste; 
  }
}
.icon-ekey-metalwaste {
  &:before {
    content: $icon-ekey-metalwaste; 
  }
}
.icon-ekey-mixednorecyclingwaste {
  &:before {
    content: $icon-ekey-mixednorecyclingwaste; 
  }
}
.icon-ekey-moldedfiberwaste {
  &:before {
    content: $icon-ekey-moldedfiberwaste; 
  }
}
.icon-ekey-paperwaste {
  &:before {
    content: $icon-ekey-paperwaste; 
  }
}
.icon-ekey-plasticwaste {
  &:before {
    content: $icon-ekey-plasticwaste; 
  }
}
.icon-ekey-totalwaste {
  &:before {
    content: $icon-ekey-totalwaste; 
  }
}
.icon-ekey-otherwaste {
  &:before {
    content: $icon-ekey-otherwaste; 
  }
}
.icon-ekey-wasterecycle {
  &:before {
    content: $icon-ekey-wasterecycle; 
  }
}
.icon-ekey-scop {
  &:before {
    content: $icon-ekey-scop; 
  }
}
.icon-ekey-cop {
  &:before {
    content: $icon-ekey-cop; 
  }
}
.icon-ekey-eer {
  &:before {
    content: $icon-ekey-eer; 
  }
}
.icon-ekey-seer {
  &:before {
    content: $icon-ekey-seer; 
  }
}
.icon-ekey-custom {
  &:before {
    content: $icon-ekey-custom; 
  }
}
.icon-ekey-ratio {
  &:before {
    content: $icon-ekey-ratio; 
  }
}
.icon-ekey-percentage {
  &:before {
    content: $icon-ekey-percentage; 
  }
}
.icon-ekey-chargingsystem {
  &:before {
    content: $icon-ekey-chargingsystem; 
  }
}
.icon-ekey-condensationheat {
  &:before {
    content: $icon-ekey-condensationheat; 
  }
}
.icon-ekey-coolingsystem {
  &:before {
    content: $icon-ekey-coolingsystem; 
  }
}
.icon-ekey-facilityelectricity {
  &:before {
    content: $icon-ekey-facilityelectricity; 
  }
}
.icon-ekey-heatpumps {
  &:before {
    content: $icon-ekey-heatpumps; 
  }
}
.icon-ekey-heatingelectricity {
  &:before {
    content: $icon-ekey-heatingelectricity; 
  }
}
.icon-ekey-lighting {
  &:before {
    content: $icon-ekey-lighting; 
  }
}
.icon-ekey-pue {
  &:before {
    content: $icon-ekey-pue; 
  }
}
.icon-ekey-refrigeration {
  &:before {
    content: $icon-ekey-refrigeration; 
  }
}
.icon-ekey-ventilation {
  &:before {
    content: $icon-ekey-ventilation; 
  }
}
.icon-ekey-totalenergy {
  &:before {
    content: $icon-ekey-totalenergy; 
  }
}
.icon-ekey-heatingenergy {
  &:before {
    content: $icon-ekey-heatingenergy; 
  }
}
.icon-ekey-totalenergypurchased {
  &:before {
    content: $icon-ekey-totalenergypurchased; 
  }
}
.icon-ekey-heatingpurchased {
  &:before {
    content: $icon-ekey-heatingpurchased; 
  }
}
.icon-ekey-carbondioxide {
  &:before {
    content: $icon-ekey-carbondioxide; 
  }
}
.icon-ekey-pellet {
  &:before {
    content: $icon-ekey-pellet; 
  }
}
.icon-ekey-heatingownproduction {
  &:before {
    content: $icon-ekey-heatingownproduction; 
  }
}
.icon-ekey-ownelectricity {
  &:before {
    content: $icon-ekey-ownelectricity; 
  }
}
.icon-ekey-coolingownproduction {
  &:before {
    content: $icon-ekey-coolingownproduction; 
  }
}
.icon-ekey-heavyoil {
  &:before {
    content: $icon-ekey-heavyoil; 
  }
}
.icon-ekey-oxygen {
  &:before {
    content: $icon-ekey-oxygen; 
  }
}
.icon-ekey-nitrogen {
  &:before {
    content: $icon-ekey-nitrogen; 
  }
}
.icon-ekey-liquefiedpetroleumgas {
  &:before {
    content: $icon-ekey-liquefiedpetroleumgas; 
  }
}
.icon-ekey-woodchips {
  &:before {
    content: $icon-ekey-woodchips; 
  }
}
.icon-ekey-biogas {
  &:before {
    content: $icon-ekey-biogas; 
  }
}
.icon-ekey-reactivepower {
  &:before {
    content: $icon-ekey-reactivepower; 
  }
}
.icon-ekey-reactivepowerplus {
  &:before {
    content: $icon-ekey-reactivepowerplus; 
  }
}
.icon-ekey-reactivepowerminus {
  &:before {
    content: $icon-ekey-reactivepowerminus; 
  }
}
.icon-ekey-dryice {
  &:before {
    content: $icon-ekey-dryice; 
  }
}
.icon-ekey-coolingwater {
  &:before {
    content: $icon-ekey-coolingwater; 
  }
}
.icon-ekey-wastewater {
  &:before {
    content: $icon-ekey-wastewater; 
  }
}
.icon-ekey-compressedair {
  &:before {
    content: $icon-ekey-compressedair; 
  }
}
.icon-ekey-districtheating {
  &:before {
    content: $icon-ekey-districtheating; 
  }
}
.icon-ekey-naturalgasother {
  &:before {
    content: $icon-ekey-naturalgasother; 
  }
}
.icon-ekey-naturalgas {
  &:before {
    content: $icon-ekey-naturalgas; 
  }
}
.icon-ekey-oil {
  &:before {
    content: $icon-ekey-oil; 
  }
}
.icon-ekey-electricity {
  &:before {
    content: $icon-ekey-electricity; 
  }
}
.icon-ekey-steam {
  &:before {
    content: $icon-ekey-steam; 
  }
}
.icon-ekey-iceagewater {
  &:before {
    content: $icon-ekey-iceagewater; 
  }
}
.icon-ekey-districtcooling {
  &:before {
    content: $icon-ekey-districtcooling; 
  }
}
.icon-ekey-water {
  &:before {
    content: $icon-ekey-water; 
  }
}
.icon-uniE600 {
  &:before {
    content: $icon-uniE600; 
  }
}
.icon-ekey-arrow-down {
  &:before {
    content: $icon-ekey-arrow-down; 
  }
}
.icon-ekey-arrow-right {
  &:before {
    content: $icon-ekey-arrow-right; 
  }
}
.icon-ekey-accept {
  &:before {
    content: $icon-ekey-accept; 
  }
}
.icon-ekey-cancel {
  &:before {
    content: $icon-ekey-cancel; 
  }
}
.icon-ekey-logo {
  &:before {
    content: $icon-ekey-logo; 
  }
}
.icon-ekey-options {
  &:before {
    content: $icon-ekey-options; 
  }
}
.icon-ekey-star {
  &:before {
    content: $icon-ekey-star; 
  }
}
.icon-ekey-arrow-left {
  &:before {
    content: $icon-ekey-arrow-left; 
  }
}
.icon-ekey-arrow-up {
  &:before {
    content: $icon-ekey-arrow-up; 
  }
}
.icon-ekey-groups {
  &:before {
    content: $icon-ekey-groups; 
  }
}
.icon-ekey-office {
  &:before {
    content: $icon-ekey-office; 
  }
}
.icon-ekey-dottedchart {
  &:before {
    content: $icon-ekey-dottedchart; 
  }
}
.icon-ekey-times {
  &:before {
    content: $icon-ekey-times; 
  }
}
.icon-ekey-thermometer {
  &:before {
    content: $icon-ekey-thermometer; 
  }
}
.icon-ekey-close {
  &:before {
    content: $icon-ekey-close; 
  }
}
.icon-ekey-leaf {
  &:before {
    content: $icon-ekey-leaf; 
  }
}
.icon-ekey-calendar {
  &:before {
    content: $icon-ekey-calendar; 
  }
}
.icon-ekey-bookmark {
  &:before {
    content: $icon-ekey-bookmark; 
  }
}
.icon-ekey-bulb {
  &:before {
    content: $icon-ekey-bulb; 
  }
}
.icon-ekey-chart {
  &:before {
    content: $icon-ekey-chart; 
  }
}
.icon-icon-notes-blank {
  &:before {
    content: $icon-icon-notes-blank; 
  }
}
.icon-icon-notes-content {
  &:before {
    content: $icon-icon-notes-content; 
  }
}
.icon-icon-notes-add {
  &:before {
    content: $icon-icon-notes-add; 
  }
}
.icon-ekey-add-file {
  &:before {
    content: $icon-ekey-add-file; 
  }
}

