$round-border-radius: $spacing-less;

inline-indicator {
  display: flex;
  align-items: center;
  align-self: stretch;

  .inline-indicator {
    height: $spacing-less;
    width: $spacing-less;
    margin-right: $spacing-less;

    &--round {
      border-radius: $round-border-radius;
    }
  }
}
