$enerkey-white: #fff;
$enerkey-black: #2c2c2c;
$enerkey-book-font: campton-book;
$enerkey-medium-font: campton-medium;
$enerkey-header-text: $enerkey-black;
$enerkey-modal-header-icon: $enerkey-white;
$enerkey-modal-header-text: $enerkey-white;

$enerkey-grey: #a89c96;
$enerkey-light-grey: #e0dddb;

$enerkey-button-border-radius: 2px;
$container-radius: 1rem;

$enerkey-link: darken(#4fbded, 10%);
$enerkey-link-hover: lighten($enerkey-link, 20%);

$enerkey-badge: #a30015;
