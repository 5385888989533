.manual-qa-change-time-frame-period {
  @include flex();

  & > div {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__left {
    align-items: flex-start;
  }

  &__right {
    align-items: flex-end;
  }
}
