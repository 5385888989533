// Brand elements
//
// Styleguide 2.0

// Typography
//
// Styleguide 2.1

// Headings
//
// markup:
// <h1>Heading 1</h1>
// <h2>Heading 2</h2>
// <h3>Heading 3</h3>
// <h4>Heading 4</h4>
// <h5>Heading 5</h5>
// <h6>Heading 6</h6>
//
// Styleguide 2.1.1
h1 {
  font-size: 2rem;
  padding: 20px 0;

  span {
    @include heading-font();
  }

  &.thin {
    @include heading-thin-font();
  }
}

h2 {
  font-size: rem-calc(16);
  text-transform: uppercase;
}

h3 {
  font-size: rem-calc(16);
}

.widget-content h3 {
  font-family: $font-stack;
  font-weight: 600;
}

.h-secondary {
  color: $enerkey-white;
}

// Body text
//
// markup:
// <p>
// Lorem ipsum dolor sit amet, consectetur adipiscing elit.
// Nunc sit amet odio eget nulla porttitor finibus sed a mauris.
// Praesent vehicula orci ante, sit amet venenatis est laoreet ut.
// Sed convallis nunc in justo tincidunt convallis. Phasellus faucibus vestibulum sem, vitae mattis odio.
// Ut quis magna suscipit, fringilla erat in, semper orci. Morbi sodales fringilla purus eget efficitur.
// Proin tincidunt ligula semper ultricies consectetur. Phasellus tincidunt sem nunc, sed ornare nulla commodo in.
// </p>
//
// Styleguide 2.1.2

// Bold text
//
// markup:
// <b>
// Lorem ipsum dolor sit amet, consectetur adipiscing elit.
// Nunc sit amet odio eget nulla porttitor finibus sed a mauris.
// Praesent vehicula orci ante, sit amet venenatis est laoreet ut.
// Sed convallis nunc in justo tincidunt convallis. Phasellus faucibus vestibulum sem, vitae mattis odio.
// Ut quis magna suscipit, fringilla erat in, semper orci. Morbi sodales fringilla purus eget efficitur.
// Proin tincidunt ligula semper ultricies consectetur. Phasellus tincidunt sem nunc, sed ornare nulla commodo in.
// </b>
//
// Styleguide 2.1.3

// Sup text (footnote)
//
// markup:
// <sup>
// Lorem ipsum dolor sit amet, consectetur adipiscing elit.
// Nunc sit amet odio eget nulla porttitor finibus sed a mauris.
// Praesent vehicula orci ante, sit amet venenatis est laoreet ut.
// Sed convallis nunc in justo tincidunt convallis. Phasellus faucibus vestibulum sem, vitae mattis odio.
// Ut quis magna suscipit, fringilla erat in, semper orci. Morbi sodales fringilla purus eget efficitur.
// Proin tincidunt ligula semper ultricies consectetur. Phasellus tincidunt sem nunc, sed ornare nulla commodo in.
// </sup>
//
// Styleguide 2.1.4

// Text link
//
// :link - test
// :visited - test
// :hover - test
// :active - test
//
// markup:
// <a class="{$modifiers}" href="#">Link to other page</a>
//
// Styleguide 2.1.5
a {
  font-weight: $enerkey-link-font-weight;
}

label {
  font-size: $body-font-size
}

// Footnote
//
// markup:
// <div class="footnote">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
//
// Styleguide 2.1.6
.footnote {
  color: $enerkey-footnote;
  font-style: italic;
}

html, body, div, applet, object, iframe,
p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, select, input,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  font-family: $font-stack;
}
