.ek-dropdown-menu {
  box-sizing: content-box;
  position: absolute;
  padding: 1rem;
  background-color: $enerkey-white;
  border: 1px solid $enerkey-light-grey;
  z-index: 99;
  box-shadow: 0 0 6px 0 $enerkey-light-grey;

  &__content {
    font-size: 1rem;
  }
}
