@import 'variables';

// Containers
//
// Containers are blocks that include components
//
// Styleguide 6.0

// Panel
//
// Panel is a simple container for elements
//
// markup:
// <div class="panel">Lorem ipsum dolor sit amet.</div>
//
// Styleguide 6.1
.panel {
  padding: 0;
  height: 100%;
  border: 0;

  &.toggle {
    min-height: 0;

    h4 {
      cursor: pointer;
    }

    i {
      font-size: 120%;
    }
  }

  .panel-title {
    text-transform: uppercase;
    color: $enerkey-header-text;
    font-size: 1rem;
  }
}

.facility  {
  .panel {
    background: transparent;
  }
}

// Module
//
// Module is similar to panel, but it does not have minimum height
//
// markup:
// <div class="module">Lorem ipsum dolor sit amet.</div>
//
// Styleguide 6.3

.module {
  background: $enerkey-white;
  margin-top: 25px;
  border: 1px solid #d9d9d9;
  @include box-shadow(0px 1px 5px rgba(0, 0, 0, 0.1));
  @include border-radius(3px);

  .module-header {
    padding: 12px 20px;
    line-height: 32px;
    background: #fbfbfb;
    border-bottom: 1px solid #d9d9d9;
    @include box-shadow(0px 1px 5px rgba(0, 0, 0, 0.1));
    @include border-top-radius(3px);

    h3 {
      float: left;
      width: auto;
      color: #2a2a2a;
      font-size: 14px;
      line-height: 32px;
      margin: 0;
      padding-right: 10px;
      text-shadow: 0 1px 1px rgba(255, 255, 255, 0.6);
    }

    .tag {
      display: inline-block;
      margin: 0 0 0 8px;
      padding: 0 12px;
      background-color: #cecece;
      font-size: 14px;
      line-height: 24px;
      @include border-radius(20px);
    }
  }
}

// Accordion plus minus replaced with
// fa-chevron-right and fa-chevron-down
.accordion-title {
  &::before {
    /* stylelint-disable-next-line */
    font-family: $font-awesome;
    content: '\f054';
    font-weight: 600;
  }

  .is-active > &::before {
    content: '\f078';
  }
}

// EnerKey Accordion
//
// Foundation accordion with a dash of EnerKey special styling
//
// markup:
//      <accordion class="ek-accordion">
//        <accordion-group is-open="firstOpen">
//          <accordion-heading>
//            First section
//            <span class="fa" ng-class="{'fa-chevron-down': firstOpen, 'fa-chevron-right': !firstOpen}"></span>
//          </accordion-heading>
//          <p>Accordion content</p>
//          <p>Accordion content</p>
//          <p>Accordion content</p>
//        </accordion-group>
//        <accordion-group is-open="lastOpen">
//          <accordion-heading>
//            Last section
//            <span class="fa" ng-class="{'fa-chevron-down': lastOpen, 'fa-chevron-right': !lastOpen}"></span>
//          </accordion-heading>
//          <p>Accordion content</p>
//          <p>Accordion content</p>
//          <p>Accordion content</p>
//        </accordion-group>
//      </accordion>
//
// Styleguide 24.3
/* stylelint-disable-next-line */
accordion.ek-accordion {
  .accordion-title {
    padding: 1rem 0.75rem;
    background-color: $enerkey-input-background;
    color: $enerkey-black;
    font-weight: bold;
    font-size: 1rem;
    border: $accordion-content-border;
  }

  .accordion-content {
    padding: 0.75rem;
  }

  .accordion-item {
    margin-top: -1px;

    &.is-active {
      margin: 0 0 1px 1px;
      color: $enerkey-white;
      border: 2px solid $enerkey-primary;
      border-top-width: 1px;

      & > a {
        background-color: $enerkey-black;
        color: #fff;
      }

      & > .accordion-title {
        border: none;
      }
    }
  }
}

.ek-accordion__group--invalid.ek-accordion__group--invalid.ek-accordion__group--invalid {
  /* stylelint-disable-next-line */
  a, a.active {
    color: $enerkey-invalid-text;
  }
}

/* stylelint-disable-next-line */
accordion.ek-accordion--nopadding {
  .accordion-item.is-active {
    .accordion-content {
      padding: 0;
    }
  }
}

.ek-accordion__heading-notification {
  background-color: $enerkey-highlight;
  padding: 0.4rem 0.7rem;
  color: $enerkey-white;
  border-radius: 0.9rem;
  margin-left: 0.5rem;
}

.ek-accordion__heading-alert {
  background-color: $enerkey-panel-background;
  padding: 0.4rem 0.7rem;
  color: $enerkey-black;
  border-radius: 5px;
  margin-left: 0.5rem;
  font-weight: normal;

  .fa {
    color: $enerkey-red;
  }
}

// styleguide:ignore:start
.app-container {
  margin: 0 $app-container-margin;
  @include calc('padding-top', '#{$topbar-height}');
}

.sidebar-container {
  //border-right: 1px solid $enerkey-input-border;
  box-shadow: 1px 1px 1px 0px rgba(157,157,157,0.70);
  background-color: $enerkey-input-background;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: 3;
  display: none;
  margin-left: -$spacing-default;

  .sidebar-toggle {
    cursor: pointer;
    width: $sidebar-width;
    background: $enerkey-input-background;
    border-bottom: 1px solid $light-gray;
    z-index: 1;
    display: none;
  }

  &.visible {
    display: block;
    max-width: 100%;
    width: $sidebar-width;
    height: auto;
    top: $topbar-height;
    position: fixed;
    bottom: 0;

    .sidebar-toggle {
      display: flex;
      align-items: center;
      height: $content-header-height;
    }

    &.collapsed {
      width: $sidebar-width-collapsed;

      .sidebar-toggle {
        width: $sidebar-width-collapsed;
      }
    }

    &:not(.collapsed) {
      .sidebar-content {
        overflow-y: scroll;
      }
    }

    &.collapsed, &.floating {
      &.visible {
        &~.report-container {
          padding-left: $sidebar-width-collapsed;

          .facilities-mobile {
            margin-left: -25px;
          }
        }

        &~.modal-state-body {
          @include calc('padding-left', #{$sidebar-width-collapsed + 12px});
        }
      }
    }

    &:not(.collapsed):not(.floating)~.report-container {
      padding-left: $sidebar-width;
    }

    &:not(.collapsed):not(.floating)~.modal-state-body {
      @include calc('padding-left', #{$sidebar-width + 12px});
    }

    @media print {
      &:not(.collapsed):not(.floating) ~ .report-container {
        /* stylelint-disable-next-line */
        padding-left: 0 !important;
      }

      &:not(.collapsed):not(.floating) ~ .modal-state-body {
        /* stylelint-disable-next-line */
        padding-left: 0 !important;
      }
    }
  }

  .sidebar-toggle-filler {
    height: 40px;
  }

  .sidebar-toggle-text {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    padding-left: rem-calc(10);

    &--vertical {
      @include transform(rotate(90deg));
      transform-origin: 0 100%;
      white-space: nowrap;
      padding: 0;
    }
  }

  .sidebar-toggle-button {
    background-color: $enerkey-footnote;
  }

  .sidebar-content {
    overflow-x: hidden;
    @include calc('height', '100% - #{$content-header-height}');
    position: relative;
  }
}

.fixed {
  /* Force GPU to disable blinking on mobile browsers */
  -webkit-transform: translateZ(0);
}

.fixed-placeholder {
  visibility: hidden;

  div {
    /* stylelint-disable-next-line */
    visibility: hidden !important;
  }
}

table {
  .cell-number {
    text-align: right;
  }

  .cell-relative {
    position: relative;
  }

  .cell-center {
    text-align: center;
  }

  .cell-filler {
    margin-top: 20px;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bookmarks-modal {
  .add-bookmark-input {
    margin-bottom: 0;
    margin-right: 1rem;
  }

  .no-bookmarks {
    color: $enerkey-input-text;
    line-height: 1.6rem;
  }
}

.grid-overlay {
  filter: brightness(0.9);
  pointer-events: none;
}

// styleguide:ignore:end

// Dropdown menu
//
// Dropdown menu with text as toggle.
//
// markup:
//  <div class="ek-dropdown-menu">
//    <dropdown-toggle close-on-click="true" pane-align="right">
//      <toggle>
//        <a class="dropdown-title">Title <i class="fa fa-caret-down"></i></a>
//      </toggle>
//      <pane class="dropdown-menu">
//        <div ng-click="doSomething(); $close();"><i class="fa fa-caret-right"></i>Item</div>
//      </pane>
//    </dropdown-toggle>
//  </div>
//
// Styleguide 6.6
.ek-dropdown-menu {
  .dropdown-pane {
    padding: 0;
  }

  .dropdown-title {
    line-height: 2rem;
  }

  .dropdown-menu {
    > div {
      cursor: pointer;
      font-size: rem-calc(14);
      padding: rem-calc(8) rem-calc(12);
      text-align: left;

      .fa {
        margin: 0 rem-calc(6) 0 0;
      }

      &:hover {
        background-color: $enerkey-extra-light-grey;
        position: relative;
        z-index: 2;
      }

      &.disabled {
        color: $enerkey-light-grey;
      }
    }
  }
}

.graph.card > .header-group {
  border-radius: $container-radius $container-radius 0 0;
}

.header-group {
  display: flex;
  align-items: center;
  width: 100%;
  padding: .5em;

  button {
    margin: 0; // Should be deleted
  }

  &--fade {
    position: relative;

    &:after {
      content: "";
      top: 0;
      height: 100%;
      right: 0;
      width: 30px;
      position: absolute;
    }
  }

  &__label {
    margin-left: $spacing-less;
    margin-right: $spacing-less;
    font-size: 1em;
    margin-bottom: 0;

    white-space: nowrap;
    overflow: hidden;

    &__info {
      padding-left: .5em;

      &:before {
        content: "(";
      }

      &:after {
        content: ")";
      }
    }
  }

  &__icon {
    padding-left: .5em;
  }
}

.widget-content-container {
  padding: 20px 0;
  height: 100%;
}

.widget-actions-container {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 20px;
}

.row {
  &--sidebar {
    display: flex;
    margin-bottom: 0.75rem;
  }

  &--right {
    justify-content: flex-end;
  }

  &__label {
    display: flex;
    height: 100%;
    align-items: center;

    &--two-columns {
      flex: 1 0 50%;
    }

    &--quantity {
      margin-bottom: 1rem;
    }
  }
}

.row .row {
  margin-left: 0;
  margin-right: 0;
  @include breakpoint(large up) {
    & {
      margin-left: rem-calc(-15);
      margin-right: rem-calc(-15);
    }
  }
}

.flex-center-center {
  @include flex-center-center();
}

.content-right {
  margin-left: auto;
}

.flex-box {
  @include flex();
}
