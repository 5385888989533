// Badges
//
// Default - TODO
//
// markup:
// <span class="badge badge--topbar">1</span>
// <span class="badge badge--topbar">10</span>
// <span class="badge badge--topbar">100</span>
//
// sg-wrapper:
// <div>
//   <sg-wrapper-content/>
// </div>
//
// Styleguide 4.7
.badge {
  &--topbar {
    font-size: 10px;
    position: relative;
    min-width: 1.7em;
    padding: 0;
    top: -12px;
    margin-left: -8px;
    line-height: 1.7;
    background-color: var(--enerkey-badge);
    color: var(--enerkey-badge-text);
  }
}
