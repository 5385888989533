.consumption-targets {
  &__grid {
    td.year-cell {
      cursor: pointer;

      &:before {
        font-family: $font-awesome;
        content: "\f067";
        color: var(--enerkey-primary);
        margin-right: 5px;
        font-weight: 900;
      }
    }

    .k-grouping-row {
      td {
        padding: 0;
        height: 100%;
      }

      p {
        display: block;
      }
    }

    .k-grouping-header {
      display: none;
    }

    .group-header-container {
      padding: 5px;
      color: var(--enerkey-white);

      &__facility {
        color: var(--enerkey-black);
      }

      &.mass-ops {
        margin-left: 3.7em;
      }

      display: flex;

      .unit {
        top: 0.3rem;
        position: relative;
        margin-left: auto;
      }

      .quantity-info {
        padding-left: 2.5em;

        .quantity-name {
          margin-left: 1em;
          width: 16em;
          display: inline-block;
        }

        .icon-container {
          display: inline-flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          background-color: var(--enerkey-white);
          width: 27px;
          height: 27px;
          font-size: 1.25rem;
        }
      }
    }

    tr:not(.k-grouping-row) + tr.topBorder {
      > td:not(.k-group-cell) {
        border-top: 1px solid var(--enerkey-light-grey);
      }
    }

    .k-grid-content tr {
      height: 47px;

      .k-reset {
        .k-icon {
          display: none;
          font-size: 0.9rem;
          color: $enerkey-white;
          float: right;
          right: 1rem;
          top: 0.6rem;
          font-family: $font-awesome, sans-serif;
          font-weight: 600;

          &.k-i-collapse::before {
            content: "\f054";
          }

          &.k-i-expand::before {
            content: "\f078";
          }
        }
      }
    }
  }
}

.cell-relative-position {
  position: relative;
}
