a.topbar-icon-item {
  &:hover {
    color: var(--enerkey-topbar-icon);
  }
}

#top-bar-container {
  .tabs-overflow {
    .ek-dropdown-menu {
      padding: 0;
    }
  }
}

.topbar-mobile-title {
  margin-left: 6px;
  font-family: var(--heading-thin-font);
  font-weight: var(--heading-thin-font-weight);
  font-size: 110%;
}

.mobile-topbar-content {
  .topbar-icon-item {
    i {
      font-size: 1.2rem;
    }
    width: auto;
    text-align: left;
    padding-left: var(--spacing-default);
    user-select: none;
  }
}

.topbar-tab {
  user-select: none;
  padding-left: var(--spacing-default);
  padding-right: var(--spacing-default);
  display: block;
  line-height: var(--topbar-height);
  height: var(--topbar-height);
  color: var(--enerkey-topbar-text);
  transition: box-shadow 0.15s;
  font-size: 110%;
  font-family: var(--heading-thin-font);
  font-weight: var(--heading-thin-font-weight);
  white-space: nowrap;

  &:hover {
    box-shadow: var(--topbar-item-hover-shadow);
  }

  &:hover, &:focus {
    color: var(--enerkey-topbar-text);
  }

  &.is-active {
    box-shadow: var(--topbar-item-active-shadow);
  }
}

.topbar-icon-item {
  height: var(--topbar-height);
  width: var(--topbar-height);
  color: var(--enerkey-topbar-icon);
  display: block;
  text-align: center;
  line-height: var(--topbar-height);

  i {
    font-size: 1.55rem;
  }

  &:hover {
    box-shadow: var(--topbar-item-hover-shadow);
  }
}

.reveal {
  .topbar-icon-item {
    height: var(--modal-header-height);
    width: var(--modal-header-height);
    line-height: var(--modal-header-height);
  }
}
