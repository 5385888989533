// Button groups
//
// Some fancy text about button groups
//
// Default               - TODO
// .button-group--wide   - TODO
// .button-group--right  - TODO
// .button-group--center - TODO
//
// markup:
// <ul class="button-group {$modifiers}">
//   <li>
//     <button class="button">Button primary</button>
//   </li>
//   <li>
//     <button class="button button--negative">Button negative</button>
//   </li>
//   <li>
//     <button class="button button--secondary">Button secondary</button>
//   </li>
// </ul>
//
// Styleguide 5.9
.button-group {
  display: flex;
  list-style: none;
  margin: 0;

  li {
    display: inline-block;
    padding-right: 1px;
  }

  &--wide {
    justify-content: space-between;
  }

  &--right {
    float: right;
  }

  &--center {
    justify-content: center;
  }
}