meter-cost-mass-edit {
  .setup-step-field {
    max-width: 20rem;
  }

  .k-spreadsheet {
    width: auto;
  }

  .k-spreadsheet-action-bar {
    display: none;
  }

  .k-state-disabled {
    opacity: 1;
    color: $enerkey-black;
  }

  .failed-meters {
    margin-bottom: var(--spacing-default);
    margin-left: var(--spacing-less);
    color: var(--enerkey-red);
  }
}
