$em-visuals-title-bar-height: 35px;
$em-visuals-chart-width: 400px;

%horizontal-line {
  border-bottom: 1px solid $enerkey-light-grey;
  max-width: 100%;
}

.em-visuals {
  &__header-text {
    margin: 0 $spacing-more 0 0;
  }

  &__horizontal-line {
    @extend %horizontal-line;

    &--more-spacing {
      @extend %horizontal-line;
      margin: $spacing-more 0;
    }
  }

  &__header-container {
    display: flex;
    align-items: center;
  }

  &__too-many-groups {
    font-weight: bold;
    margin: 30px 0 100px;
    text-align: center;
  }
}
