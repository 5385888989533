.content-header {
  display: flex;
  align-items: center;
  box-sizing: content-box;
  padding-left: rem-calc(15);
  padding-right: rem-calc(15);
  background: $enerkey-content-header-background;
  border-bottom: 1px solid $light-gray;
  height: $content-header-height;
  margin-bottom: 1em;
  margin-left: rem-calc(-15);
  margin-right: rem-calc(-15);
  overflow-x: auto;
  overflow-y: hidden;

  &--no-margin {
    margin: 0;
  }

  &--no-border {
    border-bottom: none;
  }

  .button, .select, .label {
    margin: auto 0 auto 8px;

    &:first-child {
      margin: 0;
    }
  }

  .tabs {
    height: 100%;
  }
}
