@import '../components/components';
@import 'meter-search';
@import 'meter-reorder';
@import 'meter-groups';

.admin-edit-form.modal-body {
  .row {
    padding: 0;
  }

  textarea {
    height: 8rem;
  }

  .k-autocomplete {
    input {
      height: inherit;
    }
  }

  .multiSelect {
    .checkboxLayer {
      max-height: 400px;
      overflow: auto;
    }
  }

  label {
    cursor: default;
    white-space: nowrap;
    font-weight: bold;
    padding: 0.5rem 0 0.2rem;
  }
}

.admin-toolbar {
  .float-right > * {
    display: inline-block;
    margin-left: 1em;
  }
}

.copy-usersettings-modal {
  .multiSelect .checkboxLayer {
    max-height: 300px;
    overflow: auto;
  }
}

.actionLog {
  list-style: none;
  max-height: 550px;
  overflow-y: auto;
  margin-left: .3rem;
}

.select-multiple-profile {
  .multiSelect > button {
    max-width: none;
  }

  .multiSelect .checkboxLayer {
    max-height: 600px;
    overflow: auto;
  }
}

.select-roles {
  .multiSelect > button {
    height: unset
  }
}

.bulk-modification-modal {
  min-height: 400px;

  .modal-footer {
    position: absolute;
    bottom: 0;
  }
}

.bulk-modify-roles-services {
  min-height: 600px;

  .multiSelect .checkboxLayer {
    max-height: 350px;
    overflow: auto;
  }
}

.bulk-modify-profiles {
  min-height: 600px;

  .multiSelect {
    .checkBoxContainer {
      max-height: 550px;
      overflow: auto;
    }

    > button {
      max-height: 100px;
      overflow: auto;
    }
  }
}

.grid-columns--right {
  text-align: right;

  .dropdown-pane {
    text-align: left
  }
}

.profile-name-cell {
  line-height: 29px;
}

.admin-profile-edit {
  &__remove-facilities-row {
    margin: $spacing-default $spacing-default $spacing-more;
  }
}

.k-grid .cell-removed {
  color: $enerkey-footnote;
  cursor: not-allowed;
  text-decoration: line-through;
}

.virtualMeterFormulaWrapper {
  .k-treelist {
    td {
      padding-top: 0;
      padding-bottom: 0;

      &:first-of-type {
        .k-dirty {
          display: none;
        }

        .k-icon {
          &.k-i-none, &.k-i-expand, &.k-i-collapse {
            padding-left: 1em;
          }

          &:nth-of-type(2) {
            padding-left: 0 !important;
          }

          &:first-of-type {
            display: none;
          }
        }

        .depthIndicator {
          visibility: visible;
          background-color: $enerkey-primary;
          vertical-align: middle;
          width: 6px;
          display: inline-block;
          margin-right: $spacing-default;
          height: 30px;

          &.formulaError {
            background-color: $enerkey-red;
          }
        }
      }
    }
  }
}

.noGroupingHeader {
  .k-grouping-header {
    display: none;
  }
}
