// Indicators
//
// Styleguide 25.8

// Square indicators with numbers
//
// Indicator is a simple inline element for different kind of notifications (info, error, warning and success).
//
// .indicator--success - TODO
// .indicator--success-text - TODO
// .indicator--fail-text - TODO
// .indicator--nodata-text - TODO
// .indicator--notrun-text - TODO
// .indicator--info - TODO
// .indicator--alert - TODO
// .indicator--warning - TODO
// .indicator--lilac - TODO
// .indicator--yellow - TODO
// .indicator--dark-yellow - TODO
// .indicator--orange - TODO
// .indicator--blue - TODO
// .indicator--grey - TODO
// .indicator--light-pink - TODO
// .indicator--light-lilac - TODO
// .indicator--light-green - TODO
// .indicator--dark-pink - TODO
// .indicator--dark-green - TODO
//
// markup:
// <span class="indicator {$modifiers}">12</span>
// <span class="indicator indicator--active {$modifiers}">12</span>
// <span class="indicator indicator--small {$modifiers}">12</span>
// <span class="indicator indicator--sup {$modifiers}">12</span>
// <span class="indicator indicator--highlight {$modifiers}">12</span>
//
// Styleguide 25.8.1

.indicator {
  font-size: rem-calc(14);
  line-height: normal;
  border-radius: 5px;
  padding: 0.4rem;
  color: #fff;
  margin: 2px;
  font-weight: bold;

  &.indicator--active {
    color: #000;
    background-color: #fff;
  }

  &.indicator--small {
    font-size: rem-calc(13);
    border-radius: 4px;
    padding: 0.2rem;
    margin: 0;
  }

  &.indicator--sup {
    @extend .indicator--small;
    margin: -0.2rem;
    vertical-align: super;
  }

  &.indicator--highlight {
    box-shadow: 0 0 5px #fff;
  }

  &--success, &--green {
    background-color: $enerkey-green;
  }

  &--success-text, &--green {
    color: $enerkey-green;
  }

  &--fail-text, &--red {
    color: $enerkey-red;
  }

  &--nodata-text, &--grey {
    color: $enerkey-footnote;
  }

  &--notrun-text, &--blue {
    color: $enerkey-primary;
  }

  &--warning-text, &--yellow {
    color: $enerkey-naturalgas;
  }

  &--info, &--light-blue {
    background-color: $enerkey-light-blue;
  }

  &--alert, &--pink {
    background-color: $enerkey-highlight;
  }

  &--warning, &--yellow {
    background-color: $enerkey-naturalgas;
    color: #000;
  }

  &--lilac {
    background-color: $enerkey-steam;
  }

  &--yellow {
    background-color: $enerkey-naturalgas;
  }

  &--dark-yellow {
    background-color: $enerkey-naturalgas-secondary;
  }

  &--orange {
    background-color: $enerkey-liquefiedpetroleumgas;
  }

  &--blue {
    background-color: $enerkey-primary-active;
  }

  &--grey {
    background-color: $enerkey-footnote;
  }

  &--light-pink {
    background-color: lighten($enerkey-highlight, 20%)
  }

  &--light-lilac {
    background-color: lighten($enerkey-steam, 20%)
  }

  &--light-green {
    background-color: lighten($enerkey-green, 20%)
  }

  &--dark-pink {
    background-color: darken($enerkey-highlight, 20%)
  }

  &--dark-green {
    background-color: darken($enerkey-green, 20%)
  }
}

// Trend
//
// .trend--asc - asc
// .trend--desc - desc
// .trend--normal - normal
//
// markup:
// <span class="trend {$modifiers} fa fa-location-arrow" style="font-size: 10px">100%</span>
// <span class="trend {$modifiers} fa fa-location-arrow" style="font-size: 20px">100%</span>
// <span class="trend {$modifiers} fa fa-location-arrow" style="font-size: 30px">100%</span>
// <span class="trend {$modifiers} fa fa-location-arrow" style="font-size: 40px">100%</span>
// <span class="trend {$modifiers} fa fa-location-arrow" style="font-size: 50px">100%</span>
//
// Styleguide 25.8.4

.trend {
  &:before {
    display: inline-block;
    /* stylelint-disable-next-line */
    font-family: 'Font Awesome 5 Free';
    content: "\F124";
    margin: 0 rem-calc(5) 0 0;
    font-weight: 600;
    font-size: 0.75rem;
  }

  &--asc {
    color: $enerkey-red;
  }

  &--desc {
    color: $enerkey-green;

    &:before {
      @include transform(rotate(90deg));
    }
  }

  &--normal {
    color: $enerkey-black;

    &:before {
      @include transform(rotate(45deg));
    }
  }
}
