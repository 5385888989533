/* stylelint-disable */

// Cards
//
// Styleguide 5.2

// Card with toolbar
//
// General purpose informational container for energy reporting related data, like consumptions. Works together
// with Gridster.
//
// .enerkey-actions - Use a modifier such as this one to set the background color of the header
//
// markup:
//<div class="card">
//  <div class="card__header {$modifiers}">
//    <div class="card__title-container card__title--toolbar-yes">
//      <div class="card__title__icon-container">
//        <i class="fa fa-check card__title__icon" aria-hidden="true"></i>
//      </div>
//      <h3 class="card__title">Facility actions</h3>
//    </div>
//    <div class="card__toolbar">
//      <h3 class="card__toolbar-text text--hover">Show actions</h3>
//      <h3 class="card__toolbar-text text--hover">Add new</h3>
//    </div>
//  </div>
//  <div class="card__content no-toolbar">
//    Here you would have some content
//    <div class="card__content--loading" ng-if="vm.facilityActions.length == 0">
//      <p class="text--small">No actions</p>
//    </div>
//  </div>
//  <div class="card__footer" ng-if="!item.InvalidState">
//    Use the footer to show a some information or the chevron icon for card minimize/maximize function
//  </div>
//</div>
//
// Styleguide 5.2.1
.card {
  &.graph {
    border-radius: $container-radius;
  }

  position: relative;
  background-color: $enerkey-white;
  -webkit-transition: opacity .2s, left .2s, top .2s, width .2s, height .2s !important;
  -moz-transition: opacity .2s, left .2s, top .2s, width .2s, height .2s !important;
  -o-transition: opacity .2s, left .2s, top .2s, width .2s, height .2s !important;
  transition: opacity .2s, left .2s, top .2s, width .2s, height .2s !important;
  box-shadow: $enerkey-card-shadow;

  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  &__content {
    overflow-y: hidden;
    width: 100%;

    &__timeperiod{
      margin: 5px 0 10px 0;
      opacity: 0.7;
    }
    &__secondary-information{
      opacity: 0.7;
    }
  }
  .no-toolbar{
    height: 193px;
  }
  &__header {
    display: -ms-flexbox;
    display: flex;
    height: 42px;
    width: 100%;
    background: mix($enerkey-primary, #ffffff, 80%);

    .card__title-container {
      overflow-x: hidden;
      white-space: nowrap;
      width: 80%;
      padding: rem-calc(8);
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;

      &.card__title--toolbar-yes {
        width: auto;
        max-width: 50%;
        margin-right: auto;
      }

      &.card__title--fade {
        position: relative;

        &:after {
          content: "";
          top: 0;
          height: 100%;
          right: 0;
          width: 30px;
          position: absolute;
        }

      }

      .card__title {
        color: $enerkey-white;
        margin: 0;
      }
    }
  }

  @media only screen and (min-width: 800px) {
    margin-bottom: 15px;
  }
}

.card__toolbar {
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid rgba(128,128,128,0.55);

  &--right-icon {
    margin-top: 2px;
    margin-left: 5px;
  }
}

.card__toolbar-text {
  min-width: 20%;
  padding: rem-calc(12);
  //font-size: rem-calc(16);
  color: #ffffff;
  text-align: right;
  white-space: nowrap;
  margin-bottom: 0;

  &--mobile{
    font-weight: bold;
  }
}

/* MODIFIERS */
.width-100 {
  width: 100%;
}
