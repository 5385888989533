@import "mixins";
// Colors
//
// Styleguide 2.2

// Main colors
//
// $enerkey-white            - General white in EnerKey portal
// $enerkey-black            - Genral black in EnerKey portal
// $enerkey-primary          - Color for primary actions, such as buttons
// $enerkey-primary-active   - Color for active state of top bar menu items
// $enerkey-secondary        - Color for widget- and modal headers and separators in grid headings
// $enerkey-secondary-light  - Color for actions impact grid visualization
// $enerkey-highlight        - Warnings, notifications and loading bar
// $enerkey-error            - Error color eg. in invalid form fields
// $enerkey-green            - Used in indicators on positive change values
// $enerkey-red              - red used in alerts
// $enerkey-footnote         - Footnotes and explanatory texts used with main content and headings
// $enerkey-light-grey       - Used as a light grey background
// $enerkey-extra-light-grey - Used as an extra light grey background
// $enerkey-super-light-grey - Used as a super light grey background
//
// markup:
// <div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>
//
// Styleguide 2.2.1
$enerkey-white: #fff !default;
$enerkey-black: #2c2c2c !default;

$enerkey-primary: #7ca77c !default;
$enerkey-primary-active: darken(desaturate($enerkey-primary, 1.1), 11.37) !default;
$enerkey-warning: #ffa500 !default;

$enerkey-secondary: #9fbbbe !default;
$enerkey-secondary-light: lighten($enerkey-secondary, 15%) !default;
$enerkey-highlight: #fd7d7c !default;
$enerkey-error: #fd7d7c !default;
$enerkey-green: #30b000 !default;
$enerkey-blue: #132878 !default;
$enerkey-red: #cd1f3c !default;
$enerkey-light-blue: #0099cc !default;
$enerkey-under-investigation: #f7b33c !default;
$enerkey-no-investigation-required: #cfd6e9 !default;

$enerkey-grey: #9e9e9e !default;
$enerkey-light-grey: #c7c7c7 !default;
$enerkey-extra-light-grey: #f2f2f2 !default;
$enerkey-super-light-grey: #f9f9f9 !default;

$enerkey-footnote: #9e9e9e !default;
$enerkey-button-text: $enerkey-white !default;

$enerkey-loading-background: transparent !default;
$enerkey-loading-foreground: $enerkey-highlight !default;

// Topbar colors
//
// $enerkey-topbar          - used as background color in top navigation
// $enerkey-topbar-primary  - background color of user settings dropdown menu in topbar
// $enerkey-header-text     - default header item color (icons, links and text)
//
// markup:
// <div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>
//
// Styleguide 2.2.2
$enerkey-topbar: #517770 !default;
$enerkey-topbar-primary: $enerkey-secondary !default;
$enerkey-topbar-text: $enerkey-white !default;
$enerkey-topbar-icon: $enerkey-white !default;
$enerkey-header-text: $enerkey-white !default;
$enerkey-modal-header: $enerkey-topbar !default;
$enerkey-modal-close-background: $enerkey-secondary !default;
$enerkey-modal-header-icon: $enerkey-header-text !default;
$enerkey-modal-header-text: $enerkey-header-text !default;
$enerkey-content-header-background: $enerkey-white !default;

// Background colors
//
// $enerkey-background       - background color
// $enerkey-panel-background - background color for panels
//
// markup:
// <div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>
//
// Styleguide 2.2.3
$enerkey-background: #f0f0f0 !default;
$enerkey-panel-background: #ffffff !default;
$enerkey-background-overlay: rgba($enerkey-background, 0.4);

// Secondary menu colors
//
// $enerkey-input-background - dropdown background color
// $enerkey-input-text       - dropdown text color
// $enerkey-popup-menu-bg     - background color for pop-up menus (e.g. add widgets -menu)
//
// markup:
// <div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>
//
// Styleguide 2.2.4
$enerkey-input-background: #ffffff !default;
$enerkey-input-border: $enerkey-light-grey !default;
$enerkey-input-text: #8a91ae !default;
$enerkey-popup-menu-bg: #fff !default;
$enerkey-input-shadow: #dddddd !default;

// Toaster styling
$enerkey-toaster-margin: 6px !default;
$enerkey-toaster-info: #2f96b4 !default;
$enerkey-toaster-success: $enerkey-green !default;
$enerkey-toaster-warning: $enerkey-warning !default;
$enerkey-toaster-error: $enerkey-red !default;

// Buttons and action icon colors
//
// $enerkey-primary-action-hover - color for primary buttons and action icons hover
// $enerkey-button-secondary - secondary button color, cancel/
// $enerkey-secondary-action-hover - color for secondary buttons hover
// $enerkey-remove-action - color for remove buttons and action icons
// $enerkey-remove-action-hover - color for remove buttons and action icons hover
// $enerkey-badge - color for unread/new notifications indicator icon
// $enerkey-link - Link color
//
// markup:
// <div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>
//
// Styleguide 2.2.5
$enerkey-primary-action-hover: lighten($enerkey-primary, 10%) !default;
$enerkey-secondary-action: #d2d2d2 !default;
$enerkey-secondary-action-hover: #e4e4e4 !default;
$enerkey-remove-action: #ac1313 !default;
$enerkey-remove-action-hover: lighten($enerkey-remove-action, 10%) !default;
$enerkey-badge: $enerkey-highlight !default;
$enerkey-badge-text: $enerkey-white !default;
$enerkey-link: $enerkey-primary !default;
$enerkey-link-hover: darken($enerkey-link, 20%) !default;

// Grid colors
//
// $enerkey-grid-row-light         - light background color for table row
// $enerkey-grid-row-dark          - dark background color for table row
// $enerkey-grid-header-background - grid header background color
// $enerkey-grid-footer-background - grid footer background color
// $enerkey-grid-header-text       - facilities grid header text color
// $enerkey-grid-footer-text       - facilities grid footer text colour
// $enerkey-grid-border            - border color for facilities grid
//
// markup:
// <div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>
//
// Styleguide 2.2.6
$enerkey-grid-row-light: #f9f9f9 !default;
$enerkey-grid-row-dark: #f0f1f5 !default;
$enerkey-grid-header-background: #fff !default;
$enerkey-grid-footer-background: #fff !default;
$enerkey-grid-header-text: #2c2c2c !default;
$enerkey-grid-footer-text: #2c2c2c !default;
$enerkey-grid-border: $enerkey-topbar !default;

// Chart colors
$enerkey-chart-first-comparison: #7682a2 !default;
$enerkey-chart-second-comparison: #99a5cc !default;

// Other colors
//
// $enerkey-header-general               - used as a header background color on cards with general information (other than energy type headers)
// $enerkey-accordion-light              - light accordion header color
// $enerkey-border                       - thin border limiting light areas
// $enerkey-invalid-text                 - text on top of validation error indication
// $enerkey-autofill                     - indicator background for autofilled input value
// $enerkey-panel-text                   - TODO
// $enerkey-input-focus-border-color     - TODO
// $enerkey-input-focus-background-color - TODO
// $enerkey-actions                      - TODO
// $enerkey-comments                     - TODO
// $enerkey-user-menu-bg                 - TODO
//
// markup:
// <div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>
//
// Styleguide 2.2.7
$enerkey-header-general: #5c5c5c !default;
$enerkey-accordion-light: #e2e2e2 !default;
$enerkey-accordion-dark: $enerkey-header-general !default;
$enerkey-border: #d8d8d8 !default;
$enerkey-invalid-text: #000000 !default;
$enerkey-autofill: #fafbc8 !default;
$enerkey-panel-text: #3b4977 !default;
$enerkey-input-focus-border-color: #999999 !default;
$enerkey-input-focus-background-color: #fafafa !default;
$enerkey-actions: $enerkey-header-general !default;
$enerkey-comments: $enerkey-header-general !default;
$enerkey-user-menu-bg: #f1f2f6 !default;
$enerkey-pdf: $enerkey-red;
$enerkey-excel: $enerkey-green;
$enerkey-xml: $enerkey-blue;

// Trends (kulutusmuutokset) colors
//
// $enerkey-positive-change - Indication of a positive change in value
// $enerkey-negative-change - Indication of a positive change in value
//
// markup:
// <div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>
//
// Styleguide 2.2.8
$enerkey-positive-change: #5FA845 !default;
$enerkey-negative-change: #FF4000 !default;

$enerkey-tab-highlight: $enerkey-primary !default;
$enerkey-topbar-highlight: $enerkey-tab-highlight !default;

// Quantity type colors
//
// Quantity type colors are used for indicating different quantity types in the portal.
//
// Styleguide 2.2.9

// Primary colors
//
// markup:
// <div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>
//
// Styleguide 2.2.9.1

// Energy types
$enerkey-electricity: #354ba0 !default;
$enerkey-districtheating: #f05f9a !default;
$enerkey-steam: #89659e !default;
$enerkey-oil: #3f3535 !default;
$enerkey-naturalgas: #f7b33c !default;
$enerkey-districtcooling: #8dc3d2 !default;
$enerkey-liquefiedpetroleumgas: #e87014 !default;
$enerkey-ownelectricity: #4763cb !default;
$enerkey-heatingownproduction: #da3576 !default;
$enerkey-coolingownproduction: #3fa2c5 !default;
$enerkey-biogas: #8daf6b !default;
$enerkey-pellet: #c3ae8a !default;
$enerkey-woodchips: #5f4a31 !default;
$enerkey-heavyoil: #5b2200 !default;
$enerkey-reactivepower: $enerkey-header-general !default;
$enerkey-reactivepowerplus: #e59595 !default;
$enerkey-reactivepowerminus: #6591fc !default;
$enerkey-cop: #cf5b5c !default;
$enerkey-scop: #9d4e68 !default;
$enerkey-eer: #5f4a31 !default;
$enerkey-seer: #5c5050 !default;

// Energy sum types
$enerkey-heatingpurchased: #de5158 !default;
$enerkey-totalenergypurchased: #38b13d !default;
$enerkey-heatingenergy: #de5158 !default;
$enerkey-totalelectricity: $enerkey-electricity !default;
$enerkey-totalcooling: #97c1d0 !default;
$enerkey-totalenergy: #38b13d !default;

// Water types
$enerkey-water: #41c9f1 !default;
$enerkey-wastewater: #00aead !default;
$enerkey-iceagewater: #00cef4 !default;
$enerkey-hotwater: #a74768 !default;
$enerkey-coolingwater: #828bc7 !default;

// Usage types
$enerkey-ventilation: #6591fc !default;
$enerkey-refrigeration: #8cc5d9 !default;
$enerkey-pue: #5cae4e !default;
$enerkey-lighting: #eeb555 !default;
$enerkey-heatingelectricity: #657bcd !default;
$enerkey-heatpumps: #9d4e68 !default;
$enerkey-facilityelectricity: $enerkey-electricity !default;
$enerkey-coolingsystem: #97c1d0 !default;
$enerkey-condensationheat: #cf5b5c !default;
$enerkey-chargingsystem: #3e3636 !default;
$enerkey-percentage: #e06998 !default;
$enerkey-ratio: #e06998 !default;
$enerkey-custom: #e06998 !default;
$enerkey-percentage2: #5c3c8b !default;
$enerkey-ratio2: #5c3c8b !default;
$enerkey-custom2: #5c3c8b !default;

// Indoor air quality types
$enerkey-indoorairquality: #6591fc !default;
$enerkey-indoorpermanence: #44aaab !default;
$enerkey-temperaturepermanence: #fc925e !default;
$enerkey-co2permanence: #aabddb !default;
$enerkey-humiditypermanence: #4d4186 !default;
$enerkey-particulatespermanence: #e79bc5 !default;
$enerkey-pressurepermanence: #3e3636 !default;
$enerkey-vocpermanence: #9d4e68 !default;
$enerkey-indoortemperature: #fc925e !default;
$enerkey-indoorco2: #aabddb !default;
$enerkey-indoorhumidity: #4d4186 !default;
$enerkey-indoorparticulates: #e79bc5 !default;
$enerkey-indoorpressure: #3e3636 !default;
$enerkey-indoorvoc: #9d4e68 !default;

// Transport fuel types
$enerkey-dieseltransport: #5f4a31 !default;
$enerkey-biodieseltransport: #8daf6b !default;
$enerkey-petroltransport: #44aaab !default;
$enerkey-lngtransport: #95a4c1 !default;
$enerkey-biogastransport: #8daf6b !default;
$enerkey-naturalgastransport: #fc925e !default;
$enerkey-electricitytransport: $enerkey-electricity !default;
$enerkey-fueloiltransport: #3e3636 !default;
$enerkey-ethanole85transport: #44aaab !default;
$enerkey-kmtransport: #84689a !default;

// Transport fuel sum types
$enerkey-transport: #38b13d !default;

// Waste types
$enerkey-metalwaste: #3e3636 !default;
$enerkey-plasticwaste: #e59595 !default;
$enerkey-glasswaste: #8cc5d9 !default;
$enerkey-mixednorecyclingwaste: #c3ae8a !default;
$enerkey-biowaste: #8daf6b !default;
$enerkey-paperwaste: #67c7ed !default;
$enerkey-moldedfiberwaste: #5cae4e !default;
$enerkey-electricalandelectronicwaste: $enerkey-electricity !default;
$enerkey-woodwaste: #5f4a31 !default;
$enerkey-otherwaste: #b0ad9e !default;
$enerkey-hazardouswaste: #eeb555 !default;

// Waste sum types
$enerkey-wasterecycle: #44aaab !default;
$enerkey-totalwaste: #cf5b5c !default;

// Other types
$enerkey-compressedair: #5c3c8b !default;
$enerkey-carbondioxide: #95a5c0 !default;
$enerkey-oxygen: #aabddb !default;
$enerkey-nitrogen: #e79bc5 !default;
$enerkey-dryice: #8cc5d9 !default;
$enerkey-naturalgasother: #fc925e !default;
$enerkey-personsin: #6591fc !default;
$enerkey-personsout: #e59595 !default;
$enerkey-personsoccupancy: #44aaab !default;

// Secondary colors
//
// Secondary colors are used in graphs when more than one visualisation of the same energy type is needed
//
// markup:
// <div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>
//
// Styleguide 2.2.9.2

// Energy types
$enerkey-electricity-secondary: #1a2552 !default;
$enerkey-districtheating-secondary: #ac2a64 !default;
$enerkey-steam-secondary: #4f3d5d !default;
$enerkey-oil-secondary: #000000 !default;
$enerkey-naturalgas-secondary: #986e21 !default;
$enerkey-districtcooling-secondary: #557d87 !default;
$enerkey-liquefiedpetroleumgas-secondary: #9c4a0c !default;
$enerkey-electricityownproduction-secondary: #233a8d !default;
$enerkey-heatingownproduction-secondary: #89214a !default;
$enerkey-coolingownproduction-secondary: #23657c !default;
$enerkey-biogas-secondary: #596f42 !default;
$enerkey-pellet-secondary: #89795e !default;
$enerkey-woodchips-secondary: #312619 !default;
$enerkey-heavyoil-secondary: #241207 !default;
$enerkey-reactivepowerplus-secondary: #3b5594 !default;
$enerkey-reactivepowerminus-secondary: #ad6767 !default;
$enerkey-cop-secondary: #5c2829 !default;
$enerkey-scop-secondary: #4f2734 !default;
$enerkey-eer-secondary: #312619 !default;
$enerkey-seer-secondary: #2f2828 !default;

// Energy sum types
$enerkey-heatingpurchased-secondary: #5c2829 !default;
$enerkey-totalenergypurchased-secondary: #206121 !default;
$enerkey-heatingenergy-secondary: #5c2829 !default;
$enerkey-totalelectricity-secondary: #1a2552 !default;
$enerkey-totalcooling-secondary: #557d87 !default;
$enerkey-totalenergy-secondary: #206121 !default;

// Water types
$enerkey-water-secondary: #25809a !default;
$enerkey-wastewater-secondary: #006565 !default;
$enerkey-iceagewater-secondary: #25809a !default;
$enerkey-hotwater-secondary: #703045 !default;
$enerkey-coolingwater-secondary: #4e5277 !default;

// Usage types
$enerkey-ventilation-secondary: #3b5594 !default;
$enerkey-refrigeration-secondary: #638d9c !default;
$enerkey-pue-secondary: #206121 !default;
$enerkey-lighting-secondary: #986e21 !default;
$enerkey-heatingelectricity-secondary: #283876 !default;
$enerkey-heatpumps-secondary: #4f2734 !default;
$enerkey-facilityelectricity-secondary: #1a2552 !default;
$enerkey-coolingsystem-secondary: #557d87 !default;
$enerkey-condensationheat-secondary: #5c2829 !default;
$enerkey-chargingsystem-secondary: #1f1b1b !default;
$enerkey-percentage-secondary: #7b3551 !default;
$enerkey-ratio-secondary: #7b3551 !default;
$enerkey-custom-secondary: #7b3551 !default;
$enerkey-percentage2-secondary: #34214e !default;
$enerkey-ratio2-secondary: #34214e !default;
$enerkey-custom2-secondary: #34214e !default;

// Indoor air quality
$enerkey-indoorairquality-secondary: #3b5594 !default;
$enerkey-indoorpermanence-secondary: #1d5151 !default;
$enerkey-temperaturepermanence-secondary: #b46842 !default;
$enerkey-co2permanence-secondary: #657898 !default;
$enerkey-humiditypermanence-secondary: #271d57 !default;
$enerkey-particulatespermanence-secondary: #9e6886 !default;
$enerkey-pressurepermanence-secondary: #1f1b1b !default;
$enerkey-vocpermanence-secondary: #4f2734 !default;
$enerkey-indoortemperature-secondary: #b46842 !default;
$enerkey-indoorco2-secondary: #657898 !default;
$enerkey-indoorhumidity-secondary: #271d57 !default;
$enerkey-indoorparticulates-secondary: #9e6886 !default;
$enerkey-indoorpressure-secondary: #1f1b1b !default;
$enerkey-indoorvoc-secondary: #4f2734 !default;

// Transport fuel types
$enerkey-dieseltransport-secondary: #312619 !default;
$enerkey-biodieseltransport-secondary: #596f42 !default;
$enerkey-petroltransport-secondary: #1d5151 !default;
$enerkey-lngtransport-secondary: #495770 !default;
$enerkey-biogastransport-secondary: #596f42 !default;
$enerkey-naturalgastransport-secondary: #b46842 !default;
$enerkey-electricitytransport-secondary: #1a2552 !default;
$enerkey-fueloiltransport-secondary: #1f1b1b !default;
$enerkey-distancetransport-secondary: #84689a !default;

// Transport fuel sum types
$enerkey-totaltransport-secondary: #206121 !default;

// Waste types
$enerkey-metal-secondary: #1f1b1b !default;
$enerkey-plastic-secondary: #ad6767 !default;
$enerkey-glass-secondary: #638d9c !default;
$enerkey-mixedwaste-secondary: #89795e !default;
$enerkey-biowaste-secondary: #596f42 !default;
$enerkey-paper-secondary: #537581 !default;
$enerkey-moldedfiber-secondary: #206121 !default;
$enerkey-electricalwaste-secondary: #1a2552 !default;
$enerkey-woodwaste-secondary: #312619 !default;
$enerkey-wasteother-secondary: #56544a !default;
$enerkey-hazardouswaste-secondary: #986e21 !default;

// Waste sum types
$enerkey-wasterecycle-secondary: #1d5151 !default;
$enerkey-totalwaste-secondary: #5c2829 !default;

// Other types
$enerkey-compressedair-secondary: #34214e !default;
$enerkey-carbondioxide-secondary: #626e81 !default;
$enerkey-oxygen-secondary: #657898 !default;
$enerkey-nitrogen-secondary: #9e6886 !default;
$enerkey-dryice-secondary: #638d9c !default;
$enerkey-naturalgasother-secondary: #b46842 !default;
$enerkey-personsin-secondary: #3b5594 !default;
$enerkey-personsout-secondary: #ad6767 !default;
$enerkey-personsoccupancy-secondary: #1d5151 !default;

// Energy type colors in a map
$enerkey-quantity-colors: (
  0: $enerkey-header-general, // $enerkey-undefined,
  1: $enerkey-electricity,
  2: $enerkey-naturalgas,
  3: $enerkey-districtheating,
  4: $enerkey-water,
  5: $enerkey-oil,
  6: $enerkey-hotwater,
  7: $enerkey-iceagewater,
  8: $enerkey-wastewater,
  9: $enerkey-coolingwater,
  10: $enerkey-compressedair,
  11: $enerkey-districtcooling,
  12: $enerkey-reactivepower,
  13: $enerkey-districtheating, // $enerkey-districtheatingwater
  14: $enerkey-districtheating, // $enerkey-districtheatingoutflowwater
  15: $enerkey-districtheating, // $enerkey-districtheatinginflowwater
  16: $enerkey-districtheating, // $enerkey-districtcoolingwater
  17: $enerkey-steam,
  18: $enerkey-liquefiedpetroleumgas,
  19: $enerkey-heatingownproduction,
  20: $enerkey-coolingownproduction,
  21: $enerkey-naturalgasother,
  22: $enerkey-biogas,
  23: $enerkey-pellet,
  24: $enerkey-woodchips,
  25: $enerkey-heavyoil,
  26: $enerkey-carbondioxide,
  27: $enerkey-oxygen,
  28: $enerkey-nitrogen,
  29: $enerkey-dryice,
  30: $enerkey-reactivepowerplus,
  31: $enerkey-reactivepowerminus,
  32: $enerkey-indoorairquality,
  33: $enerkey-indoorpermanence,
  34: $enerkey-temperaturepermanence,
  35: $enerkey-co2permanence,
  36: $enerkey-humiditypermanence,
  37: $enerkey-particulatespermanence,
  38: $enerkey-pressurepermanence,
  39: $enerkey-vocpermanence,
  40: $enerkey-indoortemperature,
  41: $enerkey-indoorco2,
  42: $enerkey-indoorhumidity,
  43: $enerkey-indoorparticulates,
  44: $enerkey-indoorpressure,
  45: $enerkey-indoorvoc,
  46: $enerkey-facilityelectricity,
  47: $enerkey-lighting,
  48: $enerkey-refrigeration,
  49: $enerkey-condensationheat,
  50: $enerkey-heatingelectricity,
  51: $enerkey-ventilation,
  52: $enerkey-coolingsystem,
  53: $enerkey-chargingsystem,
  54: $enerkey-heatpumps,
  55: $enerkey-pue,
  56: $enerkey-percentage,
  57: $enerkey-ratio,
  58: $enerkey-custom,
  59: $enerkey-percentage2,
  60: $enerkey-ratio2,
  61: $enerkey-custom2,
  62: $enerkey-dieseltransport,
  63: $enerkey-petroltransport,
  64: $enerkey-lngtransport,
  65: $enerkey-biogastransport,
  66: $enerkey-naturalgastransport,
  67: $enerkey-electricitytransport,
  68: $enerkey-fueloiltransport,
  69: $enerkey-ethanole85transport,
  70: $enerkey-biodieseltransport,
  71: $enerkey-kmtransport,
  72: $enerkey-metalwaste,
  73: $enerkey-plasticwaste,
  74: $enerkey-glasswaste,
  75: $enerkey-mixednorecyclingwaste,
  76: $enerkey-biowaste,
  77: $enerkey-paperwaste,
  78: $enerkey-moldedfiberwaste,
  79: $enerkey-electricalandelectronicwaste,
  80: $enerkey-woodwaste,
  81: $enerkey-otherwaste,
  82: $enerkey-hazardouswaste,
  83: $enerkey-ownelectricity,
  84: $enerkey-cop,
  85: $enerkey-eer,
  86: $enerkey-scop,
  87: $enerkey-seer,
  88: $enerkey-personsin, // $enerkey-personsinavg
  89: $enerkey-personsout, // $enerkey-personsoutavg
  90: $enerkey-personsoccupancy, // $enerkey-personsoccupancyavg
  91: $enerkey-personsin,
  92: $enerkey-personsout,
  93: $enerkey-personsoccupancy,
  94: $enerkey-pellet, // $enerkey-pelletmwh
  95: $enerkey-naturalgas, // $enerkey-naturalgasmwh
  96: $enerkey-oil, // $enerkey-oilmwh
  97: $enerkey-liquefiedpetroleumgas, // $enerkey-liquidgasmwh
  98: $enerkey-naturalgasother, // $enerkey-naturalgasothermwh
  99: $enerkey-biogas, // $enerkey-biogasmwh
  100: $enerkey-woodchips, // $enerkey-woodchipsmwh
  101: $enerkey-heavyoil, // $enerkey-heavyfueloilmwh
  1000: $enerkey-heatingenergy,
  1001: $enerkey-totalenergy,
  1002: $enerkey-transport,
  1003: $enerkey-wasterecycle,
  1004: $enerkey-totalwaste,
  1005: $enerkey-heatingpurchased,
  1006: $enerkey-totalenergypurchased,
  1007: $enerkey-totalelectricity,
  1008: $enerkey-totalcooling,
  1009: $enerkey-totalenergy, // $enerkey-totalownenergy
);

// Ines savings highlight colors (EG brown shades)
$ines-insights-color: #3c3c3b;
$ines-money-color: #85796a;
$ines-consumption-color: #a89c96;
$ines-co2-color: #e0dddb;

// Ines analytics type colors
$ines-ventilation-color: #9599BD;
$ines-heatbalance-color: #A66A88;
$ines-heatingpower-color: #A69672;
$ines-electricitydr-color: #F2A89B;
$ines-heatingoptimization-color: #FF9865;
$ines-heatingcooling-color: #66CCCC;

/* Card colors */
$mix-color: #ffffff;
$mix-percent: 80%;

/* value colors */
$modelled-value: rgba(0, 173, 239, 1);

/* Sustainability reporting GRI scope colors */
$enerkey-gri-scope-colors: (
  Scope1: #7ca77c,
  Scope2: #a89c96,
  Scope3: #9fbbbe
);

.bg-enerkey-facilityinformation,
.bg-enerkey-default {
  background: $enerkey-header-general;
}

.bg-enerkey-water {
  background: $enerkey-water;
}

.bg-enerkey-districtcooling {
  background: $enerkey-districtcooling;
}

.bg-enerkey-electricity {
  background: $enerkey-electricity;
}

.bg-enerkey-districtheating {
  background: $enerkey-districtheating;
}

.bg-enerkey-hotwater {
  background: $enerkey-hotwater;
}

.bg-enerkey-naturalgas {
  background: $enerkey-naturalgas;
}

.bg-enerkey-wastewater {
  background: $enerkey-wastewater;
}

.bg-enerkey-iceagewater {
  background: $enerkey-iceagewater;
}

.bg-enerkey-compressedair {
  background: $enerkey-compressedair;
}

.bg-enerkey-oil {
  background: $enerkey-oil;
}

.bg-enerkey-coolingwater {
  background: $enerkey-coolingwater;
}

.bg-enerkey-liquefiedpetroleumgas {
  background: $enerkey-liquefiedpetroleumgas;
}

.bg-enerkey-steam {
  background: $enerkey-steam;
}

.bg-enerkey-heatingownproduction {
  background: $enerkey-heatingownproduction;
}

.bg-enerkey-coolingownproduction {
  background: $enerkey-coolingownproduction;
}

.bg-enerkey-naturalgasother {
  background: $enerkey-naturalgasother;
}

.bg-enerkey-biogas {
  background: $enerkey-biogas;
}

.bg-enerkey-pellet {
  background: $enerkey-pellet;
}

.bg-enerkey-woodchips {
  background: $enerkey-woodchips;
}

.bg-enerkey-heavyoil {
  background: $enerkey-heavyoil;
}

.bg-enerkey-carbondioxide {
  background: $enerkey-carbondioxide;
}

.bg-enerkey-nitrogen {
  background: $enerkey-nitrogen;
}

.bg-enerkey-oxygen {
  background: $enerkey-oxygen;
}

.bg-enerkey-dryice {
  background: $enerkey-dryice;
}

.bg-enerkey-reactivepowerplus {
  background: $enerkey-reactivepowerplus;
}

.bg-enerkey-reactivepowerminus {
  background: $enerkey-reactivepowerminus;
}

.bg-enerkey-heatingenergy {
  background: $enerkey-heatingenergy;
}

.bg-enerkey-totalenergy {
  background: $enerkey-totalenergy;
}

.bg-enerkey-reactivepower {
  background: $enerkey-header-general;
}

.bg-enerkey-indoorairquality {
  background: $enerkey-indoorairquality;
}

.bg-enerkey-indoorpermanence {
  background: $enerkey-indoorpermanence;
}

.bg-enerkey-temperaturepermanence {
  background: $enerkey-temperaturepermanence;
}

.bg-enerkey-co2permanence {
  background: $enerkey-co2permanence;
}

.bg-enerkey-humiditypermanence {
  background: $enerkey-humiditypermanence;
}

.bg-enerkey-particulatespermanence {
  background: $enerkey-particulatespermanence;
}

.bg-enerkey-pressurepermanence {
  background: $enerkey-pressurepermanence;
}

.bg-enerkey-vocpermanence {
  background: $enerkey-vocpermanence;
}

.bg-enerkey-indoortemperature {
  background: $enerkey-indoortemperature;
}

.bg-enerkey-indoorco2 {
  background: $enerkey-indoorco2;
}

.bg-enerkey-indoorhumidity {
  background: $enerkey-indoorhumidity;
}

.bg-enerkey-indoorparticulates {
  background: $enerkey-indoorparticulates;
}

.bg-enerkey-indoorpressure {
  background: $enerkey-indoorpressure;
}

.bg-enerkey-indoorvoc {
  background: $enerkey-indoorvoc;
}

.bg-enerkey-facilityelectricity {
  background: $enerkey-facilityelectricity;
}

.bg-enerkey-lighting {
  background: $enerkey-lighting;
}

.bg-enerkey-refrigeration {
  background: $enerkey-refrigeration;
}

.bg-enerkey-condensationheat {
  background: $enerkey-condensationheat;
}

.bg-enerkey-heatingelectricity {
  background: $enerkey-heatingelectricity;
}

.bg-enerkey-ventilation {
  background: $enerkey-ventilation;
}

.bg-enerkey-coolingsystem {
  background: $enerkey-coolingsystem;
}

.bg-enerkey-chargingsystem {
  background: $enerkey-chargingsystem;
}

.bg-enerkey-heatpumps {
  background: $enerkey-heatpumps;
}

.bg-enerkey-pue {
  background: $enerkey-pue;
}

.bg-enerkey-percentage {
  background: $enerkey-percentage;
}

.bg-enerkey-ratio {
  background: $enerkey-ratio;
}

.bg-enerkey-custom {
  background: $enerkey-custom;
}

.bg-enerkey-percentage2 {
  background: $enerkey-percentage2;
}

.bg-enerkey-ratio2 {
  background: $enerkey-ratio2;
}

.bg-enerkey-custom2 {
  background: $enerkey-custom2;
}

.bg-enerkey-dieseltransport {
  background: $enerkey-dieseltransport;
}

.bg-enerkey-petroltransport {
  background: $enerkey-petroltransport;
}

.bg-enerkey-lngtransport {
  background: $enerkey-lngtransport;
}

.bg-enerkey-biogastransport {
  background: $enerkey-biogastransport;
}

.bg-enerkey-naturalgastransport {
  background: $enerkey-naturalgastransport;
}

.bg-enerkey-electricitytransport {
  background: $enerkey-electricitytransport;
}

.bg-enerkey-fueloiltransport {
  background: $enerkey-fueloiltransport;
}

.bg-enerkey-ethanole85transport {
  background: $enerkey-ethanole85transport;
}

.bg-enerkey-biodieseltransport {
  background: $enerkey-biodieseltransport;
}

.bg-enerkey-kmtransport {
  background: $enerkey-kmtransport;
}

.bg-enerkey-metalwaste {
  background: $enerkey-metalwaste;
}

.bg-enerkey-plasticwaste {
  background: $enerkey-plasticwaste;
}

.bg-enerkey-glasswaste {
  background: $enerkey-glasswaste;
}

.bg-enerkey-mixednorecyclingwaste {
  background: $enerkey-mixednorecyclingwaste;
}

.bg-enerkey-biowaste {
  background: $enerkey-biowaste;
}

.bg-enerkey-paperwaste {
  background: $enerkey-paperwaste;
}

.bg-enerkey-moldedfiberwaste {
  background: $enerkey-moldedfiberwaste;
}

.bg-enerkey-electricalandelectronicwaste {
  background: $enerkey-electricalandelectronicwaste;
}

.bg-enerkey-woodwaste {
  background: $enerkey-woodwaste;
}

.bg-enerkey-otherwaste {
  background: $enerkey-otherwaste;
}

.bg-enerkey-hazardouswaste {
  background: $enerkey-hazardouswaste;
}

.bg-enerkey-ownelectricity {
  background: $enerkey-ownelectricity;
}

.bg-enerkey-cop {
  background: $enerkey-cop;
}

.bg-enerkey-eer {
  background: $enerkey-eer;
}

.bg-enerkey-scop {
  background: $enerkey-scop;
}

.bg-enerkey-seer {
  background: $enerkey-seer;
}

.bg-enerkey-personsinavg {
  background: $enerkey-personsin;
}

.bg-enerkey-personsoutavg {
  background: $enerkey-personsout;
}

.bg-enerkey-personsoccupancyavg {
  background: $enerkey-personsoccupancy;
}

.bg-enerkey-personsin {
  background: $enerkey-personsin;
}

.bg-enerkey-personsout {
  background: $enerkey-personsout;
}

.bg-enerkey-personsoccupancy {
  background: $enerkey-personsoccupancy;
}

.bg-enerkey-pelletmwh {
  background: $enerkey-pellet;
}

.bg-enerkey-naturalgasmwh {
  background: $enerkey-naturalgas;
}

.bg-enerkey-oilmwh {
  background: $enerkey-oil;
}

.bg-enerkey-liquidgasmwh {
  background: $enerkey-liquefiedpetroleumgas;
}

.bg-enerkey-naturalgasothermwh {
  background: $enerkey-naturalgasother;
}

.bg-enerkey-biogasmwh {
  background: $enerkey-biogas;
}

.bg-enerkey-woodchipsmwh {
  background: $enerkey-woodchips;
}

.bg-enerkey-heavyfueloilmwh {
  background: $enerkey-heavyoil;
}

.bg-enerkey-transport {
  background: $enerkey-transport;
}

.bg-enerkey-wasterecycle {
  background: $enerkey-wasterecycle;
}

.bg-enerkey-totalwaste {
  background: $enerkey-totalwaste;
}

.bg-enerkey-heatingpurchased {
  background: $enerkey-heatingpurchased;
}

.bg-enerkey-totalenergypurchased {
  background: $enerkey-totalenergy;
}

.bg-enerkey-totalelectricity {
  background: $enerkey-totalelectricity;
}

.bg-enerkey-totalcooling {
  background: $enerkey-totalcooling;
}

.bg-enerkey-totalownenergy {
  background: $enerkey-totalenergy;
}

.bg-enerkey-actions {
  background: $enerkey-actions;
}

.bg-enerkey-comments {
  background: $enerkey-comments;
}

// Element colors
//
// Default                    - Default color for element
// .ek-color--primary         - Primary color for element
// .ek-color--negative        - Negative color for element
// .ek-color--secondary-light - Light secondary color for element
// .ek-color--secondary       - Secondary color for element
// .ek-color--green           - Green color for element
// .ek-color--white           - White color for element
// .ek-color--black           - Black color for element
// .ek-color--light-grey      - Light grey color for element
// .ek-color--pink            - Pink color for element
// .ek-color--grey            - Grey color for element
// .ek-color--red             - red color for element
//
// markup:
// <span class="ek-color {$modifiers}">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
//
// Styleguide 2.2.10
.ek-color {
  &--primary {
    color: $enerkey-primary;
  }

  &--negative {
    color: $enerkey-red;
  }

  &--secondary-light {
    color: $enerkey-secondary-light;
  }

  &--secondary {
    color: $enerkey-secondary;
  }

  &--highlight {
    color: $enerkey-highlight;
  }

  &--green {
    color: $enerkey-green;
  }

  &--white {
    color: $enerkey-white;
  }

  &--black {
    color: $enerkey-black;
  }

  &--light-grey {
    color: $enerkey-light-grey;
  }

  &--pink {
    color: $enerkey-highlight;
  }

  &--grey {
    color: $enerkey-footnote;
  }

  &--red {
    color: $enerkey-red;
  }
}
