manual-qa-consumption-stats {
  table,
  thead,
  thead > tr,
  tbody {
    background: transparent;
  }

  tbody > tr {
    &:nth-of-type(odd) {
      background: $enerkey-white;
    }
  }

  td {
    padding: 0 0.625rem;
  }
}
