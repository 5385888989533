$em-visuals-title-bar-height: 35px;
$em-visuals-chart-width: 400px;

.donut-visuals {
  &__wrapper-box {
    display: flex;
    flex-wrap: wrap;
    padding: $spacing-default 0;
  }

  &__text-button-container {
    float: right;
  }

  &__text-button {
    cursor: pointer;
    color: $enerkey-light-grey;

    &:hover {
      color: $enerkey-white;
    }

    &--selected {
      color: $enerkey-white;
      text-decoration: underline;
    }
  }

  &__wrapper-flex {
    display: flex;
    flex-basis: $em-visuals-chart-width;
    flex-grow: 1;
    max-width: 600px;
    margin-top: $spacing-default;
    padding: 0 $spacing-default;
  }

  &__wrapper-chart {
    flex-basis: 100%;
  }

  &__chart {
    width: $em-visuals-chart-width;
    margin: 0 auto;

    svg {
      // stylelint-disable-next-line declaration-no-important
      overflow: visible !important;
    }
  }

  &__spacer {
    width: 1px;
    background-color: $enerkey-light-grey;
  }
}
