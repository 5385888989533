$grid-section-border: 2px solid $enerkey-secondary;
$grid-tr-hover-bg: #f1f1f1;

.k-grid, .k-treelist {
  input[type="checkbox"] {
    margin: 0;
  }

  // Fix dropdown components in grid toolbar
  .k-toolbar {
    overflow: visible;
  }

  .k-grouping-header > .k-group-indicator > .k-link {
    color: $enerkey-grid-header-text;
  }

  .k-grouping-row {
    td {
      font-weight: normal;
      background: $grid-alt-bg;

      .k-icon {
        color: $enerkey-primary;
      }
    }
  }

  .k-group-cell {
    background: $grid-alt-bg;
  }

  .k-grid-header {
    border-bottom: $grid-section-border;

    .k-header > .k-link {
      color: $enerkey-grid-header-text;
    }
  }

  .k-grid-header-locked {
    border-bottom-width: 1px;
  }

  .k-grid-footer {
    border: 0;
    border-top: $grid-section-border;
    border-bottom: $grid-section-border;
  }

  .k-pager-wrap {
    .k-link > .k-icon {
      color: $base-text;
    }
  }

  table {
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        color: $enerkey-link;

        &:hover {
          color: $enerkey-link-hover;
        }
      }
    }

    tbody {
      tr:hover {
        background-color: $grid-tr-hover-bg;
      }
    }
  }
}

.k-chart-tooltip-wrapper {
  pointer-events: none;
}

.k-chart-shared-tooltip {
  padding: 0;
}

.shared-tooltip {
  color: #000;

  .shared-tooltip-title {
    font-weight: bold;
    padding: 3px;
    background: $enerkey-secondary;
    color: var(--enerkey-header-text);
  }

  .shared-tooltip-table {
    border: none;
    background: transparent;
    margin: 0;

    table {
      margin: 0;
    }

    tr {
      height: 25px;
    }
  }
}

.dashboard .widget-content {
  .k-grid {
    border: none;

    .k-grid-header, .k-grid-footer {
      border: none;
      /* stylelint-disable-next-line */
      padding-right: 0 !important;
    }

    .k-grid-header-wrap, .k-grid-footer-wrap {
      border: none;
    }

    td, th {
      border: none;
      padding: 0.5rem;
    }

    .k-grid-content {
      overflow: auto;
    }
  }
}

.k-input {
  margin: 0;
}

.k-autocomplete {
  padding: 0;

  .k-input {
    line-height: $kendo-input-line-height;
  }
}

.k-animation-container {
  .k-list-container {
    ul > li {
      flex-wrap: wrap;
    }
  }
}

.grid-full-height {
  height: var(--content-full-height);
  min-height: 400px;
  max-height: var(--content-full-height);
}

.k-grid {
  .align-text-right, th.align-text-right, td.align-text-right {
    text-align: right;
  }
}

.k-checkbox, .k-checkbox-wrapper input[type="checkbox"] {
  margin-bottom: 0;
}

// Override foundation css
tfoot {
  color: inherit;
}

// .k-button:disabled, .k-link.k-state-disabled {
//   filter: none;
//   opacity: 0.5;
// }

.time-period-history-popup {
  min-width: 250px;

  .k-group {
    max-height: 50vh;
    overflow-y: auto;

    li {
      &:first-child {
        .k-menu-link {
          @include fill(
            $menu-item-hover-text,
            $menu-item-hover-bg,
            $menu-item-hover-border,
            $menu-item-hover-gradient);
        }
      }

      .k-menu-link {
        padding: 0px;
        border: 1px solid rgba(0, 0, 0, 8%);

        &:focus {
          box-shadow: none;
        }

        .time-period-history-item {
          width: 100%;
          height: 100%;
          padding: $kendo-menu-popup-item-padding-y-md $kendo-menu-popup-item-padding-x-md;
          padding-inline-end: $kendo-menu-popup-item-padding-end-md;
        }
      }
    }
  }
}

.time-period-quick-select-popup {
  .k-menu-link {
    padding: 6px 36px 6px 12px;
  }
}

.datepicker-popup {
  width: min-content;
}

kendo-dialog {
  .k-dialog-titlebar {
    background-color: var(--enerkey-modal-header);
    color: $enerkey-white;
    border-radius: 12px 12px 0 0;
    user-select: none;
  }

  .k-dialog-title, h2 {
    color: var(--enerkey-modal-header-text);
  }

  .k-window {
    border-radius: 13px;
  }

  .k-window-titlebar {
    padding: 12px 16px;
  }

  .k-window-title {
    padding: 0;
    margin: 0;
    line-height: 1;
  }

  .k-window-content {
    margin: 20px 30px 10px;
    text-align: center;
  }

  .k-actions {
    padding: 11px;
  }

  h2 {
    margin: 0;
    padding: 3px 0 0;
  }
}
