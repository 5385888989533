// styleguide:ignore:start
@use "sass:math";

@import "font_variables";

@function calculateRem($size) {
  $remSize: math.div($size, 16px);
  @return $remSize * 1rem;
}

@mixin heading-font {
  font-family: $heading-font;
  font-weight: $heading-font-weight;
}

@mixin heading-thin-font {
  font-family: $heading-thin-font;
  font-weight: $heading-thin-font-weight;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $size, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $size $color;
    -moz-box-shadow:inset $top $left $blur $size $color;
    box-shadow:inset $top $left $blur $size $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $size $color;
    -moz-box-shadow: $top $left $blur $size $color;
    box-shadow: $top $left $blur $size $color;
  }
}

@mixin gradient($from, $to) {
  background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
  background: -moz-linear-gradient(top,  $from, $to);
  background: -ms-linear-gradient(top, $from, $to);
  background: -o-linear-gradient(top, $from, $to);
  background: linear-gradient(top, $from, $to);
  filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
}

@mixin gradient-with-custom-settings($settings...) {
  background: -webkit-linear-gradient($settings);
  background: -moz-linear-gradient($settings);
  background: -ms-linear-gradient($settings);
  background: -o-linear-gradient($settings);
  background: linear-gradient($settings);
}

@mixin gradient-border($fromColor, $toColor, $width) {
  border-width: $width;
  border-style: solid;
  -webkit-border-image:
    -webkit-gradient(linear, 0 100%, 0 0, from($fromColor), to($toColor)) 1 100%;
  -webkit-border-image:
    -webkit-linear-gradient(bottom, $fromColor, $toColor) 1 100%;
  -moz-border-image:
    -moz-linear-gradient(bottom, $fromColor, $toColor) 1 100%;
  -o-border-image:
    -o-linear-gradient(bottom, $fromColor, $toColor) 1 100%;
  border-image:
    linear-gradient(to top, $fromColor, $toColor) 1 100%;
}

@mixin transform($feature) {
  -moz-transform: $feature!important;
  -webkit-transform: $feature!important;
  -o-transform: $feature!important;
  -ms-transform: $feature!important;
  transform: $feature!important;
}

@mixin enerkey-icon($content) {
  @if(unquote($content) == right) {
    $content: $icon-ekey-arrow-right;
  }
  @if(unquote($content) == left) {
    $content: $icon-ekey-arrow-left;
  }
  @if(unquote($content) == up) {
    $content: $icon-ekey-arrow-up;
  }
  @if(unquote($content) == down) {
    $content: $icon-ekey-arrow-down;
  }
  @if(unquote($content) == logo) {
    $content: $icon-ekey-logo;
  }
  @if(unquote($content) == settings) {
    $content: $icon-ekey-options;
  }
  @else {
    $content: $content;
  }
  content: $content;
  font-family: enerkey;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin animation($animations) {
  -webkit-animation: $animations;
  -moz-animation:    $animations;
  -o-animation:      $animations;
  animation:         $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin calc($key, $value) {
  #{$key}: -webkit-calc(#{$value})!important;
  #{$key}: -moz-calc(#{$value})!important;
  #{$key}: calc(#{$value})!important;
}

@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model;
  -moz-box-sizing: $box-model;
  box-sizing: $box-model;
}

@mixin linear-gradient($fromColor, $toColor) {
  background: $toColor;
  background: -moz-linear-gradient(top,  $fromColor 0%, $toColor 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$fromColor), color-stop(100%,$toColor));
  background: -webkit-linear-gradient(top,  $fromColor 0%,$toColor 100%);
  background: -o-linear-gradient(top,  $fromColor 0%,$toColor 100%);
  background: -ms-linear-gradient(top,  $fromColor 0%,$toColor 100%);
  background: linear-gradient(to bottom,  $fromColor 0%,$toColor 100%);
}

@mixin linear-horizontal-gradient($from, $to, $stop: 100%) {
  background-color: $from;
  background-image: -moz-linear-gradient(left, $from 0%, $to $stop);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop($stop, $to));
  background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);
  background-image: -o-linear-gradient(left, $from 0%, $to $stop);
  background: -ms-linear-gradient(left, $from 0%, $to $stop);
  background: linear-gradient(to right, $from 0%, $to $stop);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
}

@mixin transition($transition-property, $transition-time, $method, $delay...) {
  -webkit-transition: $transition-property $transition-time $method $delay;
  -moz-transition:  $transition-property $transition-time $method $delay;
  -ms-transition: $transition-property $transition-time $method $delay;
  -o-transition:  $transition-property $transition-time $method $delay;
  transition: $transition-property $transition-time $method $delay;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  background-clip: padding-box;
}

@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}

@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

@mixin box-shadow($params) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

@mixin nutch($backgroundColor, $leftCorner) {
  &:before {
    background-color: $backgroundColor;
    border-left: 1px solid $enerkey-border;
    border-top: 1px solid $enerkey-border;
    content: '';
    height: rem-calc(9);
    margin-left: rem-calc(-5);
    position: absolute;
    @if $leftCorner {
      left: 5px;
    }
    @else {
      right: rem-calc(220);
    }
    top: rem-calc(-5);
    @include transform(rotate(45deg));
    width: rem-calc(9);
    z-index: 1;
  }
}

@mixin identifier-prefix() {
  &:after {
    font-weight: normal;
    content: " – ";
  }
}


@mixin title-fade($color) {
  background-color: rbga(255,255,255,0);
  background: linear-gradient(to right, rgba($color, 0), rgba($color, 1));
}

@mixin quantity-color($color, $mix-color, $mix-percent){
  background-color: mix($color, $mix-color, $mix-percent);
  .card__title-container.card__title--fade:after {
    @include title-fade(mix($color, $mix-color, $mix-percent));
  }
}

@mixin k-grid--colored-group-headers {
  & .k-grid-header-wrap table tr:first-child th:not([rowspan]):nth-child(even) {
    background: $enerkey-header-general;
    color: white;
  }

  & .k-grid-header-wrap table tr:first-child th:not([rowspan]):nth-child(odd) {
    background: $enerkey-footnote;
    color: white;
  }
}

@mixin flex() {
  display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
}

// Naming convention flex-{align-items-value}
@mixin flex-center() {
  @include flex();
  align-items: center;
}

// Naming convention flex-{align-items-value}-{justify-content-value}
@mixin flex-center-center() {
  @include flex-center();
  justify-content: center;
}
