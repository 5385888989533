// Logo
//
// markup:
// <object
//    class="enerkey-logo"
//    type="image/svg+xml"
//    title="Enerkey"
//    data="/assets/images/EnerKey-logo-white.svg"
// ></object>
//
// sg-wrapper:
// <div style="background-color: #132878; display: inline-block">
//   <sg-wrapper-content/>
// </div>
//
// Styleguide 2.4
.enerkey-logo {
  content: $logo-image;
  height: var(--topbar-height);
}

.logo-secondary {
  content: $logo-secondary-image;
  height: var(--topbar-height);
  margin-right: rem-calc(15);
}
