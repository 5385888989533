$em-visuals-title-bar-height: 35px;
$em-visuals-chart-width: 400px;

%table-cell {
  border-left: 1px solid $enerkey-light-grey;
}

.em-yearly-visuals {
  &__container {
    margin: 0 $spacing-default;
  }

  &__table {
    table-layout: fixed;
  }

  &__title-column {
    width: 250px;
  }

  &__table-cell {
    @extend %table-cell;

    &--bold {
      @extend %table-cell;
      font-weight: bold;
    }
  }

  &__label-color {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 7px;
  }

  &__table-header {
    border-bottom: 2px solid $enerkey-secondary;
  }

  &__table-footer {
    border-top: 2px solid $enerkey-secondary;
    border-bottom: 2px solid $enerkey-secondary;
    font-weight: bold;
  }
}
