.manual-qa-spreadsheet-filter-type-select {
  @include flex-center();

  padding: $spacing-default;

  label {
    @include flex-center();
    padding-right: $spacing-default;
  }

  input {
    margin: 0 $spacing-less 0 0;
  }
}
