.toggle-grid-columns {
  position: absolute;
  right: rem-calc(12);
  top: rem-calc(6);
  height: 50px;
}

.columns-button {
  padding: rem-calc(8) rem-calc(16);
}

.toggle-grid-columns, toggle-grid-columns-button-only {
  .columns-menu {
    font-size: rem-calc(14);
  }
}
