.manual-qa-result {
  .mqa--fill-vertical {
    height: calc(100vh - #{$topbar-height} - 10px);
  }

  .mqa__view-visuals-button {
    margin: -1 * $spacing-default $spacing-default $spacing-default;
    text-align: right;
  }

  .kendo-grid-header-checkbox-text {
    margin-left: $spacing-less;
  }
}
