// TODO: refactor
/* stylelint-disable */
@import '../components/mrc-mass-import/mrc-mass-import';

$border-gray: #E3E5EA;
$xlight-blue: #d9dce3;
$light-blue: #d9dce3;
$body-font-color: $enerkey-black;
$meter-table-bg: #f6f6f8;
$meter-bg: #f6f6f8;
$gray-background: #f2f3f7;

$mrc-default-margin: rem-calc(8px);

.mrc {
  .header-group {
    height: $accordion-height;
  }

  .view-content {
    position: relative;
    min-height: 50px;
  }

  .load-indicator {
    width: 100%;
    height: 100%;
    background: url("/assets/images/ajax-loader.gif") no-repeat center;
    position: absolute;
    left: 0;
    color: transparent;
    min-height: 50px;
  }

  .input-group {
    width: auto;
    margin-bottom: 0;
  }

  .tabs-panel {
    padding: 0;
  }

  accordion-heading.accordion-title {
    display:none;
  }

    .facility-content-readings, .modal-body {
      .property-information-list {
        overflow: hidden;
        width: 100%;
        margin: 0 0 20px;
        border: 1px solid $border-gray;

        .property-information-item {
          overflow: hidden;
          text-overflow: ellipsis;
          float: left;
          width: 25%;
          padding: 10px;
          margin: 0 0 -1px;
          font-size: .85rem;
          line-height: 1rem;
          border-right: 1px solid $border-gray;
          border-bottom: 1px solid $border-gray;
          list-style-type:none;

          @include breakpoint(medium up) {
            &:nth-child(1) {
              border-top: none;
              border-right: 1px solid $border-gray;
              width:25%;
            }
            &:nth-child(2) {
              width:75%;
              border-right: none;
              border-top: none;
              border-left: 1px solid $border-gray;
              margin-left:-1px;
            }
            &:nth-child(3) {
              clear:both;
            }
            &:nth-child(1n+3) {
              width:25%;
              max-width:25%;
            }
            &:last-child {
              border-right: none;
              border-bottom: none;
            }
          }
          @include breakpoint(small only) {
            width: 100%;
            &:nth-child(3n+3) {
              border-right: 1px solid $border-gray;
            }
            &:nth-child(4n+4) {
              border-right: 1px solid $border-gray;
            }
          }
          .property-information-label {
            font-weight: 600;
            display:block;
            margin-bottom: 0.3rem;
          }

          &.meter-properties {

            .property-information-label {
              font-size: 0.9rem;
            }

            .property-information-value {
              font-size: 0.9rem;
              display: block;
              padding: 0.5rem 0;
              word-wrap: break-word;
            }

            .property-information-input {
              padding: 0.5rem 0;
              font-size: 0.9rem;

              .value-container {
                display: inline-block;
                width: 33%;
              }

              .input-container {
                display: inline-block;
                width: 33%;

                input[type="number"] {
                  margin: 0;
                  padding: 0.4rem;
                  height: 2rem;
                  font-size: 0.85rem;
                }
              }

              .button-container {
                display: inline-block;
              }
            }
          }
        }
      }
    }

  table.property-information-table {
    width: 100%;
    margin: 0 0 20px;
    border: 1px solid $border-gray;
    tr {
      background-color: #fff;
    }
    td {
      padding: 10px;
      font-size: 0.9rem;
      vertical-align: top;
    }
    tr:nth-child(1) {
      border-bottom: 1px solid $border-gray;
      td:nth-child(1) {
        width:25%;
        border-right: 1px solid $border-gray;
      }
      td:nth-child(2) {
        width:25%;
        border-right: 1px solid $border-gray;
      }
      td:nth-child(3) {
        width:50%;
      }
    }
    tr:nth-child(2) {
      td {
        width:25%;
        max-width:25%;
        border-right: 1px solid $border-gray;
      }
      td:last-child {
        border-right: none;
      }
    }
    .property-information-label {
      font-weight: 600;
      display:block;
    }
    .property-information-value {
      font-size: 0.9rem;
      display: block;
      padding: 0.5rem 0;
      word-wrap: break-word;
    }
  }

  accordion {
    li > a {
      padding:0;
    }
    .content-row {
      padding:1rem;
      display:block;
      line-height: 1.6;
      font-size: 1rem;
    }

    li.is-active>a {
      background-color: $enerkey-primary;
      color: $enerkey-header-text !important;
      border-bottom: none !important;
    }

    div.content.active {
      padding: 0;

      .accordion-content {
        border: 2px solid $enerkey-primary;
        border-top: none;
      }
    }

    .single-facility-content {
      padding-top: 1rem;
      border: 2px solid $enerkey-header-general;
      padding-left:1px;
      padding-right:1px;

      .switch {
        float: left;
        width: auto;
        margin-bottom: 0;
      }
      .switch-label {
        float: left;
        width: auto;
        padding: 5px 8px;
        font-size: .9rem;
      }

      .switch-paddle {
        border-radius: 0;
      }

      .switch-paddle:after {
        border-radius: 0;
      }

      .property-information-header {
        margin: 0;
        text-transform: uppercase;
        font-size: 0.8rem;
        overflow: hidden;
        width: 100%;
        clear: both;
        cursor: pointer;
      }

      .property-information-header.no-info {
        margin-bottom: 10px;
      }

      .metering-type-wrapper {
        background-color: rgb(250,250,250);
      }

      .metering-type-list {
        .accordion {
          background: #fefefe;
          border: 1px solid $light-blue;
          border-bottom: 0;
          border-radius: 0;
          margin-left: 0;
        }

        .metering-type {
          border-color: #fff;

          a.active {
            background: $enerkey-primary;
            color: $enerkey-white;
            border-bottom: none;
          }

          a {
            background: #fff;
            color: $body-font-color;
            font-size: .9rem;
            font-weight: 600;
            border-bottom: 1px solid $xlight-blue;
          }

          .accordion-content {
            padding: 1rem;
            background: $gray-background;
          }

        }

      }

      .meter-info-container {
        padding-top: 0.975rem;
      }

    }

    .data-loader, .no-data {
      margin-top:3rem;
      margin-left:1.8rem;
    }

    span.name {
      max-width: 80%;
      word-wrap: break-word;
    }

    .consumptionTypeName {
      margin-left: 3px;
    }

    .facility-content-consumptions {

      padding-top: 0px;
      padding-bottom: 0px;

      .single-facility-content {
        border: 2px solid $enerkey-primary;
      }

      .tabs-content.vertical {
        width:75%;
        background-color: #fff;
        display: inline-block;
        border: none;
      }

      .meter-info-container {
        padding:0;
      }

      div.meter-content ul.tabs.vertical {
        margin:0;
      }

      .common-actions-wrapper {
        button {
          margin: 10px;
          margin-right: 0px;
          margin-left: 0px;
        }
      }

      .no-padding {
        padding-left:0 !important;
        padding-right:0 !important;
      }

      .month-row {
        @include flex-center-center();
        padding-left:0;
        padding-right:0;
        min-height: $accordion-height;
      }

      .hidden {
        margin:0;
        padding:0;
      }
      .hidden > .container > .meter-row {
        display:none;
      }

      ul.tabs.vertical {
        border-left: none;
        border-right:1px solid #d8d8d8;
        font-size: 0.9rem;
      }

      li > a {
        color:#000;
        padding:0;
      }
      .meter-name {
        border-bottom:1px solid $enerkey-input-border !important;
      }
      .tabbable li > a{
        @include flex-center();
        min-height: $accordion-height;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
      }

      .common-save-wrapper {
        margin-top: 0px !important;
      }

      .header-row {
        @include flex-center-center();
        width:100%;
        background-color: $enerkey-white;
        color:#37467d;
        min-height: $accordion-height;
      }
      .delim {
        @include flex-center();
        border-bottom:2px solid #1797ce;
        min-height: $accordion-height;
      }
      .quantity-row {
        color: $enerkey-white;
        width:100%;
        clear:both;
        i {
          font-size: 1.2rem;
          margin-top: 0.1rem;
          margin-right: 0.5rem;
        }
      }
      .meter-row {
        background-color: #fff;
        width: 100%;
        float: left;
        border-bottom: none !important;
        min-height: $accordion-height;
        .accordion-title::before {
          display:none;
        }
        .accordion-title {
          background-color: $enerkey-accordion-light;
        }
      }
      .meter-row-dark > .container > .meter-row ul li {
        background-color: #eceff3;
      }
      .meter-name {
        @include flex-center();
        padding: 0.8rem 1.5rem;
        min-height: $accordion-height;
        border-left: 1px solid $enerkey-input-border;
        color: #222;
        background-color: #fff;
        font-weight: 600;
        font-size: .9rem;
        text-overflow: ellipsis;
        overflow: hidden;
        span {
          float: left;
        }
      }
      .meter-name.interval-input-mode {
        padding-top: 14px;
        padding-bottom: 16px;
      }
      .empty-block {
        display:inline-block;
        width:100%;
      }
      .month-block {
        @include flex-center-center();
        border-left:1px solid #d8d8d8;
        text-align:center;
        color:#37467d;
        min-height: $accordion-height;
      }
      .value-cell {
        @include flex-center-center();
        border: 2px solid $enerkey-input-border;
        background-color: $enerkey-white;
        padding:0;
        text-align: center;
        color:#37467d;
        min-height: $accordion-height;
        font-size: 1rem;
        font-weight:600;
        overflow:hidden;
        white-space: nowrap;
      }
      .value-cell.edited {
        color:$enerkey-primary;
      }
      .value-cell:focus {
        border:none;
      }
      .selected {
        background-color:$enerkey-primary;
        color:#fff;
      }
      .unsaved {
        color:$enerkey-primary;
      }
      .selected.edited {
        color:#fff;
      }
      .incomplete {
        color:#ee488a;
      }
      .input-cell-overlay {
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        min-height: $accordion-height;
        .fa-exclamation-triangle {
          position:absolute;
          top:2px;
          right:2px;
          color:red;
          font-size: 1rem;
        }
        .fa-lock {
          position:absolute;
          top:2px;
          left:2px;
          color: $enerkey-black;
          font-size: 1rem;
        }
        .has-tip {
          border-bottom:none;
        }
      }
      .input-cell-overlay:focus {
        border:none;
        outline: none;
      }
      .sum-cell {
        @include flex-center-center();
        border-left:1px solid #d8d8d8;
        border-bottom:1px solid #d8d8d8;
        border-right: 1px solid $enerkey-input-border;
        white-space: nowrap;
        font-size: 1rem;
        font-weight: 600;
        color: $enerkey-black;
        min-height: $accordion-height;
        .input-cell-overlay {
          @include flex-center-center();
          background-color:transparent;
          color:$enerkey-primary;
          .fa-lock {
            position:absolute;
            top: 4px;
            right: 8px;
            left: initial;
            color: $enerkey-black;
            font-size: 1rem;
          }
        }
      }
      .sum-cell.edited {
        color: $enerkey-primary;
      }
      .disabled {
        cursor:default;
        color: $enerkey-black;
      }
      .unit {
        font-size: 0.85rem;
        font-weight: normal;
      }
      .input-field {
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        padding:2px;
        background:transparent;
      }
      .input-field > input {
        font-size: 1rem;
        font-weight:600;
        color:$enerkey-primary;
        margin-left:auto;
        margin-right:auto;
        height:100%;
        border:none;
        text-align:center;
      }
      .input-field > input:disabled {
        background-color: #fefefe;
        cursor: default;
      }
      .input-field > input:focus {
        color: $enerkey-black;
        font-weight:normal;
        text-align:right;
      }
      .tabs-content.vertical {
        padding:0;
        margin:0;
        width: 100%;
      }
      .property-information-list {
        overflow: hidden;
        width: 100%;
        margin: 0;
        border: 1px solid $border-gray;
        border-top:none;
      }
      .property-information-item {
        overflow: hidden;
        text-overflow: ellipsis;
        float: left;
        width: 25%;
        padding: 10px;
        margin: 0 0 -1px;
        font-size: .85rem;
        line-height: 1rem;
        border-bottom: 1px solid $border-gray;
        list-style-type:none;

        @include breakpoint(medium up) {
          &:nth-child(1) {
            border-top: none;
            width:33%;
          }
          &:nth-child(2) {
            width:33%;
            border-right: none;
            border-top: none;
            border-left: 1px solid $border-gray;
          }
          &:nth-child(3) {
            width:34%;
            border-right: none;
            border-top: none;
            border-left: 1px solid $border-gray;
          }
          &:nth-child(4) {
            clear:both;
            width:33%;
          }
          &:nth-child(5) {
            width:33%;
            border-left: 1px solid $border-gray;
          }
          &:last-child {
            border-right: none;
            border-bottom: none;
            border-left: 1px solid $border-gray;
            width:34%;
          }
        }
        .property-information-label {
          font-weight: 600;
          display:block;
        }
        &.meter-properties {
          .property-information-label {
            font-size: 0.9rem;
          }
          .property-information-value {
            font-size: 0.9rem;
            display: block;
            padding: 0.5rem 0;
            word-wrap: break-word;
          }
        }
      }
    }

    .metering-target {
      background: #EFEFEF;
      color: $enerkey-black;
      border-bottom: 1px solid $enerkey-white;
      a.accordion-title {
        color: #222222;
        display: block;
        position: relative;
        border: none;
        border-bottom: 1px solid #d8d8d8;
        font-size: 0.9rem;
      }
      .accordion-content {
        padding: 0;
        border:none;
      }
      li.is-active > a {
        color: #fff;
      }
      .tabs-title.is-active {
        background-color: $enerkey-primary;
        color: #fff;
        margin-left: -1px;
      }
    }
    .metering-target.is-active {
      background: transparent;
    }

    .toggle-target {
      padding-right: 0;
      text-align: right;
    }
  }

  .no-margin {
    margin:0 !important;
  }

  .meter-wrapper {
    margin-bottom: 0px;
    width: 100%;
    line-height: 1.5;

    .metering-point-list {
      > ul.accordion {
        margin-bottom: 0px;
      }
    }

    .sub-meter-wrapper {
      margin-bottom: 0px;
      width: 100%;
      line-height: 1.5;
    }

    .tabs-content.vertical {
      margin-bottom: 0px;
      width: 100%;
      height: 100%;
      margin-left: -1px;
      padding-left: 0px;
      display: inline-block;
      border: none;
      border-top: 1px solid $enerkey-input-border;
      border-left: 1px solid $enerkey-input-border;
    }
  }

  .meter-wrapper.hidden {
    border:none;
  }

  .metering-point {
    border-color: #fff;

    .meter-name-holder {
      color:#222222;
      display: flex;
      align-items: center;
      font-weight: 600;
      overflow: hidden;
      font-size: .88rem;
      border: none;
      padding-top:2rem;

      span.meter-name {
        text-overflow: ellipsis;
        line-height: 1.5;
      }
      span:last-child {
        font:  normal normal 600 14px/1.5 $font-awesome;
      }
    }

    .value-holder {
      position: relative;
      float: left;
      background-color: $meter-table-bg;
      padding: 0;
      border-left: 1px solid $enerkey-input-border;
    }
    .value-holder.opened {
      background-color:#fff;
      padding-top:0.45rem;
    }

    a.active {
      .value-holder {
        div.reading-input-overlay {
          position: absolute;
          z-index: 3;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #fff;
          opacity: 0.7;
          cursor: default;
        }
      }
    }

    div.accordion-content {
      float: left;
      width: 100%;
    }

    table.meter-data-table {
      width: 100%;
      border-collapse: collapse;
      margin: 0;
      padding: 0;
      border: none;
      cursor: default;
      table-layout: fixed;
      line-height: inherit;

      th {
        font-weight: normal;
      }

      input {
        max-width: 300px;
        min-width: 90px;
      }

      tr.add-new-values {
        > td {
          padding: 0.67rem 0.4rem;
          padding-top: 0.4rem;
          padding-bottom: 0.4rem;
          color: #222;
          > div {
            &.previous-data-item-wrapper {
              display: block;
              width: 100%;
              margin-top: 3px;
              .previous-data-item {
                display: block;
                padding: 3px 8px!important;
                margin-bottom: 3px;
                border-radius: 3px;
                width: 156px;
              }
            }

            &.invalid-value {
              .k-datepicker {
                box-shadow: 0 0 8px #f75d16;
              }
            }

            > div {
              &.invalid-value {
                input.reading-value {
                  box-shadow: 0 0 8px #f75d16;
                }
              }
            }
          }
        }
      }
    }

    .meter-content {
      border-bottom: 1px solid $enerkey-input-border;
      border-left: 1px solid $enerkey-input-border;
      border-right: 1px solid $enerkey-input-border;
    }
  }

  .metering-point>a {
    padding: 0;
    background-color: #fff !important;
    color:#222222;
    width: 100%;
    float: left;
  }

  .add-new-values > td > div > .k-datepicker, .reading-date > .k-datepicker {
    width:100%;
    min-width: 8em;
  }

  .k-datepicker {
    .k-picker-wrap.k-state-hover {
      background-color: #ebebeb;

      .k-select {
        background-color: #ebebeb;
      }
    }
  }

  .k-picker-wrap {
    height: 32px;
  }
  .k-picker-wrap.k-state-default{
    border-color: #d9d9d9;
    border-radius: 0px;
    background-clip: padding-box;
    background-color: #fafafa;
  }
  .k-picker-wrap input {
    height: 100%;
    background-color: #fff;
  }
  .k-select {
    line-height: 28px;
    width: 2.5em;
    background-color: #fafafa;
  }

  input.reading-value {
    margin: 0;
  }

  div.meter-content {
    padding: 0;
    border-bottom: 1px solid #d8d8d8;

    ul.tabs.vertical {
      margin-bottom: -1px !important;
      padding-bottom: 0;
      width: 100%;
      border-right: 1px solid $enerkey-input-border;
      li {
        border: none;
        border-bottom: 1px solid $enerkey-input-border;

        &:last-child > a {
          margin-bottom: 0;
        }

        > a {
          color: $enerkey-primary;
          font-size: 1rem;
        }

        &.active {
          border: none;
          margin-right: 0;

          >a {
            background-color: $enerkey-primary;
            color: $enerkey-header-text;
          }
        }
      }
    }
  }

  .facility-content-readings {
    ul.tabs.vertical {
      li {
        >a {
          padding: 1rem 1rem 1rem 2em;
          font-size: 1rem;
        }
      }
      li:first-child {
        border-top: 1px solid $enerkey-input-border;
      }
    }
  }


  .facility-info-group {
    .title {
      background: $enerkey-primary;
      color: #fff;
      padding: 0.7rem;
      margin: 0;
    }
  }

  .meter-input-container {
    float: left;
    margin-right: 2px;
    min-width: 90px;
    max-width: 200px;
    width: 49%;
  }

  .meter-input-container.meter-readings-history {
    margin: 0;
    padding-left: 0.8rem;
    width: auto;
    float:none;
    display: inline-block;
    min-width: initial;
    max-width: initial;
  }

  .meter-previous-data-item {
    display: inline-block;
    margin-right: 5px;
    padding: 3px 8px!important;
    margin-bottom: 3px;
    border-radius: 3px;
    width: 150px;
    text-align: right;
  }
  .previous-readings {
    float: left;
    padding: 3px 8px!important;
    margin-bottom: 3px;
    min-width: 90px;
    max-width: 200px;
    width: 49%;
    text-align: right;
  }
  .previous-data-item-wrapper.single-value-input {
    max-width: 200px;
    width: 49% !important;
    .previous-readings {
      float: right;
    }
  }

  .meter-previous-data-item:first-child {
    border: none;
    padding-left: 0;
  }

  .meter-data-table-headers {
    overflow: hidden;
    width: 100%;
    clear: both;
    display: flex;

    > div {
      .fake-table-cell {
        height: 100%;
        margin: 0;
        border: 0 solid white;
        border-top-width: 2px;

        .header-group--height-100-percent {
          height: 100%;
        }

        .quantity-title {
          text-indent: 10px;
          font-weight: 600;
          background-color:#039dd8;
          color:#fff;
          padding-top: 6px;
          padding-bottom: 6px;
          padding-left: 6px;
          i {
            font-size: 1.2rem;
            margin-top: 0.5rem;
          }
        }
      }

      > .k-datepicker { float: left; }
      // Mobile view changes
      .fake-table-cell.is-mobile {
        border: 0;

        .quantity-title {
          margin: 2px;
        }
      }
    }

    table { width: 100%; margin: 0; border-color: $enerkey-input-border; table-layout: fixed; }
    label { float: left; line-height: 2rem; padding-right: 10px; padding-left: 10px; }

    table.quantity-row-headers {
      border: none;
      th {
        color:#fff;
        padding-top: 12px;
        padding-bottom: 12px;
        border: 2px solid white;
        border-right: 0px;
        border-bottom: 0px;
      }
    }

    // Mobile view changes
    .meter-data-headers.is-mobile {
      border-left: solid 1px #DDDDDD;
    }
  }

  .meter-data-table th:nth-child(1),
  .meter-data-table td:nth-child(1),
  .meter-data-table th.child-cell-1,
  .meter-data-table td.child-cell-1  {
    width: 22%;
  }
  .meter-data-table th:nth-child(2),
  .meter-data-table td:nth-child(2),
  .meter-data-table th.child-cell-2,
  .meter-data-table td.child-cell-2  {
    width: 36.71726%;
  }
  .meter-data-table th:nth-child(3),
  .meter-data-table td:nth-child(3),
  .meter-data-table th.child-cell-3,
  .meter-data-table td.child-cell-3  {
    width: 11%;
    text-align: center;
  }
  .meter-data-table th:nth-child(4),
  .meter-data-table td:nth-child(4),
  .meter-data-table th.child-cell-4,
  .meter-data-table td.child-cell-4 {
    width: 12%;
  }
  .meter-data-table th:nth-child(5),
  .meter-data-table td:nth-child(5),
  .meter-data-table th.child-cell-5,
  .meter-data-table td.child-cell-5  {
    width: 15%;
    text-align: center;
  }
  .meter-data-table th:nth-child(6),
  .meter-data-table td:nth-child(6),
  .meter-data-table th.child-cell-6,
  .meter-data-table td.child-cell-6  {
    width: 6.07210%;
  }
  .meter-data-table tr.second-row td:nth-child(3),
  .meter-data-table tr.second-row td.child-cell-3  {
    width: 12%;
    text-align: left;
  }

  // Mobile view changes
  .meter-data-table.is-mobile th:nth-child(1),
  .meter-data-table.is-mobile td:nth-child(1),
  .meter-data-table.is-mobile th.child-cell-1,
  .meter-data-table.is-mobile td.child-cell-1  {
    width: 30%;
    padding-left:6px;
    div:first-child {
      width:99%;
    }
  }
  .meter-data-table.is-mobile.single-value-container th:nth-child(1),
  .meter-data-table.is-mobile.single-value-container td:nth-child(1),
  .meter-data-table.is-mobile.single-value-container th.child-cell-1,
  .meter-data-table.is-mobile.single-value-container td.child-cell-1  {
    width: 50%;
    padding-left:6px;
  }
  .meter-data-table.is-mobile th:nth-child(2),
  .meter-data-table.is-mobile td:nth-child(2),
  .meter-data-table.is-mobile th.child-cell-2,
  .meter-data-table.is-mobile td.child-cell-2  {
    width: 50%;
    padding-left:6px;
    text-align:left;
  }
  .meter-data-table.is-mobile.single-value-container th:nth-child(2),
  .meter-data-table.is-mobile.single-value-container td:nth-child(2),
  .meter-data-table.is-mobile.single-value-container th.child-cell-2,
  .meter-data-table.is-mobile.single-value-container td.child-cell-2  {
    width: 50%;
    padding-left:6px;
    text-align:left;
  }

  table.meter-data-table tr:nth-of-type(even) {
    background: #fff;
  }

  .common-actions-wrapper {

    button {
      margin-bottom: 0 !important;
      margin-right: 10px;
    }
    button:last-child {
      margin-right: 0px;
    }

    .common-save-wrapper {
      width: auto;
      padding-right: 0;
      padding-left: 0;
    }

    .common-datepicker-wrapper {
      padding: 0;
      max-width: 405px;

      > span {
        line-height: 34px;
        margin-left: 10px;

        &.k-datepicker {
          font-size: 0.875rem;
          margin-left: 6px;
        }
      }
    }
  }
  .common-actions-wrapper:first-of-type {
    margin-bottom: 0px;
  }
  .common-actions-wrapper:last-child {
   .common-save-wrapper {
     margin-top:12px;
     margin-right:10px;
     margin-bottom: 10px;
   }
  }

  .positive-value {
    color: forestgreen;
  }

  .negative-value {
    color: red;
  }

  .large-difference-alert {
    color: red;
    font-weight: 600;
  }

  .meter-readings-container {
    table {
      width: 100%;
      border: none;
      border-bottom: 1px solid $enerkey-input-border;

      tr:nth-of-type(odd) { background: #f9f9f9; }
      tr:nth-of-type(even) { background: #fff; }

      th:nth-child(1),
      td:nth-child(1),
      th.child-cell-1,
      td.child-cell-1  {
        width: 18%;
      }

      th:nth-child(2),
      td:nth-child(2),
      th.child-cell-2,
      td.child-cell-2  {
        width: 40%;
        padding-left: 1.2rem;
      }

      th {
        >button {
          margin-bottom: 0;
          width: 40px;
          padding: 0.75rem;
        }
      }

      tbody {
        border-collapse:collapse;
        td {

          span.k-widget.k-datepicker.k-header {
              width: 120px;
          }

          >button {
            margin-bottom: 0;
            width: 40px;
            padding: 0.75rem;
          }

          >div.input-group {
            >span {
              display: block;
              width: 150px;

              &.input-group-addon {
                font-weight: 600;
              }
            }

            >input {
              margin-bottom: 0;
              max-width: 150px;
            }
          }
        }
      }
    }
  }

  .mrc-pagination-wrapper {
    text-align: center;
    padding: 10px;

    .mrc-pagination-pages {
      margin: auto;

      .page {
        display: inline-block;
        padding: 0.25rem 1rem;
        margin: 2px 5px;
        border: 1px solid $enerkey-input-border;
        border-radius: 3px;
        background-color: #fafafa;
        cursor: pointer;

        &:hover {
          background-color: $gray-background;
        }

        &.selected {
          background-color: $enerkey-primary;
          border: none;
          color: #fff;

          &:hover {
            background-color: $enerkey-primary;
          }
        }
      }
    }
  }

  .wizard-container {
    padding: 10px 10px 0;

    .wizard-steps {
      border-bottom: 1px solid $enerkey-input-border;

      .wizard-step {
        display: inline-block;
        line-height: 48px;
        padding: 0 15px;
        position: relative;
        color: $enerkey-footnote;
        cursor: not-allowed;

        &--completed {
          color: $enerkey-black;
          cursor: pointer;
        }

        &--active {
          background-color: $gray-background;
          border-left: 1px solid $enerkey-input-border;
          border-right: 1px solid $enerkey-input-border;
          border-top: 3px solid $enerkey-primary;
          color: $enerkey-black;
        }
      }
    }

    .wizard-content {
      .meter-item {
        .meter-item-data-table {
          width: 100%;
          border-collapse: collapse;
          border: 1px solid #ebebeb;
          border-top: none;
          position: relative;

          tr:first-child {
            td:nth-child(1), td.child-cell-1 {
              width:30%;
              max-width:30%;
            }
            td:nth-child(2), td.child-cell-2 {
              width:50%;
              max-width:50%;
            }
          }

          tr {
            background: #f9f9f9;
          }

          input {
            max-width: 150px;
            margin-bottom: 0;

            &[type="text"] {
              max-width:100%;
              width:100%;
            }

            &.reading-input {
              display: inline-block;
              margin-right: 5px;
            }

            &[type="checkbox"] {
              position: relative;
              top: 1px;
            }
          }

          .meter-data-label {
            margin-bottom: 5px;
            font-weight: 600;
            font-size: 0.9rem;
          }

          .disabled-input {
            cursor: default;
          }

          .long-content {
            word-wrap: break-word;
          }

          .meter-type-input {
            margin-right: 15px;

            input[type="radio"] {
              position: relative;
              top: 1px;
            }
          }

          button#next {
            margin-bottom: 0;
            position: absolute;
            bottom: 10px;
            right: 10px;
          }
        }
        .meter-item-data-table.meter-type-change {
          tr:first-child {
            td:nth-child(1), td.child-cell-1 {
              width:25%;
              max-width:25%;
            }
            td:nth-child(2), td.child-cell-2 {
              width:40%;
              max-width:40%;
            }
            td:nth-child(3), td.child-cell-3 {
              width:20%;
              max-width:20%;
            }
          }
          .data-loader {
           margin-top:0.75rem;
           margin-left:0rem;
          }
        }
        .meter-item-data-table.meter-type-change.no-reading {
          tr:first-child {
            td:nth-child(1), td.child-cell-1 {
              width:65%;
              max-width:65%;
            }
            td:nth-child(2), td.child-cell-2 {
              width:0%;
            }
          }
        }
      }

      .change-meter-confirmation {
        .confirmation-content {
          background-color: $gray-background;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-bottom: 15px;
          margin-left: 0px;
          border: 1px solid $enerkey-input-border;
          border-top: none;

          .reading-date-element {
            padding-bottom: 10px;

            .meter-data-label {
              display: inline-block;
              font-weight: 600;
              font-size: 1rem;
            }

            .meter-data-content {
              display: inline-block;
              font-size:0.95rem;
            }
          }

          .confirmation-data-list {
            list-style-type: none;
            margin: 0;
            background-color: #fff;
            padding: 10px 15px;
            border: 1px solid #c7c7c7;

            .list-title {
              font-weight: 600;
              font-size: 1.1rem;
              margin-bottom: 1rem;
            }

            .meter-data-label {
              min-width: 120px;
              display: inline-block;
              font-weight: 600;
              font-size: 1rem;
            }

            .meter-data-content {
              display: inline-block;
              font-size:0.95rem;

              .meter-reading {
                display: inline-block;
                margin-right: 5px;
                min-width: 100px;
              }
            }
            .meter-type-change-notification {
              display: inline-block;
              font-weight: 600;
              font-size: 1rem;
              color:#ff0000;
              margin-top: 1.5rem;
            }
          }

          .button-wrapper {
            position: relative;


            button {
              margin-bottom: 0;
              margin-top: 10px;
            }

            .change-error-wrapper {
              position: absolute;
              left: 20%;
              right: 20%;
              width: 60%;
              margin: 0;
              top: 10px;
              text-align: center;
              border: 2px solid $enerkey-warning;
              background-color: #fafafa;
              border-radius: 3px;
              padding: 5px 10px;

              > span {
                color: $enerkey-warning;
              }
            }
          }
        }
      }
    }
  }

  .meter-readings-wrapper {
    .reading-row {

      .reading-row-content {
        &.invalid-values {
          border: 3px solid $enerkey-warning;
        }
      }

      border-bottom: 1px solid $enerkey-input-border;
      font-size: 0.875rem;

      &.even {
        background-color: $gray-background;
      }

      .reading-date {
        display: inline-block;
        width: 18%;
        padding: 0.2rem 0.4rem;
        position: relative;

        .fa-stack.flag-indicator {
          position: absolute;
          right: 5px;
          top: 1px;
        }
      }

      .meter-readings {
        display: inline-block;
        width: 40%;

        input {
          margin-bottom: 0;
          width: 150px;
        }

        span {
          width: 150px;
          padding: 0.5rem 0.3rem;
          display: block;
        }
      }

      .buttons-div {
        display: inline-block;
        width: 36.71726%;
        color: #fff;

        button {
          margin: 2px 0;
          padding: 0;
          width: 38px;
          height: 38px;
        }
      }

      &.in-edit {
        .meter-readings {
          padding: 0.2rem
        }
      }

      .new-reading-row {
        background-color: #fafafa;
        border-top: 1px solid $enerkey-input-border;

        &.invalid-values {
          border: 3px solid $enerkey-warning;
        }

        .reading-date {
          padding-left: 0.4rem;
          width: 18%;
          .k-datepicker {
            text-indent: 0;
            font-size: 0.875rem;
            max-width: 150px;
          }
          .fa-stack.flag-indicator {
            top: 6px;
          }
        }

        .meter-readings {
          input {
            width: 150px;
            margin-left: 6px;
          }
        }
        .meter-input-container {
          float:none;
          display: inline-block;
          margin-right: 2px;
          min-width: 90px;
          max-width: 200px;
          width:auto;
        }
      }

      .history-reading-error {
        padding: 0.5rem 0.75rem;
        font-size: 14px;
        line-height: 18px;

        &> span {
          color: $enerkey-warning;
          font-size: 18px;
        }
      }
    }
    .type-change-indication-row {
      color: #fff;
      background-color: #bababa;
      font-size: 0.875rem;
      font-style: italic;
      .buttons-div {
        button {
          border: 2px solid #fff;
        }
      }
    }
  }

  .meter-history-content {
    .meter-history-table {
      width: 100%;
      border-spacing: 0;
      border: none;
      border-bottom: 1px solid $enerkey-input-border;
      table-layout: fixed;

      td, th {
        word-wrap: break-word;
        button {
          color: #fff;
        }
      }

      tr {

        th:nth-child(1),
        th.child-cell-1 {
          width: 10%;
          max-width: 10%
        }
        th:nth-child(2),
        th.child-cell-2 {
          width: 15%;
          max-width: 15%
        }
        th:nth-child(3),
        th.child-cell-3 {
          width: 25%;
          max-width: 25%
        }
        th:nth-child(4),
        th.child-cell-4 {
          width: 10%;
          max-width: 10%
        }
        th:nth-child(5),
        th.child-cell-5 {
          width: 11%;
          max-width: 11%
        }
        th:nth-child(6),
        th.child-cell-6 {
          width: 9%;
          max-width: 9%
        }
        th:nth-child(7),
        th.child-cell-7 {
          width: 9%;
          max-width: 9%
        }
        th:nth-child(8),
        th.child-cell-8 {
          width: 11%;
          max-width: 11%
        }

        td {
          padding: 0 0.625rem;

          input {
            margin: 0;
            max-width: 75px;
          }

          .meter-previous-data-item {
            max-width: 100px;
            text-align: left;
            padding: 0 !important;

          }
        }
        td.factor-container {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          span {
            padding-left: 1rem;
          }
        }
      }
      tr.current-item {
        td:first-child {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
      .factor-input-container {
        width:100%;
        max-width:100px;
      }
    }
  }

  .invalid-date-input-indicator {
    position: absolute;
    display: inline-block;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #FD8E00;
    padding: 4px;
    bottom: $accordion-height;
    left: 4px;
    i {
      color:#ff0000;
    }
    span {
      margin-left: 2px;
      color:#222;
    }
  }

  &.email-sending-results-modal {
    .modal-body {
      padding-left:0;
      padding-right:0;
      padding-bottom: 0;
    }
    ul {
      list-style-type:none;
      margin:0;
      max-height: 460px;
      min-height: 100px;
      overflow-y: scroll;
      border-bottom: 1px solid #cbcbcb;
    }
    li {
      background-color: #fff;
      padding: 0.2rem;
    }
    i.fa {
      margin-left:0.5rem;
      margin-top: 0.5rem;
      font-size: 1rem;
    }
    .fa-check {
      color:#94ba79;
    }
    .fa-remove {
      color: #f75d16;
    }
    .fa-ban {
      color: #cbcbcb;
    }
  }
  &.email-sending-modal {
    input {
      font-size: 0.9rem !important;
    }
    input[type="email"]:invalid {
      box-shadow: 0 0 8px #f75d16;
    }
    label {
      font-size: 1rem;
    }
    .text-editor {
      min-height:200px;
      max-height:400px;
      /*display: inline-block;*/
    }
    .modal-footer {
      button {
        margin-left: 2px;
        margin-right: 2px;
      }
      button:last-child {
        float:right;
      }
    }
  }
  &.email-settings-modal {
    font-weight: normal !important;
    .text-editor {
      /*display: inline-block;*/
      min-height: 100px;
      max-height: 200px;
      margin-bottom: 20px;
    }
    input {
      font-size: 0.9rem !important;
    }
    label {
      font-size: 1rem;
    }
    .select-container {
      min-width: 100px;
      margin-right: 4px;
      select {
        font-size: 0.97rem !important;
      }
      div {
        display: block;
        label {
          margin-right: 0.5rem;
          font-size: 0.9rem !important;
          cursor: pointer;
          input[type="checkbox"] {
            cursor:pointer;
            margin-top: 0.35rem;
            margin-right: 0.5rem;
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
    .select-container:last-child {
      margin-right: 0px;
      padding-top: 10px;
    }
    .toggle-topic-date {
      label {
        float:right;
        text-align:right;
        cursor:pointer;
        margin-right: 0.5rem;
        font-size: 0.9rem !important;
        input[type="checkbox"] {
          cursor:pointer;
          float:right;
          margin-top: 0.35rem;
          margin-right: 0.1rem;
          margin-left: 0.5rem;
          width: 1rem;
          height: 1rem;
        }
      }
    }
    .modal-footer {
      button {
        margin-left: 2px;
        margin-right: 2px;
      }
      button:last-child {
        float:right;
      }
    }
  }

  .no-manual-meters {
    margin-top:0.6rem;
    margin-bottom:0.6rem;
  }

  .search-result, .search-result:hover {
    background-color: $enerkey-primary;
    color:#fff;
  }
  .search-result-remove {
    transition: all linear 0.5s;
  }

  .interval-input-row {
    @include flex-center();

    height: $accordion-height;
    border-left: 1px solid $enerkey-input-border;

    &__unit {
      width: 30px;
      margin-left: $mrc-default-margin;
    }

    input[type="tel"] {
      width:auto;
      margin-left: $mrc-default-margin;
      margin-bottom: 0;
      max-width: 150px;
      .success {
        box-shadow: 0 0 8px #94ba79;
      }
    }

    span.separator {
      color: #b4b4b4;
      margin-left: 1rem;
      font-size: 2rem;
      font-weight: bold;
    }
    span.separator.success {
      color:#222222;
    }
    .fa-check.success {
      color:#94ba79;
      margin-left: 0.7rem;
    }
    span.date-text {
      color:#222222;
      font-weight:bold;
      width:152px;
      padding-left: 0.6rem;
      text-indent: .33em;
    }

    span.unit {
      padding-top:8px;
      padding-left:8px;
      width: 36px;
    }
    button {
      margin-left: $mrc-default-margin !important;
    }
    div.invalid-value {
      .k-datepicker {
        box-shadow: 0 0 8px #f75d16;
      }
    }
  }

  .interval-input-row.success {
    background-color:#edf6e3
  }

  .data-loader {
    font-size: 0.9rem;
    color:#37467d;
    i.fa-spin {
      color:#0194cd;
      font-size: 1.1rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  .intervals-container {
    padding-left:0px;
    border: 1px solid #d8d8d8;
    border-top: none;
    border-bottom: none;
    .row {
      padding:6px;
    }
    .interval-item {
      margin-left: 0px;
      padding-left:16px;
      div:first-child {
        padding-left:4px;
      }
      span.separator {
        color: #b4b4b4;
        margin-right: 16px;
        margin-top: -7px;
        font-size: 2rem;
        font-weight: bold;
      }
    }
    .date-field {
      padding-top:6px;
    }
    .unit {
      padding-top:8px;
      padding-left:8px;
    }
    input[type="tel"] {
      margin-bottom:0px;
      margin-left:-24px;
      width:auto;
      max-width: 150px;
    }
    button {
      margin-left: $mrc-default-margin !important;
    }
    .even {
      background-color: #f9f9f9;
    }
    .intervals-header-row {
      margin-left:0px;
      background-color:#fff;
      border-bottom: 1px solid #d8d8d8;
      div {
        padding-left:4px;
      }
      i {
        cursor:pointer;
        margin-top: 4px;
        margin-left: 16px;
      }
    }
    .pagination-container {
      padding: 8px;
      padding-bottom: 4px;
      padding-top: 14px;
      border-top: 1px solid #d8d8d8;
      display:inline-block;
      width:100%;
      i {
        color:#b4b4b4;
        margin-right:32px;
        cursor:pointer;
        font-size:1rem;
        margin-top: 0.09rem;
      }
      i.fa-caret-left, i.fa-caret-right {
        font-size: 1.5rem;
        margin-top:-0.12rem;
      }
      span {
        margin-right:32px;
        margin-top:-0.19rem;
        cursor:pointer;
        text-align: center;
      }
      span.current {
        font-weight: 700;
        font-size: 1.2rem;
        margin-top: -0.4rem;
      }
    }
    .year-selector-row {
      padding:4px;
      padding-top:8px;
      border-top: 1px solid #d8d8d8;
      display:inline-block;
      width:100%;
      text-align: center;
      div {
        display:inline-block;
      }
      span.year-button {
        font-weight: bold;
        cursor:pointer;
        border: 1px solid $enerkey-input-border;
        background-color: #fafafa;
        border-radius: 3px;
        color:#222222;
        margin: 2px 5px;
        padding: 0.25rem 1rem;
        &:hover {
          background-color: $gray-background;
        }
      }
      span.year-button.selected {
        color:#fff;
        background-color:$enerkey-primary;
        border:none;
        &:hover {
          background-color: $enerkey-primary;
        }
      }
    }
  }

  div.reading-input-overlay {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.7;
    cursor: default;
  }

  .loading-indicator-container {
    margin-top: 1rem;
    font-size: 0.9rem;
    margin-right: 1rem;
    color:#37467d;
    i.fa-spin {
      color:#0194cd;
      font-size: 1.1rem;
      margin-left: 1rem;
    }
  }

  span.fa-check.success {
    color: #94ba79;
  }

  input[type="tel"].success {
    box-shadow: 0 0 8px #94ba79;
  }

  .interval-measurement-switch-container  {
    input[type="checkbox"] {
      margin-top:0.35rem;
      width: 1rem;
      height: 1rem;
    }
    label {
      cursor: pointer;
      margin-top: 0.45rem;
      font-size: 1rem;
      display: inline-block;
    }
  }
  .interval-measurement-header-container {
    padding:6px;
    span.k-datepicker {
      max-width:150px;
      width:150px;
      font-size:0.9rem;
      white-space: normal;
    }
    span.separator {
      color: #b4b4b4;
      margin-left: $mrc-default-margin;
      margin-right: $mrc-default-margin;
      margin-top: -8px;
      font-size: 2rem;
      font-weight: bold;
    }
    button {
      margin:0;
      margin-left: $mrc-default-margin !important;
      padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    }
    .invalid-date-input-indicator {
      bottom:46px;
    }
  }

  .datePickerWrapper {
    display: inline-block;
    -webkit-appearance: none;
    line-height: normal;
    box-sizing: content-box;
    overflow: visible;
    position: relative;
    outline: 0;
    box-shadow: none;
    font-weight: 500;
    background-color: $enerkey-white;
    max-width: 100%;
    min-width: 102px;
    min-height: 32px;
    width: 100%;
    font-size: 0.9rem;
    white-space: normal;
    cursor: pointer;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    .pickerView {
      display: inline-block;
      position:absolute;
      top:0;
      left:0;
      padding: 0;
      height: 32px;
      width:100%;
    }
    .dateString {
      color: $enerkey-black;
      font-family: inherit;
      line-height: 1.65em;
      padding: .60em;
      text-indent: .33em;
      float: left;

      span {
        margin-right:1em;
      }
    }
    .iconWrap {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      border-style: solid;
      border-width: 0 0 0 1px;
      min-height: 1.65em;
      vertical-align: middle;
      text-align: center;
      border-radius: 0 2px 2px 0;
      border-color: #fafafa;
      line-height: 28px;
      width: 2.45em;
      height: 32px;
      background-color: #fafafa;
    }
    .k-datepicker {
      width:100%;
      max-width:100%;
      border:none;
      box-shadow: none;
      outline:none;
      * {
        border:none;
        box-shadow: none;
        outline:none;
      }
    }
    .k-picker-wrap > input {
      padding: .60em;
    }

    .picker-placeholder {
      color:#cacaca;
    }
    .invalid-date-input-indicator {
      bottom: 34px;
      left: 0px;
    }
  }
  .datePickerWrapper:hover {
      transition: box-shadow .15s ease-out,-webkit-box-shadow .15s ease-out;
      box-shadow: 0 2px 6px rgba(0,0,0,.2), 0 2px 3px rgba(0,0,0,.05);
    .iconWrap {
      background-color: #ebebeb;
      border-color: #fff;
    }
  }
  .datePickerWrapper.invalid-value {
    box-shadow: 0 0 8px #f75d16;
  }
  .datePickerWrapper.disabled-input:hover {
    transition: none;
    box-shadow: none;
    .iconWrap {
      background-color: #fff;
    }
  }

  .interval-input-datepicker {
    max-width:102px;
    margin-left: $mrc-default-margin;
    font-size:0.9rem;
    white-space: normal;
    float:left;
  }

  .reading-input-datepicker {
    display:initial;
    max-width:150px;
    min-width:110px;
    float:left;
  }

  .readings-listing-container {
    .select-wrapper {
      margin-bottom: 10px;
    }
    .row:first-child > * {
      display: inline-block;
    }
    label {
      margin-right: $mrc-default-margin;
      margin-left: 16px;
      cursor:pointer;
    }
    .multi-select {
      button {
        border-radius: 0px;
      }
      .multiSelect .helperButton {
          padding: 3px 5px;
      }
      .multiSelect .clearButton {
          padding: 1px;
      }
      .multiSelect .inputFilter {
          height: 30px;
          border-radius:0px;
      }
      .helperContainer {
        .line:last-child {
          margin-top: 6px;
          margin-bottom: 4px;
        }
      }
    }

    .readings-listing-grid-container {
      position: relative;
    }
  }

  .factor-input-container {
    @include flex();

    margin: $mrc-default-margin;
    width: 100%;
    max-width: 100px;
    height: 30px;

    div.factor-value-container {
      @include flex-center-center();

      cursor: pointer;
      border: 1px solid $enerkey-input-border;
      background-color: $enerkey-white;
      width: 60%;
      height: 100%;
      text-align: center;
      margin-right: 5px;
    }
    span {
      width: 100%;
      text-align: center;
      word-wrap: break-word;
    }
    button#edit {
      padding: 0.75rem 0.5rem;
      margin-right:-1px;
      height:100%;
      float: right;
      width: 40%;
    }
    .factor-input-popup {
      border:1px solid #d5d7d6;
      background-color: #f9f8f8 !important;
      min-width:64px;
      width:128px;
      margin-top: -1px;
      color:#535557;
      box-shadow:0px 1px 1px 0px rgba(0,0,0,0.2);
      position: absolute;
      z-index: 3;
      padding: 4px;
      padding-left: 0px;
      padding-right: 0px;
      input[type="tel"] {
        display: block;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4px;
        margin-top: 8px;
      }
      .popup-button-row {
        display:block;
        text-align: center;
        button {
          padding: 0.75rem 0.85rem;
          display: inline-block;
          margin: 2px;
        }
      }
    }
    .factor-input-popup.left-aligned {
      margin-left: -38px;
    }
    .factor-input-popup.right-aligned {
      margin-left: -1px;
    }
  }

  .medium-width {
    accordion.mrc-meter-history-accordion {

      ul {
        li {
          border-bottom: 1px solid #fff;
          color: #222222;
          > a {
            padding:1rem;
            display: inline-block;
            width: 100%;
            background: #EFEFEF;
            color: #222222;
            font-size: 1rem;
            .change-date-label {
              margin-right: 10px
            }

            .meter-name-label {
              font-weight: 600;
            }
          }

          table {
            tr {
              background-color: #fff;
              border-bottom: 1px solid $border-gray;
              td {
                border-right: 1px solid $border-gray;
                vertical-align: top;
              }
            }
            tr:nth-child(1) {
              td:nth-child(1) {
                width:20%;
              }
              td:nth-child(2) {
                width:80%;
                border: none;
              }
            }
            tr:nth-child(2) {
              td:nth-child(1) {
                width:25%;
              }
              td:nth-child(2) {
                width:25%;
              }
              td:nth-child(3) {
                width:25%;
              }
              td:nth-child(4) {
                width:25%;
                border: none;
              }
            }
            .property-information-label {
              margin-bottom: 4px;
              font-weight: 600;
              font-size: 0.9rem;
              display:block;
            }
            .property-information-value {
              font-size: 0.9rem;
              display: block;
              padding: 0.5rem 0;
              word-wrap: break-word;
            }
            .meter-previous-data-item {
              width: auto;
            }
          }

        }
        li.is-active > a {
          background-color: #efefef;
          color: #222222 !important;
        }
        li:first-of-type > a {
          span {
            display:inline-block;
            /*margin-top:0.8rem;*/
          }
        }
        li:last-child {
          border-bottom: none;
        }
      }
    }
  }

  mrc-searchbar {
    z-index: 4;
    width: 100%;

    .search-bar {
      display: flex;
      align-items: center;
    }

    i {
      color: $body-font-color;
      float:left;
      font-size: 1.1rem;
      text-align: center;
      padding: 0.5rem 0.6rem;
    }
    .input-container {
      float:left;
      margin-left:0.2rem;
    }
    .input-container > span {
      width: 100%;
      max-width: 250px;
      min-width: 250px;
      input {
        height:2rem;
        padding:0.1rem;
      }
    }
    .f-dropdown {
      box-shadow:0px 2px 2px 0px rgba(0,0,0,0.2);
      width:310px;
      min-width:310px;
      height:auto;
      max-height:600px;
      overflow-y: auto;
      font-weight:500;
      font-size: 16.2px;
      width: 310px;
      margin: 0;
      padding: 2px;
      border:1px solid #ebebeb;
      outline: 0;
      box-sizing: content-box;
      border-radius: 0 0 2px 2px;
    }
    .f-dropdown > li {
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      margin:0;
      line-height: 1.2em;
      min-height: 1.8em;
    }
    .f-dropdown > li > a {
      padding: 0.2rem 0.4rem 0.1rem 0.4rem;
    }
    li.active {
      background-color:#eeeeee;
      margin:0;
    }
    .result-primary {
      display:block;
      width:100%;
    }
    .result-secondary {
      display:inline-block;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: #aaa;
    }
    .loading-indicator-container {
      width:100%;
      text-align: center;
      margin-top: 0rem;
      i {
        float:none;
        font-size: 1.4rem !important;
      }
    }
    .last {
      padding-top:10px;
      padding-left:6px;
      padding-right:8px;
      button {
        width:100%;
        padding: 1em;
        max-width: 150px;
        float: right;
      }
    }
  }
  mrc-searchbar.readings {
  }

    .year-row, .year-selector, .year-block {
      @include flex-center-center();
      min-height: $accordion-height;
    }

    .year-row {
      height: 100%;
      padding-left:0;
      padding-right:0;
    }

    .year-block {
        color: $enerkey-black;
        height:100%;
        cursor:pointer;
        text-align:center;
        font-weight:600;
    }
    .year-selector {
        color: $enerkey-black;
        height:100%;
        cursor:pointer;
        text-align:center;
    }
    .selected-year {
        background: $enerkey-primary-active;
        color: $enerkey-white;

    }

    .content-readings .metering-target {
      border-bottom: 1px solid #fff;
    }

    .tabbable.is-mobile {
      .tabs {
        li {
          float: none;
          border: none;
          border-bottom: 1px solid $light-blue;
        }

        li:first-child {
          border-top: 1px solid $light-blue;
        }
      }
    }

    &.is-mobile button:focus {
      border:none;
      outline:0 !important;
    }
    &.is-mobile button:active {
      border:none;
      outline:0;
    }

    &.is-mobile mrc-searchbar {
      min-width: 100%;
    }

    &.is-mobile mrc-searchbar .input-container {
      min-width: 210px;
    }

    &.is-mobile .content-readings .data-loader, .no-data {
      margin-top:0.5rem;
      margin-bottom:0.5rem;
      margin-left:0rem;
    }

    &.is-mobile .facility-content-consumptions {
      ul.tabs.vertical {
        float:left;
      }
      div.tabs-content.vertical {
        float:right;
      }
    }

    &.is-mobile .content-readings {
      .button-row {
        background-color:#fff;
        text-align:center;
        span {
          margin-left:4px;
          margin-top:0.5rem;
        }
        button {
          margin-right:4px;
          margin-bottom: 0.5rem;
          width:33%;
        }
      }

      .reading-input-datepicker {
        min-width: 100px;
        max-width: 99%;
      }

      table {
        border-bottom: none;
        thead {
          background-color: #fefefe;
        }
      }

      span.previous-data-item {
        color:#222;
      }

      div.previous-data-item-wrapper {
        width: 49%;
        text-align:right;
        color:#222;

        span.previous-data-item {
          width: auto;
        }
        span.meter-previous-data-item {
          width:auto;
          padding:0px !important;
          text-align:right;
        }
      }

      .metering-point {
        .value-holder {
          background-color: #fefefe;
        }
      }

      .meter-data-headers {
        border: none;
        border-collapse: separate;
      }

      .meter-data-table-headers > div {
        padding-bottom: 0px;
      }

      .meter-content-accordion li {
        border-bottom: 1px solid #fff;
        color: #222222;
      }

      .meter-content-accordion li > a {
        padding: 0.5rem 0.5rem 0.5rem 2em;
        display: flex;
        align-items: center;
        width: 100%;
        background: #EFEFEF;
        color: #222222;
        font-size: 1rem;
      }
      .meter-content-accordion li.is-active > a {
        background-color: #efefef;
        color: #222222 !important;
      }
      .meter-content-accordion li:first-of-type > a {
        span {
          display:inline-block;
          /*margin-top:0.8rem;*/
        }
      }

      .no-manual-meters {
        text-align: center;
      }

      .metering-target {
        min-width:0;

        .accordion-title::before {
          display:none;
        }
      }

      .single-facility-content {
        padding: 0;
        margin: 0;

        .common-actions-wrapper {

          margin-bottom: 0px;

          .facility-info-wrapper {
            padding-top: 0.5rem;
            text-align: center;

            >button {
              width: 95%
            }
          }

          .common-input-wrapper {
            padding-top: 1rem;

            .common-datepicker-wrapper {
              width: 100%;
              float: left;

              > span {
                float: left;
              }
            }
          }

          .common-save-wrapper {
            width: 100%;
            margin-top: 0.5rem;

            >button {
              display: block;
              margin: auto;
            }

            &.bottom-instance {
              margin-top: -0.5rem;
            }
          }
        }


        .meter-readings-container {

          table {
            width: 100%;
            border: none;

            tr:nth-of-type(odd) { background: #f9f9f9; }
            tr:nth-of-type(even) { background: #fff; }

            th:nth-child(1),
            td:nth-child(1),
            th.child-cell-1,
            td.child-cell-1  {
              width: 33%;
            }

            th:nth-child(2),
            td:nth-child(2),
            th.child-cell-2,
            td.child-cell-2  {
              width: auto;
            }
          }

          .meter-readings-wrapper {
            .reading-row {
              clear:both;
              display: inline-block;
              width:100%;
              .type-change-indication-row, .reading-row-content {
                padding: 4px;
              }
              .reading-date {
                width: 33%;
                padding-left:0rem;

                .date-value {
                  padding: 0.2rem 2.5rem 0.2rem 0.2rem;
                }

                .k-datepicker {
                  max-width: 120px;
                }
              }

              .meter-readings {
                width: auto;
                padding: 0.2rem;
                /*min-width: 85px;*/

                .meter-input-container {
                  >input {
                    max-width: 86px;
                  }
                }

                span {
                  width: auto;
                }
              }

              .buttons-div {
                width: auto;
                float:right;
                button {
                  margin:2px 2px;
                }
              }
            }
          }
          .new-reading-row {
            clear:both;
            display:inline-block;
            width:100%;
            padding-top:4px;
            padding-bottom:4px;
          }
        }
          .meter-history-content {
          padding: 0.25rem;

          accordion.mrc-meter-history-accordion {
            ul {
              margin-top: 2px;

              li {
                > a {
                  .change-date-label {
                    margin-right: 10px
                  }

                  .meter-name-label {
                    font-weight: 600;
                  }
                }

                .property-information-list {
                  margin: 0;
                  .property-information-label {
                    margin-bottom: 4px;
                  }
                  .meter-previous-data-item {
                    width: auto;
                  }
                }
              }

              li:last-child {
                border-bottom: none;
              }
            }
          }
        }

      }
      .metering-point {

        .meter-name-holder {
          padding: 0.5rem;
          display: flex;
          align-items: center;
          min-height: $accordion-height;
        }

        .meter-content {
          .meter-content-accordion {
            li {
              border-bottom: 1px solid #ddd;
            }
          }
        }
        table.meter-data-table {
          .meter-input-container {
            margin-left: 2px;
            margin-right: 0px;
            width:49%;
            &.multi-values {
              display: table-cell;

              .meter-reading-input {
                width: 95%;
              }
            }
          }
          .meter-previous-data-item.multi-values {
            display: table-cell;
          }

          tr.add-new-values {
            > td {
              padding: 0.2rem 0rem;

              span.k-datepicker {
                width: 100%;
              }

              > div.previous-data-item-wrapper {
                width: auto;

                span.previous-data-item {
                  width: auto;
                }
              }
            }
          }
        }
        table.meter-data-table.single-value-container {
          tr.add-new-values {
            td {
              div {
                width:100%;
              }
            }
          }
          .meter-input-container {
            width:100%;
            input {
              width:99%;
              max-width:100%;
            }
          }
          div.previous-data-item-wrapper {
            width:100%;
          }
        }
      }
      .vertical-input-container {
        margin: 0;
        padding: 0;

        input[type="radio"] {
          margin: 0.5rem;
        }
      }
    }
    input[type="tel"] {
      text-align: right;
      font-size: 0.9rem;
    }
}

 .input-popup {
    position:absolute;
    box-sizing: border-box;
    border:1px solid #d5d7d6;
    border-top:8px solid #18c0f1;
    background-color: #f9f8f8 !important;
    padding:0 !important;
    min-width:80px;
    width:312px;
    color:#535557;
    box-shadow:0px 1px 1px 0px rgba(0,0,0,0.2);
    .row {
      border-bottom:1px solid #d5d7d6 !important;
      padding:8px !important;
      width:100%;
    }
    span.right {
      font-size: 0.75rem;
      margin-top: 10px;
    }
    .row > span {
      margin-top: 6px;
      margin-left: $mrc-default-margin;
    }
    .button-row {
      background-color:#fff;
      padding:4px !important;
    }

    button {
      margin-bottom: 0 !important;
    }

    input[type="tel"] {
      max-width:36%;
      display:inline-block;
      margin-right: $mrc-default-margin;
      text-align:right;
      font-size: 0.9rem;
    }
    input {
      margin:0 !important;
    }
    input::placeholder {
      color:#b4b6b6;
    }
    input[type="radio"] {
      margin-right: $mrc-default-margin !important;
    }
    .notification {
      text-align:center;
      padding:8px;
      color:#b4b6b6;
      i {
        color:#ee488a;
        margin-right:4px;
        margin-top:5px;
      }
      span {
        margin-top:2px;
      }
      span:last-child {
        margin-left: $mrc-default-margin;
      }
    }
    .nub {
      border:solid 10px !important;
      border-color: transparent transparent #18c0f1 transparent !important;
      margin-top:-26px !important;
      width:0;
    }
    .loading-indicator {
      text-align:center;
      padding:8px !important;
      font-size:1.1rem;
    }
    .header-row {
      text-align:center;
      background-color: $enerkey-white;
      margin-top:6px;
      span:last-child {
        margin-right: $mrc-default-margin * 2;
        margin-top: $mrc-default-margin;
        font-size:0.85rem;
      }
    }
    .missing-interval-row {
      background-color:#fff;
    }
    .bold {
      font-weight:680;
    }
    .grey {
      color:#b4b6b6;
    }
  }

  .month-values {
    .nub {
      margin-left:50%;
    }
    input[type="checkbox"] {
      margin-top: 0.25rem !important;
      margin-right: 0.4rem !important;
      width: 1rem;
      height: 1rem;
    }
    label {
      margin-top: 0.25rem;
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }
  .year-values {
    .nub {
      margin-left:90%;
    }
    .row {
      background-color:#fff;
    }
  }
  .invalid-input-indicator {
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #FD8E00;
    padding: 4px;
    z-index: 600;
    i {
      color:#ff0000;
    }
    span {
      margin-left: 2px;
    }
  }

  .meters {
    margin-top: 0.5rem;
  }

  .mrc accordion .metering-target > a.accordion-title > div.accordion-heading-filler {
      vertical-align: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 2rem 1rem;
  }

  .mobile-readings-area {
    border-bottom: 2px solid $enerkey-secondary;
  }

  .mobile-interval-consumption-area-separator {
    border-bottom: 2px solid $enerkey-secondary;
  }

  .facility-info-area-top {
    padding-top: 5px;
  }
  .facility-info-area-bottom {
    margin-bottom: 5px !important;
  }
  .consumption-value {
    text-align: right;
    margin-bottom: 5px;
  }


