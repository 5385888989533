mrc-mass-import {
  .k-spreadsheet-action-bar {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  .mass-import-content {
    margin-top: 12px;
  }

  #massImportSheetContainer {
    box-shadow: none;
  }
}
