:root {
  --enerkey-white: #{$enerkey-white};
  --enerkey-black: #{$enerkey-black};

  --enerkey-primary: #{$enerkey-primary};
  --enerkey-primary-active: #{$enerkey-primary-active};
  --enerkey-primary-action-hover: #{$enerkey-primary-action-hover};
  --enerkey-remove-action: #{$enerkey-remove-action};
  --enerkey-warning: #{$enerkey-warning};
  --enerkey-secondary: #{$enerkey-secondary};
  --enerkey-secondary-light: #{$enerkey-secondary-light};
  --enerkey-topbar: #{$enerkey-topbar};
  --enerkey-topbar-text: #{$enerkey-topbar-text};
  --enerkey-topbar-icon: #{$enerkey-topbar-icon};
  --enerkey-topbar-primary: #{$enerkey-topbar-primary};
  --enerkey-highlight: #{$enerkey-highlight};
  --enerkey-background: #{$enerkey-background};
  --enerkey-background-overlay: #{$enerkey-background-overlay};
  --enerkey-error: #{$enerkey-error};
  --enerkey-badge: #{$enerkey-badge};
  --enerkey-badge-text: #{$enerkey-badge-text};
  --enerkey-meter-pink: #EC3680;
  --enerkey-meter-light-blue: #00adef;
  --enerkey-meter-dark-green: #325F2A;
  --enerkey-meter-light-green: #8FD263;
  --enerkey-header-general: #{$enerkey-header-general};
  --enerkey-header-text: #{$enerkey-header-text};
  --enerkey-modal-header: #{$enerkey-modal-header};
  --enerkey-modal-header-text: #{$enerkey-modal-header-text};
  --enerkey-modal-close-background: #{$enerkey-modal-close-background};
  --modal-header-height: #{$modal-header-height};
  --enerkey-link: #{$enerkey-link};
  --enerkey-green: #{$enerkey-green};
  --enerkey-blue: #{$enerkey-blue};
  --enerkey-light-blue: #{$enerkey-light-blue};
  --enerkey-red: #{$enerkey-red};
  --enerkey-footnote: #{$enerkey-footnote};
  --enerkey-input-shadow: $enerkey-input-shadow;
  --enerkey-input-background: #{$enerkey-input-background};
  --enerkey-input-focus-border-color: #{$enerkey-input-focus-border-color};
  --enerkey-border: #{$enerkey-border};
  --enerkey-accordion-light: #{$enerkey-accordion-light};
  --enerkey-light-grey: #{$enerkey-light-grey};
  --enerkey-extra-light-grey: #{$enerkey-extra-light-grey};
  --enerkey-grey: #{$enerkey-grey};
  --enerkey-grid-row-light: #{$enerkey-grid-row-light};
  --enerkey-panel-text: #{$enerkey-panel-text};

  --enerkey-pdf: #{$enerkey-pdf};
  --enerkey-excel: #{$enerkey-excel};
  --enerkey-xml: #{$enerkey-xml};

  --enerkey-loading-foreground: #{$enerkey-loading-foreground};
  --enerkey-loading-background: #{$enerkey-loading-background};

  --enerkey-positive-change: #{$enerkey-positive-change};
  --enerkey-negative-change: #{$enerkey-negative-change};

  @each $key, $value in $enerkey-quantity-colors {
    --enerkey-quantity-color-#{$key}: #{$value};
  }

  @each $key, $value in $icon-ekey-quantity-icons {
    --enerkey-quantity-icon-#{$key}: #{$value};
  }

  --enerkey-investigation-required: #{$enerkey-red};
  --enerkey-under-investigation: #{$enerkey-under-investigation};
  --enerkey-investigation-done: #{$enerkey-green};
  --enerkey-no-investigation-required: #{$enerkey-no-investigation-required};

  --enerkey-toaster-margin: #{$enerkey-toaster-margin};
  --enerkey-toaster-info: #{$enerkey-toaster-info};
  --enerkey-toaster-success: #{$enerkey-toaster-success};
  --enerkey-toaster-warning: #{$enerkey-toaster-warning};
  --enerkey-toaster-error: #{$enerkey-toaster-error};

  --grid-alt-bg: #{$grid-alt-bg};

  --input-shadow: #{$input-shadow};
  --input-height: #{$input-height};

  --enerkey-card-shadow: #{$enerkey-card-shadow};
  --font-stack: #{$font-stack};
  --font-awesome: #{$font-awesome};
  --heading-font: #{$heading-font};
  --heading-font-weight: #{$heading-font-weight};
  --heading-thin-font: #{$heading-thin-font};
  --heading-thin-font-weight: #{$heading-thin-font-weight};
  --body-font-size: #{$body-font-size};

  --spacing-default: 1rem;
  --spacing-more: calc(var(--spacing-default) * 2);
  --spacing-less: calc(var(--spacing-default) / 2);
  --container-radius: #{$container-radius};
  --topbar-height: #{$topbar-height};
  --content-header-height: #{$content-header-height};
  --accordion-height: #{$accordion-height};
  --content-full-height: calc(100vh - var(--topbar-height) - var(--content-header-height) - 2 * var(--spacing-default));

  --enerkey-chart-first-comparison: #{$enerkey-chart-first-comparison};
  --enerkey-chart-second-comparison: #{$enerkey-chart-second-comparison};

  --logo-secondary-image: #{$logo-secondary-image};

  --kendo-text-color: #{$body-text};

  --topbar-item-active-shadow: #{$topbar-item-active-shadow};
  --topbar-item-hover-shadow: #{$topbar-item-hover-shadow};

  --ines-insights-color: #{$ines-insights-color};
  --ines-money-color: #{$ines-money-color};
  --ines-consumption-color: #{$ines-consumption-color};
  --ines-co2-color: #{$ines-co2-color};
  
  --ines-ventilation-color: #{$ines-ventilation-color};
  --ines-heatbalance-color: #{$ines-heatbalance-color};
  --ines-heatingpower-color: #{$ines-heatingpower-color};
  --ines-electricitydr-color: #{$ines-electricitydr-color};
  --ines-heatingoptimization-color: #{$ines-heatingoptimization-color};
  --ines-heatingcooling-color: #{$ines-heatingcooling-color};

  @each $key, $value in $enerkey-gri-scope-colors {
    --enerkey-gri-#{$key}: #{$value};
  }
  
  --reporting-sidebar-width: #{$reporting-sidebar-width};
  --reporting-sidebar-button-width: #{$reporting-sidebar-button-width};
}
