@import "../components/components";

.password-modal {
  .modal-body {
    min-height: 20px;
  }
}

.config-editor {
  .property-description {
    color: $enerkey-primary;
  }

  .required-property {
    color: $enerkey-red;
    font-weight: bold;
  }
}

.configuration-control-search-bar {
  margin-bottom: var(--spacing-default);

  > * {
    display: flex;
    gap: var(--spacing-less);

    &:last-child {
      justify-content: end;
    }
  }

  .k-textbox {
    width: 40%;
  }

  .k-multiselect {
    width: 60%;
  }

  .k-dropdownlist {
    width: 25%;
  }
}

.configurations-view {
  > button {
    font-size: 12px;
  }
}

.documentation-label {
  float: left;
}

.bucket-documentation-edit-link {
  float: left;
  /* stylelint-disable-next-line */
  padding-top: 0.9rem !important;
}

.bucket-documentation-text {
  overflow-y: auto;
  height: 700px;
}

.indented-field {
  padding-left: 15px;
}

.datetimepicker {
  width: 14em;
}

.cc-kendowindow {
  .k-edit-label .k-edit-field {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .k-edit-form-container {
    width: 750px;
  }
}
