$font-stack: 'Open Sans', sans-serif !default;

$enerkey-book-font: $font-stack !default;
$enerkey-medium-font: $font-stack !default;

/* Path for font awesome */
$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";

/* Dashboard widget dimensions */
$widget-height: 535px;

/* Topbar height */
$topbar-height: 57px;
$content-header-height: 57px;

$tab-item-active-shadow: inset 0px -5px 0px 0px $enerkey-tab-highlight !default;
$tab-item-hover-shadow: inset 0px -5px 0px 0px $enerkey-tab-highlight !default;
$topbar-item-active-shadow: inset 0px -5px 0px 0px $enerkey-topbar-highlight !default;
$topbar-item-hover-shadow: inset 0px -5px 0px 0px $enerkey-topbar-highlight !default;

/* Modal state definitions */
$modal-header-height: 44px;
$modal-width: 100%;
$modal-height: 95%;

/* Sidebar width */
$sidebar-width: 400px;
$sidebar-width-collapsed: 25px;

/* Reporting sidebar */
$reporting-sidebar-width: min(444px, 100vw);
$reporting-sidebar-button-width: 44px;

/* Measurement */
$spacing-default: 1rem;
$spacing-more: $spacing-default * 2;
$spacing-less: $spacing-default * 0.5;
$container-radius: 0 !default;

/* Heading font */
$heading-font: $enerkey-medium-font;
$heading-font-weight: 400 !default;
$heading-thin-font: $enerkey-book-font;
$heading-thin-font-weight: 400 !default;

/* Buttons font */
$button-font: $enerkey-book-font;

$enerkey-button-border-radius: 50px !default;
$enerkey-button-icon-border-radius: 50% !default;

/* Menu item font */
$menu-item-font: $enerkey-book-font;

$menu-padding-left: 10px;
$menu-padding-right: 10px;

$input-height: 44px;

$body-font-size: 13px !default;

$accordion-height: 54px;

$app-container-margin: 1rem;

$font-awesome: 'Font Awesome 5 Free';

$logo-image: url(/assets/images/EnerKey-logo-white.svg?ver=2) !default;
$logo-secondary-image: null !default;

$enerkey-card-shadow: 0 1px 2px 0 rgba(100, 100, 100, 0.5) !default;

$enerkey-link-font-weight: 400 !default;
