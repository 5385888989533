// Icons
//
// Styleguide 3.0

// Icon showcase
//
// .icon-icon-notes-blank   - Actions or comments
// .icon-icon-notes-content - Actions or comments exist
// .icon-icon-notes-add     - Add action or comment
// .icon-ekey-add-file      - Add attachment (FontAwesome file icon with plus)
// .icon-ekey-percent       - TODO
// .icon-ekey-times           - Times sign
// .icon-ekey-leaf            - Leaf
// .icon-ekey-bookmark        - Bookmark
// .icon-ekey-bulb            - Lightbulb
// .icon-uniE600              - Exclamation
// .icon-ekey-arrow-up        - Up arrow
// .icon-ekey-arrow-down      - Down arrow
// .icon-ekey-arrow-left      - Left arrow
// .icon-ekey-arrow-right     - Right arrow
// .icon-ekey-accept          - Accept
// .icon-ekey-cancel          - Cancel
// .icon-ekey-close           - Close
// .icon-ekey-logo            - Logo
// .icon-ekey-options         - Options
// .icon-ekey-star            - Star
// .icon-ekey-groups          - Groups
// .icon-ekey-office          - Office
// .icon-ekey-percent         - Percent
// .icon-ekey-dottedchart     - Dotted chart
// .icon-ekey-thermometer     - Thermometer
// .icon-icon-notes-blank     - Actions or comments
// .icon-icon-notes-content   - Actions or comments exist
// .icon-icon-notes-add       - Add action or comment
// .icon-ekey-add-file        - Add attachment (FontAwesome file icon with plus)
//
// markup:
// <span class="{$modifiers}" style="font-size: 10px;"></span>
// <span class="{$modifiers}" style="font-size: 20px;"></span>
// <span class="{$modifiers}" style="font-size: 30px;"></span>
// <span class="{$modifiers}" style="font-size: 40px;"></span>
// <span class="{$modifiers}" style="font-size: 50px;"></span>
//
// Styleguide 3.1

.icon-ekey-percent:before {
  /* Hacky custom percent icon since FA doenst' support it yet */
  content: "%";
  /* stylelint-disable-next-line */
  font-family: "open_sansregular", "Open Sans", sans-serif !important;
  font-weight: 900;
}

// Energy type icons
//
// .icon-ekey-water           - Water
// .icon-ekey-hotwater        - Hot water
// .icon-ekey-coolingwater    - Cooling water
// .icon-ekey-wastewater      - Waste water
// .icon-ekey-iceagewater     - Ice age water
// .icon-ekey-electricity     - Electricity
// .icon-ekey-districtheating - District heating
// .icon-ekey-districtcooling - District cooling
// .icon-ekey-naturalgas      - Natural gas
// .icon-ekey-oil             - Oil
// .icon-ekey-compressedair   - Compressed air
//
// markup:
// <span class="{$modifiers}" style="font-size: 10px;"></span>
// <span class="{$modifiers}" style="font-size: 20px;"></span>
// <span class="{$modifiers}" style="font-size: 30px;"></span>
// <span class="{$modifiers}" style="font-size: 40px;"></span>
// <span class="{$modifiers}" style="font-size: 50px;"></span>
//
// Styleguide 3.2

@import "font_variables";

@mixin quantity-icon($color) {
  color: $color;

  &--sidebar::before {
    background-color: $color;
  }
}

.icon-ekey-water {
  @include quantity-icon($enerkey-water);
}

.icon-ekey-hotwater {
  @include quantity-icon($enerkey-hotwater);

  &:before {
    content: $icon-ekey-water;
  }
}

.icon-ekey-coolingwater {
  @include quantity-icon($enerkey-coolingwater);
}

.icon-ekey-wastewater {
  @include quantity-icon($enerkey-wastewater);
}

.icon-ekey-iceagewater {
  @include quantity-icon($enerkey-iceagewater);
}

.icon-ekey-districtheating {
  @include quantity-icon($enerkey-districtheating);
}

.icon-ekey-electricity {
  @include quantity-icon($enerkey-electricity);
}

.icon-ekey-districtcooling {
  @include quantity-icon($enerkey-districtcooling);
}

.icon-ekey-naturalgas {
  @include quantity-icon($enerkey-naturalgas);
}

.icon-ekey-oil {
  @include quantity-icon($enerkey-oil);
}

.icon-ekey-compressedair {
  @include quantity-icon($enerkey-compressedair);
}

.icon-ekey-liquefiedpetroleumgas {
  @include quantity-icon($enerkey-liquefiedpetroleumgas);
}

.icon-ekey-steam {
  @include quantity-icon($enerkey-steam);
}

.icon-ekey-heatingenergy {
  @include quantity-icon($enerkey-heatingenergy);
}

.icon-ekey-totalenergy {
  @include quantity-icon($enerkey-totalenergy);
}

.icon-ekey-heatingownproduction {
  @include quantity-icon($enerkey-heatingownproduction);
}

.icon-ekey-coolingownproduction {
  @include quantity-icon($enerkey-coolingownproduction);
}

.icon-ekey-naturalgasother {
  @include quantity-icon($enerkey-naturalgasother);
}

.icon-ekey-biogas {
  @include quantity-icon($enerkey-biogas);
}

.icon-ekey-pellet {
  @include quantity-icon($enerkey-pellet);
}

.icon-ekey-woodchips {
  @include quantity-icon($enerkey-woodchips);
}

.icon-ekey-heavyoil {
  @include quantity-icon($enerkey-heavyoil);
}

.icon-ekey-carbondioxide {
  @include quantity-icon($enerkey-carbondioxide);
}

.icon-ekey-nitrogen {
  @include quantity-icon($enerkey-nitrogen);
}

.icon-ekey-oxygen {
  @include quantity-icon($enerkey-oxygen);
}

.icon-ekey-dryice {
  @include quantity-icon($enerkey-dryice);
}

.icon-ekey-reactivepowerplus {
  @include quantity-icon($enerkey-reactivepowerplus);
}

.icon-ekey-reactivepowerminus {
  @include quantity-icon($enerkey-reactivepowerminus);
}

.icon-ekey-ventilation {
  @include quantity-icon($enerkey-ventilation);
}

.icon-ekey-refrigeration {
  @include quantity-icon($enerkey-refrigeration);
}

.icon-ekey-pue {
  @include quantity-icon($enerkey-pue);
}

.icon-ekey-lighting {
  @include quantity-icon($enerkey-lighting);
}

.icon-ekey-heatingelectricity {
  @include quantity-icon($enerkey-heatingelectricity);
}

.icon-ekey-heatpumps {
  @include quantity-icon($enerkey-heatpumps);
}

.icon-ekey-facilityelectricity {
  @include quantity-icon($enerkey-facilityelectricity);
}

.icon-ekey-coolingsystem {
  @include quantity-icon($enerkey-coolingsystem);
}

.icon-ekey-condensationheat {
  @include quantity-icon($enerkey-condensationheat);
}

.icon-ekey-chargingsystem {
  @include quantity-icon($enerkey-chargingsystem);
}

.icon-ekey-percentage {
  @include quantity-icon($enerkey-percentage);
}

.icon-ekey-ratio {
  @include quantity-icon($enerkey-ratio);
}

.icon-ekey-custom {
  @include quantity-icon($enerkey-custom);
}

.icon-ekey-percentage2 {
  @include quantity-icon($enerkey-percentage2);

  &:before {
    content: $icon-ekey-percentage;
  }
}

.icon-ekey-ratio2 {
  @include quantity-icon($enerkey-ratio2);

  &:before {
    content: $icon-ekey-ratio;
  }
}

.icon-ekey-custom2 {
  @include quantity-icon($enerkey-custom2);

  &:before {
    content: $icon-ekey-custom;
  }
}

.icon-ekey-cop {
  @include quantity-icon($enerkey-cop);
}

.icon-ekey-scop {
  @include quantity-icon($enerkey-scop);
}

.icon-ekey-eer {
  @include quantity-icon($enerkey-eer);
}

.icon-ekey-seer {
  @include quantity-icon($enerkey-seer);
}

.icon-ekey-ownelectricity {
  @include quantity-icon($enerkey-ownelectricity);
}

.icon-ekey-totalcooling {
  @include quantity-icon($enerkey-totalcooling);
}

.icon-ekey-totalelectricity {
  @include quantity-icon($enerkey-totalelectricity);
}

.icon-ekey-heatingpurchased {
  @include quantity-icon($enerkey-heatingpurchased);
}

.icon-ekey-totalenergypurchased {
  @include quantity-icon($enerkey-totalenergypurchased);
}

.icon-ekey-metalwaste {
  @include quantity-icon($enerkey-metalwaste);
}

.icon-ekey-plasticwaste {
  @include quantity-icon($enerkey-plasticwaste);
}

.icon-ekey-glasswaste {
  @include quantity-icon($enerkey-glasswaste);
}

.icon-ekey-mixednorecyclingwaste {
  @include quantity-icon($enerkey-mixednorecyclingwaste);
}

.icon-ekey-biowaste {
  @include quantity-icon($enerkey-biowaste);
}

.icon-ekey-paperwaste {
  @include quantity-icon($enerkey-paperwaste);
}

.icon-ekey-moldedfiberwaste {
  @include quantity-icon($enerkey-moldedfiberwaste);
}

.icon-ekey-electricalandelectronicwaste {
  @include quantity-icon($enerkey-electricalandelectronicwaste);
}

.icon-ekey-woodwaste {
  @include quantity-icon($enerkey-woodwaste);
}

.icon-ekey-otherwaste {
  @include quantity-icon($enerkey-otherwaste);
}

.icon-ekey-hazardouswaste {
  @include quantity-icon($enerkey-hazardouswaste);
}

.icon-ekey-wasterecycle {
  @include quantity-icon($enerkey-wasterecycle);
}

.icon-ekey-totalwaste {
  @include quantity-icon($enerkey-totalwaste);
}

.icon-ekey-indoorairquality {
  @include quantity-icon($enerkey-indoorairquality);
}

.icon-ekey-indoorpermanence {
  @include quantity-icon($enerkey-indoorpermanence);
}

.icon-ekey-temperaturepermanence {
  @include quantity-icon($enerkey-temperaturepermanence);
}

.icon-ekey-co2permanence {
  @include quantity-icon($enerkey-co2permanence);
}

.icon-ekey-humiditypermanence {
  @include quantity-icon($enerkey-humiditypermanence);
}

.icon-ekey-particulatespermanence {
  @include quantity-icon($enerkey-particulatespermanence);
}

.icon-ekey-pressurepermanence {
  @include quantity-icon($enerkey-pressurepermanence);
}

.icon-ekey-vocpermanence {
  @include quantity-icon($enerkey-vocpermanence);
}

.icon-ekey-indoortemperature {
  @include quantity-icon($enerkey-indoortemperature);
}

.icon-ekey-indoorco2 {
  @include quantity-icon($enerkey-indoorco2);
}

.icon-ekey-indoorhumidity {
  @include quantity-icon($enerkey-indoorhumidity);
}

.icon-ekey-indoorparticulates {
  @include quantity-icon($enerkey-indoorparticulates);
}

.icon-ekey-indoorpressure {
  @include quantity-icon($enerkey-indoorpressure);
}

.icon-ekey-indoorvoc {
  @include quantity-icon($enerkey-indoorvoc);
}

.icon-ekey-dieseltransport {
  @include quantity-icon($enerkey-dieseltransport);
}

.icon-ekey-biodieseltransport {
  @include quantity-icon($enerkey-biodieseltransport);
}

.icon-ekey-petroltransport {
  @include quantity-icon($enerkey-petroltransport);
}

.icon-ekey-lngtransport {
  @include quantity-icon($enerkey-lngtransport);
}

.icon-ekey-biogastransport {
  @include quantity-icon($enerkey-biogastransport);
}

.icon-ekey-naturalgastransport {
  @include quantity-icon($enerkey-naturalgastransport);
}

.icon-ekey-electricitytransport {
  @include quantity-icon($enerkey-electricitytransport);
}

.icon-ekey-fueloiltransport {
  @include quantity-icon($enerkey-fueloiltransport);
}

.icon-ekey-kmtransport {
  @include quantity-icon($enerkey-kmtransport);
}

.icon-ekey-transport {
  @include quantity-icon($enerkey-transport);
}

.icon-ekey-personsin {
  @include quantity-icon($enerkey-personsin);
}

.icon-ekey-personsout {
  @include quantity-icon($enerkey-personsout);
}

.icon-ekey-personsoccupancy {
  @include quantity-icon($enerkey-personsoccupancy);
}

.icon-ekey-ethanole85transport {
  @include quantity-icon($enerkey-ethanole85transport);
}

.icon-ekey-biogasmwh {
  @include quantity-icon($enerkey-biogas);

  &:before {
    content: $icon-ekey-biogas;
  }
}

.icon-ekey-heavyfueloilmwh {
  @include quantity-icon($enerkey-heavyoil);

  &:before {
    content: $icon-ekey-heavyoil;
  }
}

.icon-ekey-liquidgasmwh {
  @include quantity-icon($enerkey-liquefiedpetroleumgas);

  &:before {
    content: $icon-ekey-liquefiedpetroleumgas;
  }
}

.icon-ekey-naturalgasmwh {
  @include quantity-icon($enerkey-naturalgas);

  &:before {
    content: $icon-ekey-naturalgas;
  }
}

.icon-ekey-naturalgasothermwh {
  @include quantity-icon($enerkey-naturalgasother);

  &:before {
    content: $icon-ekey-naturalgasother;
  }
}

.icon-ekey-pelletmwh {
  @include quantity-icon($enerkey-pellet);

  &:before {
    content: $icon-ekey-pellet;
  }
}

.icon-ekey-personsinavg {
  @include quantity-icon($enerkey-personsin);

  &:before {
    content: $icon-ekey-personsin;
  }
}

.icon-ekey-personsoutavg {
  @include quantity-icon($enerkey-personsout);

  &:before {
    content: $icon-ekey-personsout;
  }
}

.icon-ekey-personsoccupancyavg {
  @include quantity-icon($enerkey-personsoccupancy);

  &:before {
    content: $icon-ekey-personsoccupancy;
  }
}

.icon-ekey-oilmwh {
  @include quantity-icon($enerkey-oil);

  &:before {
    content: $icon-ekey-oil;
  }
}

.icon-ekey-woodchipsmwh {
  @include quantity-icon($enerkey-woodchips);

  &:before {
    content: $icon-ekey-woodchips;
  }
}

.icon-ekey-reactivepower {
  @include quantity-icon($enerkey-reactivepower);

  &:before {
    content: $icon-ekey-reactivepower;
  }
}

.icon-ekey-districtheatingwater {
  @include quantity-icon($enerkey-districtheating);

  &:before {
    content: $icon-ekey-water;
  }
}

.icon-ekey-totalownenergy {
  @include quantity-icon($enerkey-totalenergy);
}

// Energy type icons with background
//
// .icon-ekey-water           - Water
// .icon-ekey-hotwater        - Hot water
// .icon-ekey-coolingwater    - Cooling water
// .icon-ekey-wastewater      - Waste water
// .icon-ekey-iceagewater     - Ice age water
// .icon-ekey-electricity     - Electricity
// .icon-ekey-districtheating - District heating
// .icon-ekey-districtcooling - District cooling
// .icon-ekey-naturalgas      - Natural gas
// .icon-ekey-oil             - Oil
// .icon-ekey-compressedair   - Compressed air
//
// markup:
// <div class="header-icon header-icon--bg-round header-icon--bg-white" style="font-size: 10px;"><span class="{$modifiers}"></span></div>
// <div class="header-icon header-icon--bg-round header-icon--bg-white" style="font-size: 20px;"><span class="{$modifiers}"></span></div>
// <div class="header-icon header-icon--bg-round header-icon--bg-white" style="font-size: 30px;"><span class="{$modifiers}"></span></div>
// <div class="header-icon header-icon--bg-round header-icon--bg-white" style="font-size: 40px;"><span class="{$modifiers}"></span></div>
//
// sg-wrapper:
// <div style="background-color: #00adef; padding: 2em 0.5em 2em 0.5em; display: flex; align-items: center">
//   <sg-wrapper-content/>
// </div>
//
// Styleguide 3.3
.header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;

  &--bg-round {
    border-radius: 50%;
  }

  &--bg-white {
    background-color: $enerkey-panel-background;
  }
}

$icon-ekey-quantity-icons: (
  1: $icon-ekey-electricity,
  2: $icon-ekey-naturalgas,
  3: $icon-ekey-districtheating,
  4: $icon-ekey-water,
  5: $icon-ekey-oil,
  6: $icon-ekey-water,
  7: $icon-ekey-iceagewater,
  8: $icon-ekey-wastewater,
  9: $icon-ekey-coolingwater,
  10: $icon-ekey-compressedair,
  11: $icon-ekey-districtcooling,
  12: $icon-ekey-reactivepowerplus,
  13: $icon-ekey-districtheating, // $enerkey-districtheatingwater
  14: $icon-ekey-districtheating, // $enerkey-districtheatingoutflowwater
  15: $icon-ekey-districtheating, // $enerkey-districtheatinginflowwater
  16: $icon-ekey-districtheating, // $enerkey-districtcoolingwater
  17: $icon-ekey-steam,
  18: $icon-ekey-liquefiedpetroleumgas,
  19: $icon-ekey-heatingownproduction,
  20: $icon-ekey-coolingownproduction,
  21: $icon-ekey-naturalgasother,
  22: $icon-ekey-biogas,
  23: $icon-ekey-pellet,
  24: $icon-ekey-woodchips,
  25: $icon-ekey-heavyoil,
  26: $icon-ekey-carbondioxide,
  27: $icon-ekey-oxygen,
  28: $icon-ekey-nitrogen,
  29: $icon-ekey-dryice,
  30: $icon-ekey-reactivepowerplus,
  31: $icon-ekey-reactivepowerminus,
  32: $icon-ekey-indoorairquality,
  33: $icon-ekey-indoorpermanence,
  34: $icon-ekey-temperaturepermanence,
  35: $icon-ekey-co2permanence,
  36: $icon-ekey-humiditypermanence,
  37: $icon-ekey-particulatespermanence,
  38: $icon-ekey-pressurepermanence,
  39: $icon-ekey-vocpermanence,
  40: $icon-ekey-indoortemperature,
  41: $icon-ekey-indoorco2,
  42: $icon-ekey-indoorhumidity,
  43: $icon-ekey-indoorparticulates,
  44: $icon-ekey-indoorpressure,
  45: $icon-ekey-indoorvoc,
  46: $icon-ekey-facilityelectricity,
  47: $icon-ekey-lighting,
  48: $icon-ekey-refrigeration,
  49: $icon-ekey-condensationheat,
  50: $icon-ekey-heatingelectricity,
  51: $icon-ekey-ventilation,
  52: $icon-ekey-coolingsystem,
  53: $icon-ekey-chargingsystem,
  54: $icon-ekey-heatpumps,
  55: $icon-ekey-pue,
  56: $icon-ekey-percentage,
  57: $icon-ekey-ratio,
  58: $icon-ekey-custom,
  59: $icon-ekey-percentage,
  60: $icon-ekey-ratio,
  61: $icon-ekey-custom,
  62: $icon-ekey-dieseltransport,
  63: $icon-ekey-petroltransport,
  64: $icon-ekey-lngtransport,
  65: $icon-ekey-biogastransport,
  66: $icon-ekey-naturalgastransport,
  67: $icon-ekey-electricitytransport,
  68: $icon-ekey-fueloiltransport,
  69: $icon-ekey-ethanole85transport,
  70: $icon-ekey-biodieseltransport,
  71: $icon-ekey-kmtransport,
  72: $icon-ekey-metalwaste,
  73: $icon-ekey-plasticwaste,
  74: $icon-ekey-glasswaste,
  75: $icon-ekey-mixednorecyclingwaste,
  76: $icon-ekey-biowaste,
  77: $icon-ekey-paperwaste,
  78: $icon-ekey-moldedfiberwaste,
  79: $icon-ekey-electricalandelectronicwaste,
  80: $icon-ekey-woodwaste,
  81: $icon-ekey-otherwaste,
  82: $icon-ekey-hazardouswaste,
  83: $icon-ekey-ownelectricity,
  84: $icon-ekey-cop,
  85: $icon-ekey-eer,
  86: $icon-ekey-scop,
  87: $icon-ekey-seer,
  88: $icon-ekey-personsin, // $enerkey-personsinavg
  89: $icon-ekey-personsout, // $enerkey-personsoutavg
  90: $icon-ekey-personsoccupancy, // $enerkey-personsoccupancyavg
  91: $icon-ekey-personsin,
  92: $icon-ekey-personsout,
  93: $icon-ekey-personsoccupancy,
  94: $icon-ekey-pellet, // $enerkey-pelletmwh
  95: $icon-ekey-naturalgas, // $enerkey-naturalgasmwh
  96: $icon-ekey-oil, // $enerkey-oilmwh
  97: $icon-ekey-liquefiedpetroleumgas, // $enerkey-liquidgasmwh
  98: $icon-ekey-naturalgasother, // $enerkey-naturalgasothermwh
  99: $icon-ekey-biogas, // $enerkey-biogasmwh
  100: $icon-ekey-woodchips, // $enerkey-woodchipsmwh
  101: $icon-ekey-heavyoil, // $enerkey-heavyfueloilmwh
  1000: $icon-ekey-heatingenergy,
  1001: $icon-ekey-totalenergy,
  1002: $icon-ekey-transport,
  1003: $icon-ekey-wasterecycle,
  1004: $icon-ekey-totalwaste,
  1005: $icon-ekey-heatingpurchased,
  1006: $icon-ekey-totalenergypurchased,
  1007: $icon-ekey-totalelectricity,
  1008: $icon-ekey-totalcooling,
  1009: $icon-ekey-totalenergy, // $enerkey-totalownenergy
);
