[type=text], [type=number], [type=email], textarea {
  width: 100%;
  display: block;

  &:not(.k-input):not(.k-textbox):not(.k-input-inner) {
    margin-bottom: var(--spacing-default);
    @include border-radius( $kendo-border-radius );
    border-width: $kendo-input-border-width;
    border-style: solid;
    outline: 0;
    padding: $kendo-input-padding-y $kendo-input-padding-x;
    font-family: $kendo-input-font-family;
    font-size: $kendo-input-font-size;
    line-height: $kendo-input-line-height;
    box-sizing: border-box;
    // Targets https://github.com/telerik/kendo-react/issues/638.
    box-shadow: none;
    background: none;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    vertical-align: middle;
    position: relative;
    -webkit-appearance: none;

    @include fill(
      $kendo-input-text,
      $kendo-input-bg,
      $kendo-input-border
    );

    &:disabled {
      @include disabled( $disabled-styling );
    }

    &:focus {
      @include fill (
        $kendo-input-focus-text,
        $kendo-input-focus-bg,
        $kendo-input-focus-border
      );
      @include box-shadow( $kendo-input-focus-shadow );
    }

    &::placeholder {
      color: $kendo-input-placeholder-text;
    }

    &.ng-invalid {
      @include fill( $border: $invalid-border );
      border-width: 1px;
    }
  }
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;

  legend {
    font-weight: 600; // semibold
  }
}

select {
  border: 1px solid $kendo-input-border;
}

textarea {
  max-width: 100%;
  width: 100%;
}
