.meter-interface-status-modal {
  .modal-body {
    min-height: 300px;
  }

  #status-calendar {
    .success-event {
      color: $enerkey-green;
    }

    .failure-event {
      background-color: $enerkey-red;
      border-radius: 50%;
      margin-top: 2px;
      margin-left: 2px;
      width: 100%;
    }

    .success-indicator {
      border-radius: 50%;
      width: 3px;
      height: 3px;
      margin: auto;
      margin-top: -2px;
      background-color: $enerkey-footnote;
    }
  }

  #daily-status {
    width: 350px;
    height: 300px;
    overflow-y: auto;

    .status-history-item {
      font-size: 0.85rem;

      > .indicator {
        font-size: 0.85rem;
      }
    }
  }

  .data-container {
    float: left;
    padding-left: 15px;
  }

  .button-container {
    vertical-align: top;
  }

  .refresh-button {
    float: right;
  }
}
