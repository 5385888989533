.select-paging {
  @include flex();
  flex-direction: column;

  &__header {
    @include flex-center();
    margin-bottom: $spacing-less;

    &__title {
      @include flex();
      flex-grow: 1;
    }

    &__pager {
      @include flex();
    }

    button {
      padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
      padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
    }

    h2 {
      margin-bottom: 0;
    }
  }

  &__page-number {
    @include flex-center();
  }

  &__content {
    @include flex();
    flex-direction: column;

    &__actions {
      @include flex-center();
      justify-content: flex-end;
      flex-direction: row;

      margin-left: auto;
    }
  }
}
