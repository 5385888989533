manual-qa-spreadsheet {
  .k-spreadsheet-action-bar {
    /* stylelint-disable-next-line */
    display: none !important;
  }

  .k-state-disabled {
    opacity: 1;
    color: $enerkey-black;
  }
}
