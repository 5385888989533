$treelist-child-margin: 2em;

.meter-search {
  td.meterTreelistHierarchyCell {
    .k-i-none:first-of-type, .k-i-none:nth-of-type(2) {
      width: $treelist-child-margin;
    }

    .related-meter-marker {
      margin-left: 3px;
    }
  }

  .facility-parent, .quantity-parent, .main-meter-parent {
    td.meterTreelistHierarchyCell {
      font-weight: bold;
    }
  }

  .highlight-meter {
    /* stylelint-disable-next-line declaration-no-important */
    background-color: rgba($enerkey-primary, 0.3) !important;
  }

  .related-parent {
    td.meterTreelistHierarchyCell, td.meterTreelistQuantityCell {
      color: #8e8e8e;
      font-style: italic;
    }
  }

  .facility-parent, .quantity-parent {
    > td:not(:first-of-type):not(:nth-of-type(2)) {
      border-left: none;
    }
  }

  .floating-parent {
    td.meterTreelistHierarchyCell {
      text-decoration: line-through;
    }
  }

  .quantity-parent {
    $border-width: 2px;
    $border-style: solid;

    @each $key, $value in $enerkey-quantity-colors {
      &.quantity-parent-#{$key} {
        > td:not(:first-of-type) {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-top: 2px solid $value;
          }
        }

        td.meterTreelistHierarchyCell {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: $treelist-child-margin;
            height: 5px;
            border-left: 2px solid $value;
          }
        }
      }
    }
  }
}
