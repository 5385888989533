.mqa {
  &__separator {
    &--right {
      border-right: $mqa-default-border;
    }
  }

  &--bg-white {
    background-color: $enerkey-white;
  }

  &__column {
    @include flex();
    flex-direction: column;
    padding: $spacing-default;

    &--compact {
      padding: $spacing-less;
    }

    &--flush {
      padding: 0;
    }

    &--grow {
      flex: 1 0 auto;
    }
  }

  &__row {
    @include flex();
    padding: $spacing-default;

    width: 100%;

    &--compact {
      padding: $spacing-less;
    }

    &--flush {
      padding: 0;
    }

    &--wrap {
      flex-wrap: wrap;
    }

    &--space-between {
      justify-content: space-between;
    }

    &--center {
      align-items: center;
    }

    &--right {
      justify-content: flex-end;
    }

    &__left, &__right {
      @include flex();
      flex-direction: column;
      flex: 0 1 50%;

      padding-left: $spacing-default;
      padding-right: $spacing-default;
    }

    &__half {
      flex: 0 0 100%;

      max-height: 50%;
      min-height: 50%;

      max-width: 100%;

      padding-bottom: $spacing-default;
      padding-top: $spacing-default;

      &--compact {
        padding-bottom: $spacing-less;
        padding-top: $spacing-less;
      }

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  &__header-info {
    padding-left: $spacing-less;
    font-weight: bold;
  }

  @media only screen and (max-width: $mqa-small-width) {
    &__separator {
      &--right {
        border-right: 0;
      }
    }

    &__row {
      flex: 0 0 100%;
      flex-wrap: wrap;
    }

    &__column {
      flex: 0 0 100%;
    }
  }
}
