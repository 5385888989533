$card-margin: 15px;

.facilities-report,
.meters-report {
  .graph {
    padding-bottom: 0.9375rem;
    padding-left: 0 !important; /* stylelint-disable-line */
    padding-right: 0 !important; /* stylelint-disable-line */
    position: relative;
    overflow: visible;
    max-width: map-get($breakpoints, 'xlarge');
    @include grid-column(12);
  }

  .real-estate, .meter-id {
    @include identifier-prefix();
  }

  .facilities-report__enegia-id {
    margin-left: auto;
  }

  .facilities-report__buttons {
    margin-left: var(--spacing-default);
    gap: var(--spacing-less);
  }

  .sumreport-grid, .trendreport-grid {
    clear: both;
  }

  .title, .facility h5 {
    background: $enerkey-primary;
    color: $enerkey-white;
    padding: 0.7rem;
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.125rem;
  }

  .title-label {
    color: $enerkey-white;
    float: right;
    line-height: normal;
  }

  .graph-error-text {
    clear: both;
    color: $enerkey-red;
  }

  .graph-percentage {
    font-size: smaller;
  }

  &__header, &__buttons {
    display: flex;
  }

  &__buttons {
    align-items: center;
    padding-bottom: 10px;
  }

  @include breakpoint(xlarge up) {
    .card {
      &:nth-child(even) {
        margin-left: $card-margin;
      }
    }
  }

  .card {
    margin-bottom: $card-margin;
  }
}

.facilities-report .graph {
  @include breakpoint(xlarge up) {
    @include grid-column(6);
    width: calc(50% - #{$card-margin} / 2);
  }

  &:nth-child(even) {
    @include breakpoint(xlarge up) {
      //padding-left: 0.325rem !important;
    }
  }

  &:nth-child(odd) {
    @include breakpoint(xlarge up) {
      //padding-right: 0.325rem !important;
    }
  }
}

.meters-report {
  i[class^="icon-ekey"] {
    text-shadow: -1px -1px 0 #fff,
      1px -1px 0 #fff,
      -1px 1px 0 #fff,
      1px 1px 0 #fff;
  }

  a.anchor {
    display: block;
    visibility: hidden;
  }

  .map-image-icon-container {
    font-size: 200%;
    vertical-align: middle;
    margin-left: 0.5rem;

    .fa {
      cursor: pointer;
      color: $enerkey-primary;

      &:hover{
        color: lighten($enerkey-primary-active, 20%);
      }
    }
  }
}

.meters-treelist {
  .report-item {
    overflow: visible;
    padding-bottom: 2rem;
  }
}

.facility {
  .facilities-report .graph {
    width: 49%;
  }

  .panel {
    min-height: rem-calc(275);
  }

  .filters select {
    width: 6rem;
  }

  border-bottom: 1px solid lighten($enerkey-footnote, 50%);
  margin: 0 0 rem-calc(20) 0;
  padding-bottom: rem-calc(20);

  &.last {
    border-bottom: none;
  }

  .gridStyle {
    min-height: 420px;
  }

  > div:last-child {
    padding-bottom: rem-calc(20);
  }

  .facility-info-group, .meter-info-group {
    .title {
      font-weight: bold;
    }

    ul {
      @include clearfix();
      border: 0 solid #ccc;
      border-width: 1px;
      margin-left: 0;
      list-style: none;
      padding: 5px;

      li {
        float: left;
        padding: rem-calc(8);
        margin-bottom: -1px;
        width: 50%;

        div.no-wrap {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @include breakpoint(medium down) {
          width: 50%;

          &:nth-child(2n) {
            border-right: 0;
          }
        }

        @include breakpoint(large only) {
          width: 33.33333%;

          &:nth-child(3n) {
            border-right: 0;
          }
        }

        @include breakpoint(xlarge only) {
          width: 25%;

          &:nth-child(4n) {
            border-right: 0;
          }
        }

        @include breakpoint(xxlarge up) {
          width: 20%;

          &:nth-child(5n) {
            border-right: 0;
          }
        }
      }
    }
  }
}

.facility-modal {
  @include calc('height', '100% - 10px');
}

.report-settings {
  border-bottom: 1px solid lighten($enerkey-footnote, 50%);
  margin-bottom: 1rem !important; /* stylelint-disable-line */
  padding-bottom: 0.5rem;

  .columns {
    margin-bottom: rem-calc(5);
  }

  label {
    line-height: 2.8125rem;

    &.tiny {
      line-height: 2.8125rem * 0.9;
    }
  }

  .real-estate {
    @include identifier-prefix();
  }
}

.er-object-count {
  color: $enerkey-footnote;
  font-weight: 600;
  font-size: 1.1rem;
  margin-left: 1rem;
}

.er-object-count__inverse {
  color: #fff;
}

.er-object-count__small {
  font-size: rem-calc(13);
}
