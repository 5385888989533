.customer-admin {
  .k-grid th {
    border-bottom: none;
  }

  .k-grid th,
  .k-grid td {
    padding: 10px;
  }

  .k-grid-pager {
    padding: 20px 10px;
  }

  .grid-reduced-height {
    height: calc(var(--content-full-height) - 28.8px - var(--spacing-default));
    min-height: 400px;
  }
}