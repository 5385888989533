.manual-qa-fetch-type {
  display: flex;
  margin: $spacing-less;

  label {
    display: flex;
    align-items: center;
    margin-right: $spacing-less;
    
    input {
      margin: 0 $spacing-less 0 0;
    }
  }
}
