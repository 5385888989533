$modal-content-height: calc(100vh - 120px);

.reveal--full-height {
  .modal-content {
    height: $modal-content-height;
    min-height: $modal-content-height;
    max-height: $modal-content-height;

    overflow-y: auto;
    padding-top: $spacing-default;
  }
}
