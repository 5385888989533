.metertree {
  overflow: hidden;
  margin-left: 0;

  ul {
    list-style: none;
    margin-left: 0;

    ul {
      padding-left: 1rem;
    }

    li {
      white-space: nowrap;
    }
  }

  i {
    cursor: pointer;
  }

  input[type="checkbox"] {
    vertical-align: top;
    margin-top: 2px;
  }

  &--quantity {
    display: block;
    line-height: 1.75rem;
    font-weight: bold;
  }

  &--collapsed {
    position: relative;
    padding-bottom: rem-calc(10);

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: rem-calc(16);
      left: 0.375rem;
      border-left: 1px dashed $enerkey-border;
      height: calc(100% - 30px);
    }
  }

  &--meteringtypes {
    font-size: rem-calc(14);

    ul {
      margin-bottom: rem-calc(10);
      margin-left: 0;
    }

    li {
      display: inline-block;
      margin-right: rem-calc(8);
    }
  }

  label {
    display: inline-block;
    white-space: normal;
    margin-right: 45px;
    line-height: 1.3rem;

    &.non-main-meter {
      font-style: italic;
    }

    &.filtered-out-meter {
      color: $enerkey-footnote;
    }
  }
}

.metertree__all-none-container {
  list-style: none;
}

.metertree__all-item, .metertree__none-item {
  font-size: small;
  display: inline-block;
  color: $enerkey-input-text;
  cursor: pointer;

  &:hover {
    color: $enerkey-panel-text;
  }
}

.metertree__none-item:before {
  content: "";
  font-size: rem-calc(12);
  border-left: 1px solid $enerkey-input-text;
  padding-left: rem-calc(10);
  margin-left: rem-calc(4);
}
