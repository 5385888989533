cc-mass-import {
  // hide action bar
  .k-spreadsheet-action-bar {
    display: none !important; /* stylelint-disable-line */
  }

  .mass-import-content {
    margin-top: $spacing-default;
  }

  #massImportSheetContainer {
    box-shadow: none;
  }

  .wizard-wrapper {
    display: block;
    margin: $spacing-default;
    background: $enerkey-background;
    padding: $spacing-default;
  }
}
