.addOperationsDropdown {
  .dropdown-pane {
    width: auto;
    padding: 0.5em;
    border: 0px;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.5);

    div {
      cursor: pointer;
      padding-right: 3em;
      padding-bottom: 3px;
    }
  }
}
