@import "variables";

$modal-full-height-spacing: 45px;

.reveal {
  border: 0;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  background: $enerkey-background;
  padding: 0;
  margin: auto;
  outline: none;
  max-width: 100% !important; /* stylelint-disable-line */
  display: block;
  top: 0;
  width: 70%;
  border-radius: var(--container-radius);

  &.semiFull {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 0;
    max-height: calc(100vh - 1rem);
    min-height: calc(100vh - 1rem);
    width: 100%;

    @include breakpoint(small only) {
      margin-left: 0;
      margin-right: 0;
      min-height: 100vh;
      max-height: 100vh;
    }
  }

  &.modal-dialog-scrollable {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 1rem);

    @include breakpoint(small only) {
      max-height: 100vh;
    }

    .modal-content {
      max-height: calc(100vh - 1rem);
      display: flex;
      flex-direction: column;

      @include breakpoint(small only) {
        max-height: 100vh;
      }

      .modal-body {
        overflow-y: auto;
        /* Without position relative dropdowns don't work well when modal is scrolled */
        position: relative;
      }
    }
  }

  @include breakpoint(small only) {
    & {
      width: 100%;
      margin: 0;
    }
  }

  &.fixed-height {
    height: calc(100vh - 2rem);

    @include breakpoint(small only) {
      height: 100vh;
    }

    .modal-content {
      @include breakpoint(small only) {
        height: 100vh;
      }

      height: calc(100vh - 2rem);
      overflow: hidden;

      .modal-body {
        flex: 1 0;
      }
    }
  }

  &--full-height {
    height: calc(100vh - #{$modal-full-height-spacing});
    width: 98%;
  }

  &.fullheight {
    height: calc(100vh - #{$modal-full-height-spacing});
    width: 100%;

    > .modal-body {
      padding: 0 1rem 1rem;
    }

    .loadingIndicator {
      @include breakpoint(medium up) {
        bottom: $modal-full-height-spacing * 0.5;
      }

      &.ng-hide > i {
        display: none;
      }
    }
  }

  // TODO: This will become obsolete when all headers use the new modal header from styleguide
  .modal-header {
    background: $enerkey-primary;
    text-transform: uppercase;
    min-height: rem-calc(48);

    h3 {
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
      padding: 0.8rem;
      margin: 0;
    }
  }

  .modal-footer {
    clear: both;
    padding: 1rem;
    border-top: 1px solid var(--enerkey-light-grey);

    button + button {
      margin-left: $spacing-less;
    }
  }

  .flex-modal-footer {
    padding: 1rem;
    display: flex;
    flex-direction: row;

    &__left-content {
      display: inline-flex;
      flex-direction: row;
    }

    &__right-content {
      margin-left: auto;
      display: inline-flex;
      flex-direction: row;
    }
  }

  .modal-remove-widget {
    vertical-align: middle;

    span {
      display: inline-block;
      margin-right: 1rem;
    }

    .button {
      margin-bottom: 0;
    }

  }

  .close {
    float: right;
    background: none;
    border: 0;
    color: #fff;
    font-size: 1rem;
    padding: 1rem;
    margin: 0;
  }

  &.content {
    width: unset;

    .modal-body {
      min-height: unset;
    }
  }
}

.reveal-overlay {
  display: flex !important; /* stylelint-disable-line */
  align-items: center;
  justify-content: center;
}

.settings-modal {
  .settings-group {
    position: relative;
    max-width: rem-calc(650);
    background: $enerkey-panel-background;
    border: 1px solid $enerkey-border;
    margin: rem-calc(8) 0;
    padding-bottom: rem-calc(8);

    @include breakpoint(medium down) {
      max-width: rem-calc(450);
    }

    .settings-group-row {
      padding-left: 0.5em;
    }

    .settings-group-title {
      background: $enerkey-grid-row-dark;
      margin-bottom: rem-calc(8);
      padding-bottom: 0.5em;
      padding-top: 0.5em;

      a.button {
        margin: 0;
      }

      span.settings-group-title-text {
        @include calc('width', '100% - 120px');
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: normal;
        display: inline-block;
        vertical-align: middle;
      }

      span.settings-group-title-text-with-button {
        @include calc('width', '100% - 240px');
      }
    }
  }

  .settings-profile-default a {
    position: absolute;
    left: 100%;
    overflow: visible;
    min-width: 160px;
  }

  .multiSelect {
    .checkboxLayer {
      max-height: 300px;
      overflow: auto;
    }
  }
}

.settings-modal--profile-modal {
  height: 43em !important; /* stylelint-disable-line declaration-no-important */

  .multiSelect {
    .checkboxLayer {
      max-height: 28em;

      @include breakpoint(large only) {
        width: 30em;
      }

      @include breakpoint(medium down) {
        width: 100%; 
      }
    }

    .multiSelectItem {
      white-space: normal;
    }
  }
}

.settings-modal--profile-modal__filter-container {
  display: flex;

  .settings-modal--profile-modal__filter-fields-container {
    width: 50%;
  }

  .settings-modal--profile-modal__filter-button-container {
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.bookmarks-modal__bookmark {
  display: flex;
  height: 3rem;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.bookmarks-modal__add-bookmark-container {
  padding-bottom: 1em;
}

.bookmarks-modal__bookmark_button {
  border-radius: 50%;
  padding: 0.5rem 0.57rem;
  margin: 0 0 0 0.5rem;
}

.modal-body {
  padding: 1rem;
  min-height: 300px;

  &--tiny {
    min-height: 0;
  }

  &--no-padding {
    padding: 0;
  }

  &.modal-body--nested-containers {
    @include breakpoint(small only) {
      padding: 1rem 0;
    }
  }
}
