.manual-qa-meter-facility-info {
  padding: $spacing-less;

  div {
    padding-bottom: $spacing-less;
  }

  // fontawesome icons
  i {
    color: $enerkey-footnote;
    width: var(--spacing-default);
    text-align: center;
  }

  &__alert {
    color: $enerkey-red;
    font-weight: bold;
  }
}
