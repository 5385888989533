// Toggle group
//
// markup:
// <div class="toggle-group">
//   <button class="button toggle-group__toggle-button toggle-group__toggle-button--is-first">Toggle group button 1 (active)</button>
//   <button class="button button--secondary toggle-group__toggle-button">Toggle group button 2</button>
//   <button class="button button--secondary toggle-group__toggle-button toggle-group__toggle-button--is-last">Toggle group button 3</button>
// </div>
//
// Styleguide 4.8
.toggle-group {
  @include flex();

  .button:focus {
    box-shadow: none;
  }

  &:not(.toggle-group--tab-like) {
    .toggle-group__toggle-button {
      border-radius: 0 0 0 0 !important; /* stylelint-disable-line */
      margin-right: 1px;
      max-width: 20rem;
    }

    .toggle-group__toggle-button--is-first {
      border-radius: 50px 0 0 50px !important; /* stylelint-disable-line */
    }

    .toggle-group__toggle-button--is-last {
      border-radius: 0 50px 50px 0 !important; /* stylelint-disable-line */
    }
  }

  &--tab-like {
    --border-colors: var(--enerkey-primary) var(--enerkey-grey) transparent var(--enerkey-grey);

    button.toggle-group__toggle-button {
      // Have to because inline style :'(
      width: auto !important; /* stylelint-disable-line */
      background: var(--enerkey-light-grey);
      color: var(--enerkey-black);
      border-radius: 0;
      border-color: var(--border-colors);
      border-style: solid solid none;
      border-width: 3px 1px 0;
      transition: all .15s;

      &.button--secondary {
        background: transparent;
        color: var(--enerkey-grey);
        border-color: transparent;
        border-width: 3px 1px 0 0;

        &:hover {
          color: var(--enerkey-black);
          transition: color .15s;
        }
      }
    }

    &.toggle-group--kendo-colors {
      --border-colors: var(--enerkey-primary) var(--enerkey-light-grey) transparent var(--enerkey-light-grey);

      button.toggle-group__toggle-button {
        background: var(--enerkey-white);
        
        &.button--secondary {
          background: transparent;
        }
      }
    }
  }
}
